import React from "react"
import PropTypes from "prop-types"

import { makeStyles, useTheme } from "@material-ui/styles"
import { Grid, TextField } from "@material-ui/core"
import VideoYoutube from "components/VideoYoutube"
import { Fragment } from "react"

const useStyles = makeStyles((theme) => ({
  root: {},
  imageContainer: {
    height: 64,
    width: 64,
    margin: "0 auto",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "5px",
    overflow: "hidden",
    display: "flex",
    // alignItems: "center",
    // justifyContent: "center",
  },
  image: {
    width: "100%",
  },
  statsItem: {
    display: "flex",
    //alignItems: "center",
  },
  statsIcon: {
    color: theme.palette.icon,
    marginRight: theme.spacing(1),
  },
}))

export const Setting = (props) => {
  const { className, item, selectForUpdate, ...rest } = props

  const classes = useStyles()
  const theme = useTheme()

  if (!item || !item.id) {
    return null
  }

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            id="phone"
            name="phone"
            value={item.phone}
            required
            label="Téléphone"
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            id="email"
            name="email"
            value={item.email}
            required
            label="Email"
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            id="facebook_page"
            name="facebook_page"
            value={item.facebook_page}
            required
            label={"Page Facebook"}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            id="twitter_page"
            name="twitter_page"
            value={item.twitter_page}
            required
            label={"Page Twitter"}
            fullWidth
          />
        </Grid>

        {item.youtube_link && (
          <Grid>
            <VideoYoutube id={item.youtube_link} />
          </Grid>
        )}
        <Grid item xs={12} sm={12}>
          <div
            className="post__content"
            dangerouslySetInnerHTML={{ __html: item.cgu }}
          ></div>
        </Grid>
      </Grid>
    </Fragment>
  )
}

Setting.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object.isRequired,
}
