import gql from "graphql-tag"

export const findServicesAdminQuery = gql`
  query findServicesAdmin($page: Int!, $company_id: String!) {
    findServicesAdmin(page: $page, company_id: $company_id) {
      totalDocs
      hasPrevPage
      totalPages
      limit
      offset
      page
      prevPage
      nextPage
      docs {
        id
        name
        price
        picture
        is_active
        createdAt
        updatedAt
      }
    }
  }
`
