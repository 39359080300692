import React from "react"
import {
  Grid,
  Button,
  TextField,
  Input,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@material-ui/core"

import { makeStyles } from "@material-ui/core/styles"
import Loading from "components/Loading"
import { MessageField } from "components/MessageField"

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: "100%",
  },
  labelPhoto: {
    marginTop: 12,
    fontWeight: 700,
    display: "block",
  },
  pictureActuelle: {
    marginTop: 12,
  },
}))
export const CategoryEdit = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  category,
  globalError,
  canModify,
}) => {
  const classes = useStyles()

  if (!category) {
    return null
  }
  if (isSubmitting) {
    return <Loading />
  }

  return (
    <form
      className={classes.root}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      {globalError && <MessageField message={globalError} severity={"error"} />}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <FormControlLabel
            disabled={!canModify}
            control={
              <Checkbox
                id="is_active"
                name="is_active"
                checked={values.is_active}
                onChange={async (_e, value) =>
                  await setFieldValue("is_active", value)
                }
              />
            }
            label={values.is_active ? "Désactiver" : "Activer"}
          />
        </Grid>
        {/* <Grid item xs={12} sm={12}>
          <label style={{ color: "green" }}>Etat Actuel</label>
          <h3 style={{ color: values.is_active ? "green" : "red" }}>
            {values.is_active ? "Actif" : "Inactif"}
          </h3>
        </Grid> */}
        <Grid item xs={12} sm={6}>
          <TextField
            id="name"
            name="name"
            label="Nom"
            disabled={!canModify}
            onChange={handleChange}
            error={touched.name && errors.name ? true : false}
            value={values.name}
            fullWidth
            helperText={touched.name && errors.name ? errors.name : null}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            disabled={!canModify}
            fullWidth
            label="Priorité"
            value={values.priority}
            inputProps={{ min: "1", step: "1" }}
            name="priority"
            id="priority"
            onChange={handleChange}
            type="number"
            helperText={touched.priority && errors.priority ? errors.priority : null}
            error={touched.priority && errors.priority ? true : false}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <TextField
            id="description"
            name="description"
            label="description"
            disabled={!canModify}
            onChange={handleChange}
            error={touched.description && errors.description ? true : false}
            fullWidth
            value={values.description}
            helperText={
              touched.description && errors.description ? errors.description : null
            }
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <Input
            fullWidth
            type="file"
            name="file"
            id="file"
            onChange={async ({
              target: {
                validity,
                files: [file],
              },
            }) => {
              await setFieldValue("file", file)
            }}
            style={{ display: "none" }}
          />

          <label htmlFor="file">
            <Button
              disabled={!canModify}
              variant="contained"
              component="span"
              color="secondary"
              style={{ marginBottom: 2 }}
            >
              Changer de photo
            </Button>
          </label>
          
          {values.file && (
            <>
            <br />
            <br />
            <Grid item lg={6} md={6} sm={8} xs={12}>
              <img
                src={URL.createObjectURL(values.file)}
                alt="photo"
                width="100%"
                height="300"
              />
            </Grid>
            </>
          )}
          <br />
          {touched.file && errors.file && (
            <span style={{ color: "red" }}>{errors.file}</span>
          )}
        </Grid>
        {!values.file && (
          <Grid item lg={6} md={6} sm={8} xs={12}>
            <Typography className="text-picture-current">Photo actuelle</Typography>
            <br />
            <img src={category.picture} alt="photo" width="100%" height="300" />
          </Grid>
        )}
        <Grid item xs={12} sm={12}>
          <Button
            color="primary"
            disabled={!canModify}
            //disabled={!formState.isValid}
            type="submit"
            onClick={handleSubmit}
            variant="contained"
          >
            enregistrer
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}
