import React from "react"
import { SubscriptionComp } from "./SubscriptionComp"
import Grid from "@material-ui/core/Grid"
import "../subscription.scss"
  
export const SubscriptionCompList = ({ items, currency, selectForUpdate }) => {
  if (!items || !items.length) {
    return null
  }
  return (
    <Grid container spacing={0} className="precing-list">
      {items.map((item) => (
        <SubscriptionComp
          item={item}
          key={item.id}
          currency={currency}
          selectForUpdate={selectForUpdate}
        />
      ))}
    </Grid>
  )
}
