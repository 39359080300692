import React, { useState,useEffect } from "react"
import { useQuery, useMutation } from "react-apollo"
import * as Yup from "yup"
import { Formik } from "formik"
import { makeStyles } from "@material-ui/styles"
import { findCatsQuery } from "../../graphql/query/category"
import {
  createCategoryMutation,
  updateCategoryMutation,
  deleteCategoryMutation,
} from "../../graphql/mutation/category"
import Loading from "components/Loading"
import { CategoryList } from "./components/CategoryList"
import { CategoryToolbar } from "./components/CategoryToolbar"
import { CategoryCreate } from "./components/CategoryCreate"
import { ModalDialog } from "components/ModalDialog"
import { CategoryEdit } from "./components/CategoryEdit"
import { ConfirmDialog } from "components/ConfirmDialog"
import { CategoryDelete } from "./components/CategoryDelete"
import { oneSettingAdminQuery } from "graphql/query/setting_admin"
import client from "../../graphql/apollo"
import { currentUserQuery } from "graphql/query/user"

const useStyles = makeStyles((theme) => ({
 
  root: {
    padding:'9px 25px'
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
}))
const FILE_SIZE = 1600 * 1024
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"]

const creatCategorySchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .typeError("champ requis")
    .min(2, "minimum 2 caractères")
    .max(100, "maximum 100 caractères")
    .required("champ requis"),
  priority: Yup.number().nullable(),
  description: Yup.string(),
  /*  .trim()
    .min(100, "minimum 100 caractères")
    .required("champ requis")
    .max(500, "maximum 500 caractères") */ is_active: Yup.boolean()
    .default(false)
    .typeError("champ requis"),
  file: Yup.mixed()
    .required("champ requis")
    .test("fileFormat", "extension non acceptée", (value) => {
      if (value) {
        return value && SUPPORTED_FORMATS.includes(value.type)
      }
      return true
    }),
  /*   .test("fileSize", "image trop large", (value) => {
      if (value) {
        return value && value.size <= FILE_SIZE
      }
      return true
    }), */
})
const updateCategorySchema = Yup.object().shape({
  id: Yup.string().trim().nullable(),
  name: Yup.string()
    .trim()
    .typeError("champ requis")
    .min(2, "minimum 2 caractères")
    .max(100, "maximum 100 caractères")
    .nullable(),
  description: Yup.string()
    .trim()
    .min(100, "minimum 100 caractères")
    .nullable()
    .max(500, "maximum 500 caractères"),
  priority: Yup.number().nullable(),
  file: Yup.mixed()
    .nullable()
    .test("fileFormat", "extension non acceptée", (value) => {
      if (value) {
        return value && SUPPORTED_FORMATS.includes(value.type)
      }
      return true
    }),
  /*  .test("fileSize", "image trop large", (value) => {
      if (value) {
        return value && value.size <= FILE_SIZE
      }
      return true
    }), */
})

const deleteCategorySchema = Yup.object().shape({
  category_id: Yup.string().trim().nullable(),
})

const CategoryView = () => {
  const classes = useStyles()

  const [loading, setLoading] = useState(false)
  const [name, setName] = useState("")
  const [page, setPage] = useState(1)
  const [createModal, setCreateModal] = useState(false)
  const [globalCreateCatError, setGlobalCreateCatError] = useState("")
  const [globalEditCatError, setGlobalEditCatError] = useState("")
  const [editModal, setEditModal] = useState(false)
  const [editedCat, setEditedCat] = useState(null)
  const [globalDeleteCatError, setGlobalDeleteCatError] = useState("")
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteCat, setDeleteCat] = useState(null)
  const [canModify, setCanModify] = useState(false)
  const [canAdd, setCanAdd] = useState(false)
  const { loading: loading2, data: generalSetting } = useQuery(oneSettingAdminQuery)
  const { data: userData } = useQuery(currentUserQuery, {
    //fetchPolicy: "network-only",
  })
  const roles =
    userData && userData.onlineUser && userData.onlineUser.roles
      ? userData.onlineUser.roles
      : []
  const timezone =
    generalSetting &&
    generalSetting.oneSettingAdmin &&
    generalSetting.oneSettingAdmin.time_zone
      ? generalSetting.oneSettingAdmin.time_zone
      : "Africa/Dakar"
  const {
    loading: loading1,
    data,
    fetchMore,
    refetch,
  } = useQuery(findCatsQuery, {
    variables: { page },
  })

  const [createCat] = useMutation(createCategoryMutation, {
    async update(
      _cache,
      {
        data: {
          createCat: { ok, cat },
        },
      }
    ) {
      if (ok) {
        //let variables = await computeVariables(page)
        await clearFilter()
        /*   const { findCats } = await client.readQuery({
          query: findCatsQuery,
          variables,
        })

        const docs = findCats.docs.unshift(cat)
        await client.writeQuery({
          query: findCatsQuery,
          variables,
          data: {
            findCats: {
              ...findCats,
              totalDocs: findCats.totalDocs + 1,
              docs,
            },
          },
        }) */
      }
    },
  })
  const [deleteCategory] = useMutation(deleteCategoryMutation, {
    async update(
      _cache,
      {
        data: {
          deleteCat: { ok, category_id },
        },
      }
    ) {
      if (ok) {
        await clearFilter()
        /*  let variables = await computeVariables(page)
        const { findCats } = await client.readQuery({
          query: findCatsQuery,
          variables,
        })

        let docs = findCats.docs
        const index = docs.findIndex(
          (d) => d.id.toString() === category_id.toString()
        )
        if (index > -1) {
          docs.splice(index, 1)
        }

        await client.writeQuery({
          query: findCatsQuery,
          variables,
          data: {
            findCats: {
              ...findCats,
              totalDocs: findCats.totalDocs - 1,
              docs,
            },
          },
        })*/
      }
    },
  })
  const [updateCat] = useMutation(updateCategoryMutation, {
    async update(
      _cache,
      {
        data: {
          updateCat: { ok, cat },
        },
      }
    ) {
      if (ok) {
        await clearFilter()
        /* let variables = await computeVariables(page)
        const { findCats } = await client.readQuery({
          query: findCatsQuery,
          variables,
        })

        let docs = findCats.docs
        const index = docs.findIndex(
          (d) => d.id.toString() === editedCat.id.toString()
        )
        if (index > -1) {
          docs[index] = cat
        }
        await client.writeQuery({
          query: findCatsQuery,
          variables,
          data: {
            findCats: {
              ...findCats,
              docs,
            },
          },
        }) */
      }
    },
  })

  const createCategory = async (
    values,

    { setSubmitting, setFieldError }
  ) => {
    try {
      const res = await createCat({
        variables: {
          ...values,
        },
      })
      const { ok, errors } = res.data.createCat
      if (ok) {
        await setSubmitting(false)
        await setCreateModal(false)
      } else {
        errors.forEach(async (error) => {
          if (error.path && error.path.toString() === "global") {
            await setGlobalCreateCatError(error.message)
          } else {
            await setFieldError(error.path, error.message)
            await setSubmitting(false)
          }
        })
      }
    } catch (error) {
      window.alert(error.message)
    }
  }

  const updateCategory = async (
    values,

    { setSubmitting, setFieldError }
  ) => {
    try {
      const { name, file, description, id, is_active, priority } = values
      let variables = { id: editedCat.id }
      if (name && editedCat.name != name) {
        variables.name = name
      }
      if (is_active != undefined && editedCat.is_active != is_active) {
        variables.is_active = is_active
      }

      if (priority && editedCat.priority != priority) {
        variables.priority = priority
      }
      if (file) {
        variables.file = file
      }
      if (description && editedCat.description != description) {
        variables.description = description
      }

      const res = await updateCat({
        variables,
      })
      const { ok, errors } = res.data.updateCat

      if (ok) {
        await setSubmitting(false)
        await setEditModal(false)
        await setEditedCat(null)
      } else {
        errors.forEach(async (error) => {
          if (error.path && error.path.toString() === "global") {
            await setGlobalEditCatError(error.message)
          } else {
            await setFieldError(error.path, error.message)
            await setSubmitting(false)
          }
        })
      }
    } catch (error) {
      window.alert(error.message)
    }
  }

  const removeCategory = async (
    _values,

    { setSubmitting, setFieldError, handleReset }
  ) => {
    try {
      //const { category_id } = values

      const res = await deleteCategory({
        variables: {
          category_id: deleteCat.id,
        },
      })
      const { ok, errors } = res.data.deleteCat

      if (ok) {
        await setSubmitting(false)
        await setDeleteModal(false)
        await setDeleteCat(null)
      } else {
        errors.forEach(async (error) => {
          if (error.path && error.path.toString() === "global") {
            await setGlobalDeleteCatError(error.message)
          } else {
            await setFieldError(error.path, error.message)
            await setSubmitting(false)
          }
        })
      }
      await handleReset()
    } catch (error) {
      window.alert(error.message)
    }
  }
  const computeVariables = async (page) => {
    try {
      await setPage(page)
      let variables = { page }
      if (name) {
        variables.name = name
      }

      return variables
    } catch (error) {
      window.alert(error.message)
    }
  }
  const onPageChange = async (page) => {
    try {
      let variables = await computeVariables(page)

      fetchMore({
        variables,
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev

          const { findCats } = fetchMoreResult
          return Object.assign({}, prev, {
            findCats,
          })
        },
      })

      await setLoading(false)
    } catch (error) {
      window.alert(error.message)
    }
  }
  const search = async () => {
    try {
      await setLoading(true)

      let variables = await computeVariables(1)

      fetchMore({
        variables,
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev

          const { findCats } = fetchMoreResult
          return Object.assign({}, prev, {
            findCats,
          })
        },
      })
      await setLoading(false)
    } catch (error) {
      window.alert(error.message)
    }
  }
  const selectCategory = async (category) => {
    try {
      await setEditedCat({ ...category })
      await setEditModal(true)
    } catch (error) {
      window.alert(error.message)
    }
  }

  const selectCatToDelete = async (category) => {
    try {
      await setDeleteCat(category)
      await setDeleteModal(true)
    } catch (error) {
      window.alert(error.message)
    }
  }

  const clearFilter = async () => {
    try {
      await setName("")
      await setPage(1)
      await refetch({ variables: { page: 1 } })
    } catch (error) {
      window.alert(error.message)
    }
  }

  const setUpRoles = async (roles) => {
    try {
      const modify = roles
        .filter((r) => !r.company_id)
        .some((r) => r.is_super_admin || r.is_admin)

      const add = roles.filter((r) => !r.company_id).some((r) => r.is_super_admin)

      await setCanModify(modify)
      await setCanAdd(add)

      return true
    } catch (error) {
      throw error
    }
  }
  useEffect(() => {
    if (roles) {
      setUpRoles(roles)
        .then(() => {})
        .catch((err) => console.log("error", err))
    }
  }, [roles])
  const editedInitialValues = {
    id: editedCat && editedCat.id ? editedCat.id : null,
    name: editedCat && editedCat.name ? editedCat.name : "",
    description: editedCat && editedCat.description ? editedCat.description : "",
    is_active: editedCat && editedCat.is_active ? true : false,
    priority: editedCat && editedCat.priority ? editedCat.priority : 0,
    file: null,
  }
  return (
    <div className={classes.root}>
      <CategoryToolbar
        openModal={async () => await setCreateModal(true)}
        name={name}
        setName={setName}
        canModify={canModify}
        search={search}
        clearFilter={clearFilter}
      />

      <Formik
        initialValues={{
          name: "",
          description: "",
          file: null,
          priority: 0,
        }}
        validationSchema={creatCategorySchema}
        onSubmit={createCategory}
      >
        {(props) => (
          <ModalDialog
            open={createModal}
            cancel={async () => {
              await setCreateModal(false)

              await props.handleReset()
            }}
            title={"Nouvelle Catégorie"}
          >
            <CategoryCreate {...props} globalError={globalCreateCatError}  canAdd={canAdd}/>
          </ModalDialog>
        )}
      </Formik>

      {editModal && (
        <Formik
          initialValues={editedInitialValues}
          validationSchema={updateCategorySchema}
          onSubmit={updateCategory}
        >
          {(props) => (
            <ModalDialog
              open={editModal}
              cancel={async () => {
                await setEditModal(false)
                await setEditedCat(null)

                await props.handleReset()
              }}
              title={"Edition catégorie"}
            >
              <CategoryEdit
                {...props}
                canModify={canModify}
                category={editedCat}
                globalError={globalEditCatError}
              />
            </ModalDialog>
          )}
        </Formik>
      )}

      <Formik
        initialValues={{
          category_id: deleteCat && deleteCat.id ? deleteCat.id : null,
        }}
        validationSchema={deleteCategorySchema}
        onSubmit={removeCategory}
      >
        {(props) => (
          <ConfirmDialog
            open={deleteModal}
            cancel={async () => {
              await setDeleteModal(false)
              await setDeleteCat(null)
              await props.handleReset()
            }}
            confirmAction={props.handleSubmit}
            title={"Suppression catégorie"}
          >
            <CategoryDelete
              {...props}
              canAdd={canAdd}
              category={deleteCat}
              globalError={globalDeleteCatError}
            />
          </ConfirmDialog>
        )}
      </Formik>

      <div className={classes.content}>
        {(loading1 || loading || loading2) && <Loading />}
        <CategoryList
          data={data && data.findCats ? data.findCats : null}
          onPageChange={onPageChange}
          selectCategory={selectCategory}
          selectCatToDelete={selectCatToDelete}
          time_zone={timezone}
          canAdd={canAdd}
        />
      </div>
    </div>
  )
}

export default CategoryView
