export const colors = {
  PRIMARY: "#55ACEE",
  SECONDARY: "#444B52",
  WHITE: "#FFFFFF",
  LIGHT_GRAY: "#E0E0E0",
  LIGHT_GREEN: "#00FA9A",
  BLUE: "#17a2b8",
  SOFT_ORANGE: "#F8EFBA",
  ORANGE: "#FF7F50",
  SOFT_GREEN: "#dcf8c6",
  BEIGE: "#F5F5DC",
  RED: "red",
  PINK: "#FFD700",
  //  VIOLET: "#8A2BE2",
  VIOLET: "#0131b4",
}
