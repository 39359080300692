import React, { useState } from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { useQuery } from "react-apollo"
import Loading from "components/Loading"

import { Grid, makeStyles } from "@material-ui/core"
import Pagination from "@material-ui/lab/Pagination"
import { Accordion } from "components/Accordion"
import { readNumber } from "utils/readnumber"
import { compSignalCommercialQuery } from "graphql/query/client_signal"
const useStyles = makeStyles((theme) => ({
  root:{
    padding:'9px 25px'
  },
  table: {
    minWidth: 650,
  },

  statsIcon: {
    color: theme.palette.icon,
    marginRight: theme.spacing(1),
  },
}))
const ClientSignalCommercialView = () => {
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [expanded1, setExpanded1] = useState(true)
  const {
    loading: loading1,
    data,
    fetchMore,
  } = useQuery(compSignalCommercialQuery, {
    variables: { page },
  })

  const classes = useStyles()
  const clients =
    data && data.compSignalCommercial ? data.compSignalCommercial : null
  if (loading1 || loading) {
    return <Loading />
  }
  const onPageChange = async (page) => {
    try {
      await setLoading(true)
      await setPage(page)

      let variables = { page }

      fetchMore({
        variables,
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev

          const { compSignalCommercial } = fetchMoreResult
          return Object.assign({}, prev, {
            compSignalCommercial,
          })
        },
      })

      await setLoading(false)
    } catch (error) {
      throw error
    }
  }
  return (
   <div className={classes.root}>

    <Accordion
          id={"search-client-form"}
          title={"Listes des clients"}
          expanded={expanded1}
          setExpanded={setExpanded1}
        >
          <Grid container spacing={3}>
            {clients && (
              <Grid item xs={12} sm={12}>
                <Pagination
                  count={clients.totalPages}
                  page={page}
                  onChange={async (_event, value) => {
                    if (value.toString() !== page.toString()) {
                      await onPageChange(value)
                    }
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={12} style={{ float: "right" }}>
              {readNumber(clients.totalDocs) + " clients"}
            </Grid>
            <Grid item xs={12} sm={12}>
              <TableContainer>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Alertes des utilisateurs</TableCell>
                      <TableCell>Message</TableCell>
                      <TableCell>Entreprise</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {clients &&
                      clients.docs.map((evt, index) => (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{evt.message}</TableCell>
                          {evt.service && evt.service.name && (
                            <TableCell>{evt.service.name}</TableCell>
                          )}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Accordion>

   </div>
  )
}
export default ClientSignalCommercialView
