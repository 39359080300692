import gql from "graphql-tag"

export const findAdminHistoryQuery = gql`
  query findAdminHistory(
    $page: Int!
 
    $start: String
    $end: String
    $author_ids: [String]
  ) {
    findAdminHistory(
      page: $page
      start: $start
      end: $end

      author_ids: $author_ids
    ) {
      totalDocs
      hasPrevPage
      totalPages
      hasNextPage
      limit
      offset
      page
      prevPage
      nextPage
      docs {
        id
        author_id
        model_name
        title
        description
        company_id
        createdAt
      }
    }
  }
`
