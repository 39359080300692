import React from "react"
import { Link as RouterLink } from "react-router-dom"
import { makeStyles } from "@material-ui/styles"
import { Grid, Button, TextField, Link, Typography } from "@material-ui/core"

import MuiPhoneInput from "material-ui-phone-number"

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: "100%"
  },
  grid: {
    height: "100%"
  },
  quoteContainer: {
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: "url(/images/auth.jpg)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center"
  },
  quoteInner: {
    textAlign: "center",
    flexBasis: "600px"
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  contentHeader: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center"
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  }
}))

const Login = ({
  touched,
  errors,
  setFieldValue,
  handleSubmit,
  isSubmitting,
  handleChange,
  values
}) => {
  const classes = useStyles()
   
  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.content} item lg={7} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentBody}>
              <form className={classes.form} onSubmit={handleSubmit}>
                <Grid className={classes.socialButtons} container spacing={2}></Grid>
                <Typography
                  align="center"
                  className={classes.sugestion}
                  color="textSecondary"
                  variant="body1"
                >
                  Connexion
                </Typography>

                <MuiPhoneInput
                  defaultCountry="sn"
                  onlyCountries={["sn"]}
                  region={"africa"}
                  as={TextField}
                  className={classes.textField}
                  error={touched.phone && errors.phone ? true : false}
                  fullWidth
                  helperText={touched.phone && errors.phone ? errors.phone : null}
                  label="Téléphone"
                  name="phone"
                  onChange={async (phone) => setFieldValue("phone", phone)}
                  type="text"
                  value={values.phone}
                  variant="outlined"
                />

                <TextField
                  className={classes.textField}
                  error={touched.password && errors.password ? true : false}
                  fullWidth
                  helperText={
                    touched.password && errors.password ? errors.password : null
                  }
                  label="Password"
                  name="password"
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Button
                  className={classes.signInButton}
                  color="primary"
                  //disabled={!formState.isValid}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Se connecter
                </Button>
                <Typography color="textSecondary" variant="body1">
                  Mot de passe oublié ?{" "}
                  <Link component={RouterLink} to="/sign-up" variant="h6">
                    sms de récupération
                  </Link>
                </Typography>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default Login
