import gql from "graphql-tag"

export const deleteContactMutation = gql`
  mutation deleteContact($id: String!) {
    deleteContact(id: $id) {
      ok
      id
      errors {
        path
        message
      }
    }
  }
`
