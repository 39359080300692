import React, { useState } from "react"
import { Button, Grid, TextField } from "@material-ui/core"

import { Accordion } from "components/Accordion"

const style = { padding: 30 }

export const CategoryToolbar = (props) => {
  const {
    openModal,

    setName,
    name,
    canModify,
    search,
    clearFilter,
  } = props

  const [expanded1, setExpanded1] = useState(true)
  return (
    <Accordion
      id={"search-client-filter"}
      title={"Filtrer Les secteurs d'activités "}
      expanded={expanded1}
      setExpanded={setExpanded1}
    >
      <Grid
       
        direction="column"
        alignItems="flex-end"
        
      >
        {canModify && (
          <div className="btn-left-setting">
              <Button
              color="primary"
              variant="contained"
              onClick={openModal}
              style={{ float: "right" }}
            >
              Ajouter un secteur d'activité
            </Button>
          </div>
        )}
      </Grid>
      <Grid style={{ width: "100%" }}>
        {/*  <Grid item xs={12} sm={12} style={style}>
            <FormControlLabel
              control={
                <Checkbox
                  id="is_active"
                  name="is_active"
                  checked={is_active}
                  onChange={async (_e, value) => await setIsActive(!is_active)}
                />
              }
              label={is_active ? "Compte actif" : "Compte inactif"}
            />
          </Grid> */}
        <Grid item xs={12} sm={12} style={style}>
          <TextField
            id="name"
            name="name"
            label="Nom"
            onChange={async ({ target: { value } }) => await setName(value)}
            fullWidth
            value={name}
          />
        </Grid>

        <div class="btn-setting">
          <Button
            color="secondary"
            //disabled={!formState.isValid}
            type="submit"
            onClick={clearFilter}
            variant="contained"
          >
            Effacer les filtres
          </Button>

          <Button
            color="primary"
            //disabled={!formState.isValid}
            type="submit"
            onClick={search}
            variant="contained"
          >
            Chercher
          </Button>
        </div>
        
      </Grid>
    </Accordion>
  )
}
