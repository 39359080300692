import React from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Slide from "@material-ui/core/Slide"
import PerfectScrollbar from "react-perfect-scrollbar"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export const ModalDialog = (props) => {
  const { open, cancel, title, children, ...rest } = props
  return (
   
      <Dialog
        open={open}
        TransitionComponent={Transition}
        {...rest}
        onClose={cancel}
        fullWidth={true}
        maxWidth={"md"}
      > <PerfectScrollbar>
        <DialogTitle id="alert-dialog-slide-title" style={{ textAlign: "center" }}>
          {title}
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button onClick={cancel} color="primary">
            fermer
          </Button>
          {/* <Button onClick={cancel} color="primary">
            Agree
          </Button> */}
        </DialogActions>
        </PerfectScrollbar>
      </Dialog>
   
  )
}
