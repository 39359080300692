import React from "react"
import { Router } from "react-router-dom"
import { useMutation } from "react-apollo"
import { createBrowserHistory } from "history"

import { ThemeProvider } from "@material-ui/styles"

import theme from "./theme"
import "react-perfect-scrollbar/dist/css/styles.css"
import "./assets/scss/index.scss"

import Routes from "./Routes"
import { LOGIN_USER_MUTATION } from "./graphql/mutation/user"
import { useComponentWillMount } from "./components/useComponentWillMount"
import { TOKEN_NAME } from "utils/static_constants"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
const browserHistory = createBrowserHistory()

function App() {
  const [setUser] = useMutation(LOGIN_USER_MUTATION)
  const reconnect = async () => {
    try {
      const token = await localStorage.getItem(TOKEN_NAME)

      if (token) {
        const currentUser = {
          authenticated: true,
          token,
        }
        await setUser({
          variables: {
            currentUser,
          },
        })
      }
    } catch (error) {
      throw error
    }
  }

  useComponentWillMount(() => {
    reconnect()
      .then(() => console.log("reconnected"))
      .catch((err) => console.log("error ComponentWillMount", err))
  })
  return (
    <ThemeProvider theme={theme}>
      <Router history={browserHistory}>
        <Routes />
      </Router>
      <ToastContainer
        autoClose={false}
        style={{
          marginTop: 70,
          padding: 30,
          fontSize: 12,
          fontStyle: "italic",
          width: "30%",
        }}
      />
    </ThemeProvider>
  )
}

export default App
