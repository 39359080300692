import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import { makeStyles, useTheme } from "@material-ui/styles"
import { useMediaQuery } from "@material-ui/core"

import { Sidebar, Topbar, Footer } from "./components"
import { currentUserQuery } from "graphql/query/user"
import { useQuery } from "@apollo/react-hooks"
import DashboardIcon from "@material-ui/icons/Dashboard"
import PeopleIcon from "@material-ui/icons/People"
import CalendarIcon from "@material-ui/icons/CalendarToday"
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn"
import JobIcon from "@material-ui/icons/FolderOpenOutlined"
import AccountBoxIcon from "@material-ui/icons/AccountBox"
import SettingsIcon from "@material-ui/icons/Settings"
import SubsIcon from "@material-ui/icons/Subscriptions"
import CompanyIcon from "@material-ui/icons/AccountBalance"
import HistoryIcon from "@material-ui/icons/History"
import MessageIcon from "@material-ui/icons/MessageOutlined"
import AlertIcon from "@material-ui/icons/AlarmAddOutlined"
import {
  CATEGORY_PATH,
  COMPANY_PATH,
  DASHBOARD_PATH,
  SETTING_PATH,
  PROFILE_PATH,
  USERS_PATH,
  SUBSCRIPTION_PATH,
  SITE_SETTING_PATH,
  TEAM_PATH,
  SITE_SETTING_EDIT_PATH,
  BILLS_PATH,
  HISTORY_PATH,
  FACTURE_PATH,
  EVENT_PATH,
  JOB_PATH,
  CONTACT_MESSAGE_PATH,
  COMPANY_COMMERCIAL_PATH,
  COMMERCIAL_BILLS_PATH,
  CLIENT_SIGNAL_COMMERCIAL_PATH,
} from "utils/static_constants"
import moment from "moment-timezone"
import "moment/locale/fr"
moment.locale("fr")
const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 56,
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      paddingTop: 64,
    },
  },

  content: {
    height: "100%",
    transitionDuration: "270ms",
  },
  isDesktopCotent: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 240,
    },
  },
}))

const Main = (props) => {
  const { children } = props
  const { data: userData, loading } = useQuery(currentUserQuery, {
    fetchPolicy: "network-only",
  })

  const roles =
    userData && userData.onlineUser && userData.onlineUser.roles
      ? userData.onlineUser.roles
      : []
  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true,
  })

  const [openSidebar, setOpenSidebar] = useState(true)
  const [computedRole, setComputedRole] = useState(false)
  const [pages, setPages] = useState([
    {
      title: "Paramétrages",
      href: SETTING_PATH,
      icon: <SettingsIcon />,
    },
    {
      title: "Mon Profile",
      href: PROFILE_PATH,
      icon: <AccountBoxIcon />,
    },
    {
      title: "SECTEURS D'ACTIVITES",
      href: CATEGORY_PATH,
      icon: <SettingsIcon />,
    },
  ])

  const handleSidebarOpen = async () => {
    await setOpenSidebar(!openSidebar)
  }

  const handleSidebarClose = () => {
    setOpenSidebar(false)
  }

  // const shouldOpenSidebar = isDesktop ? true : openSidebar;

  let worker = []
  let commercial = [
    {
      title: "Portefeuille",
      href: COMPANY_COMMERCIAL_PATH,
      icon: <CompanyIcon />,
    },

    {
      title: "Mes Factures",
      href: COMMERCIAL_BILLS_PATH,
      icon: <MonetizationOnIcon />,
    },

    {
      title: "Mes alertes",
      href: CLIENT_SIGNAL_COMMERCIAL_PATH,
      icon: <AlertIcon />,
    },
  ]

  let superadmin = [
    {
      title: "Historiques des Activités",
      href: HISTORY_PATH,
      icon: <HistoryIcon />,
    },
    {
      title: `Facture ${moment().format("MMM YYYY")}`,
      href: BILLS_PATH,
      icon: <MonetizationOnIcon />,
    },
    {
      title: "Offres D'emplois",
      href: JOB_PATH,
      icon: <JobIcon />,
    },
    {
      title: `Factures`,
      href: FACTURE_PATH,
      icon: <MonetizationOnIcon />,
    },
  ]
  let admin = [
    {
      title: "Utilisateurs",
      href: USERS_PATH,
      icon: <PeopleIcon />,
    },
    {
      title: "Les Rendez-vous",
      href: EVENT_PATH,
      icon: <CalendarIcon />,
    },

    {
      title: "Messages",
      href: CONTACT_MESSAGE_PATH,
      icon: <MessageIcon />,
    },
   

    {
      title: "Personnel Entreprise",
      href: TEAM_PATH,
      icon: <PeopleIcon />,
    },

    {
      title: "Abonnements",
      href: SUBSCRIPTION_PATH,
      icon: <SubsIcon />,
    },
    {
      title: "Paramétrages Site web",
      href: SITE_SETTING_EDIT_PATH,
      icon: <SettingsIcon />,
    },

    {
      title: "Entreprises",
      href: COMPANY_PATH,
      icon: <CompanyIcon />,
    },
  ]
  let adminManager = []
  const setUpTabs = async (roles) => {
    try {
      let tabs = pages

      roles.forEach(async (role, _i) => {
        if (role.is_super_admin) {
          
          superadmin.forEach((t) => {
            if (tabs.findIndex((r) => r.title == t.title) < 0) {
              tabs.push(t)
            }
          })

          admin.forEach((t) => {
            if (tabs.findIndex((r) => r.title == t.title) < 0) {
              tabs.push(t)
            }
          })
        }

        if (role.is_admin) {
          // tabs = tabs.concat(superadmin).concat(adminManager)

          admin.forEach((t) => {
            if (tabs.findIndex((r) => r.title == t.title) < 0) {
              tabs.push(t)
            }
          })
        }

        if (role.is_commercial) {
          
          commercial.forEach((t) => {
            if (tabs.findIndex((r) => r.title == t.title) < 0) {
              tabs.push(t)
            }
          })
        }

        /* if (role.is_worker) {
          tabs = tabs.concat(worker)
        }

        if (role.is_manager) {
          tabs = tabs.concat(adminManager)
        } */
      })

      await setPages(tabs)
      await setComputedRole(true)
      return true
    } catch (error) {
     
      throw error
    }
  }
  useEffect(() => {
    if (roles && roles.length > 0) {
    
      setUpTabs(roles)
        .then(async () => {})
        .catch((err) => console.log("error", err))
    }
  }, [loading, roles])

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop,
      })}
    >
      <Topbar onSidebarOpen={handleSidebarOpen} openSidebar={openSidebar} />
      <Sidebar
        key={pages.length}
        computedRole={computedRole}
        onClose={handleSidebarClose}
        open={openSidebar}
        variant={isDesktop ? "persistent" : "temporary"}
        pages={pages}
      />

      <main
        className={clsx({
          [classes.content]: true,
          [classes.isDesktopCotent]: openSidebar,
        })}
      >
        {children}
        <Footer />
      </main>
    </div>
  )
}

Main.propTypes = {
  children: PropTypes.node,
}

export default Main
