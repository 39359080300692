import React, { useState } from "react"
import { useQuery, useMutation } from "react-apollo"
import * as Yup from "yup"
import { Formik } from "formik"
import { makeStyles } from "@material-ui/styles"
import Loading from "components/Loading"
import { updateSiteSettingAdminMutation } from "graphql/mutation/site_setting"
import { oneSiteSettingAdminQuery } from "graphql/query/site_setting"
import { SettingEdit } from "./components/SettingEdit"
import { SITE_SETTING_PATH } from "utils/static_constants"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}))
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"]
const updateSchema = Yup.object().shape({
  id: Yup.string().trim().nullable(),
  cgu: Yup.string().trim().nullable(),

  phone: Yup.string().trim().nullable(),

  email: Yup.string().trim().nullable(),

  facebook_page: Yup.string().trim().nullable(),
  twitter_page: Yup.string().trim().nullable(),

  linkedin_page: Yup.string().trim().nullable(),

  youtube_link: Yup.string().trim().nullable(),
  youtube_link_comp: Yup.string().trim().nullable(),

  saler_playstore_link: Yup.string().trim().nullable(),
  saler_appletore_link: Yup.string().trim().nullable(),
  saler_web_link: Yup.string().trim().nullable(),

  worker_playstore_link: Yup.string().trim().nullable(),
  worker_appletore_link: Yup.string().trim().nullable(),

  client_playstore_link: Yup.string().trim().nullable(),
  client_appletore_link: Yup.string().trim().nullable(),
  client_web_link: Yup.string().trim().nullable(),
  cgu_comp: Yup.string().trim().nullable(),
  phone_fix: Yup.string().trim().nullable(),
  instagram_page: Yup.string().trim().nullable(),
  youtube_page: Yup.string().trim().nullable(),
  file: Yup.mixed()
    .nullable()
    .test("fileFormat", "extension non acceptée", (value) => {
      if (value) {
        return value && SUPPORTED_FORMATS.includes(value.type)
      }
      return true
    }),
})

const SettingView = ({ history }) => {
  const classes = useStyles()

  const [globalEditError, setGlobalEditError] = useState("")
  const [editModal, setEditModal] = useState(false)

  const { loading: loading2, data } = useQuery(oneSiteSettingAdminQuery)

  const [updateItem] = useMutation(updateSiteSettingAdminMutation, {
    async update(
      cache,
      {
        data: {
          updateSiteSettingAdmin: { ok, setting },
        },
      }
    ) {
      if (ok) {
        await cache.writeQuery({
          query: oneSiteSettingAdminQuery,
          data: {
            oneSiteSettingAdmin: setting,
          },
        })
      }
    },
  })

  if (loading2) {
    return <Loading />
  }

  const update = async (
    values,

    { setSubmitting, setFieldError, handleReset }
  ) => {
    try {
      const {
        id,
        cgu,

        phone,

        email,

        facebook_page,
        twitter_page,

        linkedin_page,

        youtube_link,
        youtube_link_comp,

        saler_playstore_link,
        saler_appletore_link,
        saler_web_link,

        worker_playstore_link,
        worker_appletore_link,

        client_playstore_link,
        client_appletore_link,
        client_web_link,
        cgu_comp,
        phone_fix,
        instagram_page,
        youtube_page,
        file,
      } = values
      let variables = { id }
      let data = {}
      if (youtube_link_comp) {
        data.youtube_link_comp = youtube_link_comp
      }
      if (file) {
        data.file = file
      }
      if (saler_appletore_link) {
        data.saler_appletore_link = saler_appletore_link
      }

      if (saler_playstore_link) {
        data.saler_playstore_link = saler_playstore_link
      }

      if (youtube_page) {
        data.youtube_page = youtube_page
      }
      if (instagram_page) {
        data.instagram_page = instagram_page
      }

      if (phone_fix) {
        data.phone_fix = phone_fix
      }

      if (cgu_comp) {
        data.cgu_comp = cgu_comp
      }
      if (client_web_link) {
        data.client_web_link = client_web_link
      }
      if (client_appletore_link) {
        data.client_appletore_link = client_appletore_link
      }
      if (client_playstore_link) {
        data.client_playstore_link = client_playstore_link
      }
      if (worker_appletore_link) {
        data.worker_appletore_link = worker_appletore_link
      }
      if (worker_playstore_link) {
        data.worker_playstore_link = worker_playstore_link
      }
      if (saler_web_link) {
        data.saler_web_link = saler_web_link
      }
      if (instagram_page) {
        data.instagram_page = instagram_page
      }

      if (cgu) {
        data.cgu = cgu
      }
      if (phone) {
        data.phone = phone
      }
      if (email) {
        data.email = email
      }
      if (facebook_page) {
        data.facebook_page = facebook_page
      }
      if (twitter_page) {
        data.twitter_page = twitter_page
      }
      if (linkedin_page) {
        data.linkedin_page = linkedin_page
      }
      if (youtube_link) {
        data.youtube_link = youtube_link
      }
      variables.setting = data
      const res = await updateItem({
        variables,
      })
      const { ok, errors } = res.data.updateSiteSettingAdmin

      if (ok) {
        await setSubmitting(false)
        await setEditModal(false)
        window.alert("Modifications enregistrées")
      } else {
        errors.forEach(async (error) => {
          if (error.path && error.path.toString() === "global") {
            await setGlobalEditError(error.message)
          } else {
            await setFieldError(error.path, error.message)
            await setSubmitting(false)
          }
        })
      }
      await handleReset()
    } catch (error) {
      throw error
    }
  }

  const isSetting =
    data && data.oneSiteSettingAdmin ? data.oneSiteSettingAdmin : null

  return (
    <div className={classes.root}>
      <Formik
        initialValues={{
          id: isSetting && isSetting.id ? isSetting.id : "",
          cgu: isSetting && isSetting.cgu ? isSetting.cgu : null,
          cgu_comp: isSetting && isSetting.cgu_comp ? isSetting.cgu_comp : "",
          phone: isSetting && isSetting.phone ? isSetting.phone : "",
          phone_fix: isSetting && isSetting.phone_fix ? isSetting.phone_fix : "",
          email: isSetting && isSetting.email ? isSetting.email : "",
          facebook_page:
            isSetting && isSetting.facebook_page ? isSetting.facebook_page : "",
          twitter_page:
            isSetting && isSetting.twitter_page ? isSetting.twitter_page : "",
          instagram_page:
            isSetting && isSetting.instagram_page ? isSetting.instagram_page : "",
          linkedin_page:
            isSetting && isSetting.linkedin_page ? isSetting.linkedin_page : "",
          youtube_page:
            isSetting && isSetting.youtube_page ? isSetting.youtube_page : "",
          youtube_link:
            isSetting && isSetting.youtube_link ? isSetting.youtube_link : "",
          youtube_link_comp:
            isSetting && isSetting.youtube_link_comp
              ? isSetting.youtube_link_comp
              : "",
          saler_playstore_link:
            isSetting && isSetting.saler_playstore_link
              ? isSetting.saler_playstore_link
              : "",
          saler_appletore_link:
            isSetting && isSetting.saler_appletore_link
              ? isSetting.saler_appletore_link
              : "",
          saler_web_link:
            isSetting && isSetting.saler_web_link ? isSetting.saler_web_link : "",
          worker_playstore_link:
            isSetting && isSetting.worker_playstore_link
              ? isSetting.worker_playstore_link
              : "",
          worker_appletore_link:
            isSetting && isSetting.worker_appletore_link
              ? isSetting.worker_appletore_link
              : "",
          client_playstore_link:
            isSetting && isSetting.client_playstore_link
              ? isSetting.client_playstore_link
              : "",
          client_appletore_link:
            isSetting && isSetting.client_appletore_link
              ? isSetting.client_appletore_link
              : "",
          file: null,
          client_web_link:
            isSetting && isSetting.client_web_link ? isSetting.client_web_link : "",
        }}
        validationSchema={updateSchema}
        onSubmit={update}
      >
        {(props) => (
          <SettingEdit
            {...props}
            item={isSetting}
            globalError={globalEditError}
            picture={isSetting && isSetting.picture ? isSetting.picture : null}
          />
        )}
      </Formik>
    </div>
  )
}

export default SettingView
