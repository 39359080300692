import React from "react"
import { useTheme } from "@material-ui/styles"
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from "@material-ui/core"
import { SelectField } from "components/SelectField"

export const TeamToolbar = (props) => {
  const {
    openModal,
    phone,
    name,
    email,
    setEmail,
    search,
    roles,
    setName,
    setRoles,
    selectedRoles,
    setPhone,
    clearFilter,
    phone_code,
    setIsActive,
    is_active,
  } = props

  const theme = useTheme()

  return (
    <Grid container spacing={3} style={{ padding: '0px 20px' }}>
     
      <Grid item lg={6} md={6} sm={6} xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                id="is_active"
                name="is_active"
                checked={is_active}
                onChange={async (_e, value) => await setIsActive(!is_active)}
              />
            }
            label={is_active ? "Compte actif" : "Compte inactif"}
          />
        </Grid>
        <Grid lg={6} md={6} sm={6} xs={12}>
             <Button color="primary" variant="contained" onClick={openModal}>
                Ajouter un personnel
            </Button>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <TextField
            id="name"
            name="name"
            label="Nom"
            onChange={async ({ target: { value } }) => await setName(value)}
            fullWidth
            value={name}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <TextField
            fullWidth
            required={false}
            id="email"
            name="email"
            label="Email"
            value={email}
            onChange={async ({ target: { value } }) => await setEmail(value)}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <SelectField
            multiple={true}
            handleChange={async ({ target: { value } }) => {
              await setRoles(value)
            }}
            name="rolename"
            label={"Role"}
            value={selectedRoles}
            theme={theme}
            dataSource={roles}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          {/*  <PhoneField
            fullWidth={true}
            required={false}
            value={phone}
            setFieldValue={(data)=>{
            
            }}
            label="Téléphone"
            name="phone"
          /> */}

          <TextField
            fullWidth
            required={false}
            id="phone"
            name="phone"
            label={`Téléphone ${phone_code}`}
            value={phone}
            onChange={async ({ target: { value } }) => await setPhone(value)}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <div className="content-btn-setting-generale">
            <Button
              color="secondary"
              //disabled={!formState.isValid}
              
              type="submit"
              onClick={clearFilter}
              variant="contained"
            >
              Effacer les filtres
            </Button>

            <Button
              color="primary"
              //disabled={!formState.isValid}
              
              type="submit"
              onClick={search}
              variant="contained"
            >
              Chercher
            </Button>
          </div>
        </Grid>
      
    </Grid>
  )
}
