import React, { useEffect, useState } from "react"
import { useQuery, useMutation } from "react-apollo"
import * as Yup from "yup"
import { Formik } from "formik"
import { makeStyles } from "@material-ui/styles"
import moment from "moment-timezone"
import Loading from "components/Loading"
import { SettingToolbar } from "./components/SettingToolbar"
import { SettingCreate } from "./components/SettingCreate"
import { ModalDialog } from "components/ModalDialog"
import {
  createSettingAdminMutation,
  updateSettingAdminMutation,
} from "graphql/mutation/setting_admin"
import { oneSettingAdminQuery } from "graphql/query/setting_admin"
import { Setting } from "./components/Setting"
import { SettingEdit } from "./components/SettingEdit"
import { currentUserQuery } from "graphql/query/user"

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: theme.spacing(3),
  },
  content: {
    // marginTop: theme.spacing(2),
    padding:'15px 35px'
  },
}))

const createSchema = Yup.object().shape({
  phone_min_size: Yup.number().required("champ requis"),
  phone_max_size: Yup.number().required("champ requis"),
  minimal_cancel: Yup.number().required("champ requis"),
  time_zone: Yup.string()
    .trim()
    .min(2, "minimun 2 caractères")
    .max(50, "maximum 50 caractères")
    .required("champ requis"),

  phone_code: Yup.string()
    .trim()
    .min(2, "minimun 1 caractères")
    .max(50, "maximum 50 caractères")
    .required("champ requis"),
  sms_price: Yup.number().required("champ requis"),

  currency: Yup.string()
    .trim()
    .min(1, "minimun 2 caractères")
    .max(50, "maximum 50 caractères")
    .required("champ requis"),
  country: Yup.string()
    .trim()
    .min(2, "minimun 2 caractères")
    .max(50, "maximum 50 caractères")
    .required("champ requis"),

  regions: Yup.array().of(Yup.string()).required("champ requis"),
})

const updateSchema = Yup.object().shape({
  phone_min_size: Yup.number().nullable(),
  phone_max_size: Yup.number().nullable(),
  minimal_cancel: Yup.number().nullable(),
  id: Yup.string().trim().nullable(),
  time_zone: Yup.string()
    .trim()
    .min(2, "minimun 2 caractères")
    .max(50, "maximum 50 caractères")
    .nullable(),
  sms_price: Yup.number().nullable(),
  phone_code: Yup.string()
    .trim()
    .min(2, "minimun 1 caractères")
    .max(50, "maximum 50 caractères")
    .nullable(),
  currency: Yup.string()
    .trim()
    .min(1, "minimun 2 caractères")
    .max(50, "maximum 50 caractères")
    .nullable(),
  country: Yup.string()
    .trim()
    .min(2, "minimun 2 caractères")
    .max(50, "maximum 50 caractères")
    .nullable(),

  regions: Yup.array().of(Yup.string()).required("champ requis"),
})

const SettingView = () => {
  const classes = useStyles()
  const time_zones = moment.tz.names()
  const [createModal, setCreateModal] = useState(false)
  const [canModify, setCanModify] = useState(false)
  const [globalCreateError, setGlobalCreateError] = useState("")
  const [globalEditError, setGlobalEditError] = useState("")
  const [editModal, setEditModal] = useState(false)
  const [region, setRegion] = useState("")
  const [errorRegion, setErrorRegion] = useState("")
  const { loading: loading2, data } = useQuery(oneSettingAdminQuery)
  const { data: userData } = useQuery(currentUserQuery, {
    fetchPolicy: "network-only",
  })
  const roles =
    userData && userData.onlineUser && userData.onlineUser.roles
      ? userData.onlineUser.roles
      : []
  const [createItem] = useMutation(createSettingAdminMutation, {
    async update(
      cache,
      {
        data: {
          createSettingAdmin: { ok, setting },
        },
      }
    ) {
      if (ok) {
        await cache.writeQuery({
          query: oneSettingAdminQuery,
          data: {
            oneSettingAdmin: setting,
          },
        })
      }
    },
  })

  const [updateItem] = useMutation(updateSettingAdminMutation, {
    async update(
      cache,
      {
        data: {
          updateSettingAdmin: { ok, setting },
        },
      }
    ) {
      if (ok) {
        await cache.writeQuery({
          query: oneSettingAdminQuery,
          data: {
            oneSettingAdmin: setting,
          },
        })
      }
    },
  })

  const setUpRoles = async (roles) => {
    try {
      const modify = roles
        .filter((r) => !r.company_id)
        .some((r) => r.is_super_admin || r.is_admin)

      const add = roles
        .filter((r) => !r.company_id)
        .some((r) => r.is_super_admin || r.is_admin)

      await setCanModify(modify)

      return true
    } catch (error) {
      throw error
    }
  }
  useEffect(() => {
    if (roles) {
      setUpRoles(roles)
        .then(() => {})
        .catch((err) => console.log("error", err))
    }
  }, [roles])

  if (loading2) {
    return <Loading />
  }

  const create = async (
    values,

    { setSubmitting, setFieldError }
  ) => {
    try {
      const { phone_min_size, phone_max_size } = values
      if (phone_min_size && phone_max_size) {
        if (phone_min_size > phone_max_size) {
          await setFieldError("phone_min_size", "Taille téléphone incorrecte")
          return
        }
      }

      const res = await createItem({
        variables: {
          ...values,
          phone_min_size,
          phone_max_size,
        },
      })

      const { ok, errors } = res.data.createSettingAdmin
      if (ok) {
        await setSubmitting(false)
        await setCreateModal(false)
      } else {
        errors.forEach(async (error) => {
          if (error.path && error.path.toString() === "global") {
            await setGlobalCreateError(error.message)
          } else {
            await setFieldError(error.path, error.message)
            await setSubmitting(false)
          }
        })
      }
    } catch (error) {
      throw error
    }
  }

  const update = async (
    values,

    { setSubmitting, setFieldError, handleReset }
  ) => {
    try {
      const {
        id,
        country,
        currency,
        phone_code,
        time_zone,
        regions,
        sms_price,
        phone_min_size,
        phone_max_size,
        minimal_cancel,
      } = values
      let variables = { id }

      if (phone_min_size && phone_max_size) {
        if (phone_min_size > phone_max_size) {
          await setFieldError("phone_min_size", "Taille téléphone incorrecte")
          return
        }
      }
      if (minimal_cancel) {
        variables.minimal_cancel = minimal_cancel
      }
      if (phone_max_size) {
        variables.phone_max_size = phone_max_size
      }
      if (phone_min_size) {
        variables.phone_min_size = phone_min_size
      }
      if (country) {
        variables.country = country
      }
      if (currency) {
        variables.currency = currency
      }
      if (phone_code) {
        variables.phone_code = phone_code
      }
      if (time_zone) {
        variables.time_zone = time_zone
      }
      if (sms_price) {
        variables.sms_price = sms_price
      }
      if (regions) {
        variables.regions = regions
      }

      const res = await updateItem({
        variables,
      })
      const { ok, errors } = res.data.updateSettingAdmin

      if (ok) {
        await setSubmitting(false)
        await setEditModal(false)
      } else {
        errors.forEach(async (error) => {
          if (error.path && error.path.toString() === "global") {
            await setGlobalEditError(error.message)
          } else {
            await setFieldError(error.path, error.message)
            await setSubmitting(false)
          }
        })
      }
      await handleReset()
    } catch (error) {
      throw error
    }
  }

  const selectForUpdate = async (item) => {
    try {
      await setEditModal(true)
    } catch (error) {
      throw error
    }
  }

  return (
    <div className={classes.root}>
      <SettingToolbar
        show={
          !data || !data.oneSettingAdmin || !data.oneSettingAdmin.id ? true : false
        }
        openModal={async () => await setCreateModal(true)}
      />

      <Formik
        initialValues={{
          country: "",
          currency: "",
          phone_code: "",
          time_zone: "",
          regions: [],
          sms_price: "",
          minimal_cancel: 24,
          phone_max_size: 0,
          phone_min_size: 0,
        }}
        validationSchema={createSchema}
        onSubmit={create}
      >
        {(props) => (
          <ModalDialog
            open={createModal && canModify}
            cancel={async () => {
              await props.handleReset()
              await setCreateModal(false)
            }}
            title={"Paramétrage"}
          >
            <SettingCreate
              {...props}
              globalError={globalCreateError}
              time_zones={time_zones}
              errorRegion={errorRegion}
              setErrorRegion={setErrorRegion}
              region={region}
              setRegion={setRegion}
            />
          </ModalDialog>
        )}
      </Formik>
      <Formik
        initialValues={{
          id:
            data && data.oneSettingAdmin && data.oneSettingAdmin.id
              ? data.oneSettingAdmin.id
              : null,
          country: "",
          currency: "",
          phone_code: "",
          phone_max_size:
            data && data.oneSettingAdmin && data.oneSettingAdmin.phone_max_size
              ? data.oneSettingAdmin.phone_max_size
              : null,

          phone_min_size:
            data && data.oneSettingAdmin && data.oneSettingAdmin.phone_min_size
              ? data.oneSettingAdmin.phone_min_size
              : null,
          time_zone: "",
          sms_price:
            data && data.oneSettingAdmin && data.oneSettingAdmin.sms_price
              ? data.oneSettingAdmin.sms_price
              : null,
          regions:
            data && data.oneSettingAdmin && data.oneSettingAdmin.regions
              ? data.oneSettingAdmin.regions
              : [],
        }}
        validationSchema={updateSchema}
        onSubmit={update}
      >
        {(props) => (
          <ModalDialog
            open={editModal}
            cancel={async () => {
              await setEditModal(false)

              await props.handleReset()
            }}
            title={"Edition paramétrage"}
          >
            <SettingEdit
              {...props}
              canModify={canModify}
              item={data.oneSettingAdmin}
              globalError={globalEditError}
              time_zones={time_zones}
              errorRegion={errorRegion}
              setErrorRegion={setErrorRegion}
              region={region}
              setRegion={setRegion}
            />
          </ModalDialog>
        )}
      </Formik>

      <div className={classes.content}>
        <Setting
          canModify={canModify}
          selectForUpdate={selectForUpdate}
          item={data && data.oneSettingAdmin ? data.oneSettingAdmin : null}
        />
      </div>
    </div>
  )
}

export default SettingView
