export const TOKEN_NAME = "admin0000111SenRvdiarama"
export const USER_STORAGE = "adminhoochyketch"
export const CURRENCY = "cfa"
export const LOGIN_PATH = "/login"
export const COMPANY_ID_STORAGE = "compnay_id"
export const CONDITIONS_PATH = "/admin"
export const TEAM_PATH = "/admin/team"
export const COMPANY_PATH = "/admin/companies"
export const COMPANY_COMMERCIAL_PATH = "/admin/companies_commercial"
export const DASHBOARD_PATH = "/admin/dashboard"
export const SUBSCRIPTION_PATH = "/admin/subscription"
export const EVENT_PATH = "/admin/events"
export const SALER_PATH = "/admin/salers"
export const SERVICE_PATH = "/admin/services"
export const CATEGORY_PATH = "/admin/categories"
export const CUSTOMER_PATH = "/admin/customers"
export const SETTING_PATH = "/admin/settings"
export const SITE_SETTING_PATH = "/admin/site_settings"
export const SITE_SETTING_CREATE_PATH = "/admin/site_settings_create"
export const SITE_SETTING_EDIT_PATH = "/admin/site_settings_edit"
export const PROFILE_PATH = "/admin/profile"
export const USERS_PATH = "/admin/users"
export const BILLS_PATH = "/admin/bills"
export const NOTIF_PATH = "/admin/notifications"
export const COMMERCIAL_BILLS_PATH = "/admin/bills-commercial"

export const CLIENT_SIGNAL_COMMERCIAL_PATH = "/calendar/client_signals_commercial"
export const ONE_COMPANY_PATH = "/admin/one_company"
export const HISTORY_PATH = "/admin/histories"
export const FACTURE_PATH = "/admin/factures"
export const ONE_COMPANY_FACTURE_PATH = "/admin/company-facture"

export const ADMIN = "ADMIN"
export const SUPER_ADMIN = "SUPER_ADMIN"
export const MANAGER = "MANAGER"
export const WORKER = "WORKER"
export const COMMERCIAL = "SENRV_COMMERCIAL"

export const CONTACT_MESSAGE_PATH = "/admin/contact-messages"
export const JOB_PATH = "/admin/careers"
