import React, { useState } from "react"
import { Link as RouterLink } from "react-router-dom"
import clsx from "clsx"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/styles"
import { AppBar, Toolbar, Badge, Hidden, IconButton } from "@material-ui/core"
import MenuIcon from "@material-ui/icons/Menu"
import CloseIcon from '@material-ui/icons/Close';
import NotificationsIcon from "@material-ui/icons/NotificationsOutlined"
import InputIcon from "@material-ui/icons/Input"
import { useMutation } from "react-apollo"
import { LOGOUT_USER_MUTATION } from "graphql/mutation/user"
import { ConfirmDialog } from "components/ConfirmDialog"

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
}))

const Topbar = (props) => {
  const { className, onSidebarOpen, openSidebar,...rest } = props

  const classes = useStyles()

  const [notifications] = useState([])
  const [logoutModal, setLogoutModal] = useState(false)
  const [logout] = useMutation(LOGOUT_USER_MUTATION)


  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
      <IconButton color="inherit" onClick={onSidebarOpen}>
            {openSidebar ? <CloseIcon /> : <MenuIcon />}
            {/* <CloseIcon /> */}
      </IconButton> 
        {/* <RouterLink to="/">
          <img
            alt="Logo"
            src="https://www.chatelaillonplage.fr/var/storage/images/site-de-la-ville-v2/demarches/rdv-en-ligne-carte-d-identite-et-passeport/721110-1-fre-FR/RDV-EN-LIGNE-carte-d-identite-et-passeport_image_600x285.jpg"
            style={{ width: 25, height: 25 }}
          />
        </RouterLink> */}
        <div className={classes.flexGrow} />
        <Hidden mdDown>
          {/* <IconButton color="inherit">
            <Badge badgeContent={notifications.length} color="primary" variant="dot">
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
          <IconButton
            className={classes.signOutButton}
            color="inherit"
            onClick={async (e) => {
              e.preventDefault()
              await setLogoutModal(true)
            }}
          >
            <InputIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>

      <ConfirmDialog
        open={logoutModal}
        cancel={async () => {
          await setLogoutModal(false)
        }}
        confirmAction={async () => {
          await logout()
          await setLogoutModal(false)
        }}
        title={"Déconnexion"}
      >
        <p>Voulez vous vous déconnectez ?</p>
      </ConfirmDialog>
    </AppBar>
  )
}

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
}

export default Topbar
