import React from "react"
import { Button, TextField, Grid, Paper, Chip } from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import CurrencyTextField from "@unicef/material-ui-currency-textfield"
import Loading from "components/Loading"
import { MessageField } from "components/MessageField"
import { SelectField } from "components/SelectField"

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: "100%",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}))

export const SettingCreate = ({
  values,
  touched,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  globalError,
  time_zones,
  region,
  setRegion,
  errorRegion,
  setErrorRegion,
}) => {
  const classes = useStyles()
  const theme = useTheme()

  if (isSubmitting) {
    return <Loading />
  }
  return (
    <form
      className={classes.root}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      {globalError && <MessageField message={globalError} severity={"error"} />}

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            id="country"
            name="country"
            value={values.country}
            required
            label="Pays"
            onChange={handleChange}
            fullWidth
            helperText={touched.country && errors.country ? errors.country : null}
            error={touched.country && errors.country ? true : false}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <SelectField
            multiple={false}
            handleChange={handleChange}
            required
            id="time_zone"
            name="time_zone"
            label="Fuséau Horaire"
            value={values.time_zone}
            theme={theme}
            dataSource={time_zones}
            helperText={
              touched.time_zone && errors.time_zone ? errors.time_zone : null
            }
            error={touched.time_zone && errors.time_zone ? true : false}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="phone_code"
            name="phone_code"
            value={values.phone_code}
            required
            label={"Indicatif Téléphone (+221)"}
            onChange={handleChange}
            fullWidth
            helperText={
              touched.phone_code && errors.phone_code ? errors.phone_code : null
            }
            error={touched.phone_code && errors.phone_code ? true : false}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Délai minimal d'annulation rv"
            value={values.minimal_cancel}
            name="minimal_cancel"
            id="minimal_cancel"
            onChange={handleChange}
            type="number"
            helperText={
              touched.minimal_cancel && errors.minimal_cancel
                ? errors.minimal_cancel
                : null
            }
            error={touched.minimal_cancel && errors.minimal_cancel ? true : false}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            inputProps={{ min: "1", step: "1" }}
            label="Taille minimale Téléphone"
            value={values.phone_min_size}
            name="phone_min_size"
            id="phone_min_size"
            onChange={handleChange}
            type="number"
            helperText={
              touched.phone_min_size && errors.phone_min_size
                ? errors.phone_min_size
                : null
            }
            error={touched.phone_min_size && errors.phone_min_size ? true : false}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            inputProps={{ min: "1", step: "1" }}
            label="Taille maximale Téléphone"
            value={values.phone_max_size}
            name="phone_max_size"
            id="phone_max_size"
            onChange={handleChange}
            type="number"
            helperText={
              touched.phone_max_size && errors.phone_max_size
                ? errors.phone_max_size
                : null
            }
            error={touched.phone_max_size && errors.phone_max_size ? true : false}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="currency"
            name="currency"
            value={values.currency}
            required
            label={"Monnaie"}
            onChange={handleChange}
            fullWidth
            helperText={touched.currency && errors.currency ? errors.currency : null}
            error={touched.currency && errors.currency ? true : false}
          />
        </Grid>
        {values.currency && (
          <Grid item xs={12} sm={6}>
            <CurrencyTextField
              as={TextField}
              fullWidth
              value={values.sms_price}
              name="sms_price"
              id="sms_price"
              variant="standard"
              label={`Prix sms`}
              onChange={async (_event, value) =>
                await setFieldValue("sms_price", value)
              }
              helperText={
                touched.sms_price && errors.sms_price ? errors.sms_price : null
              }
              error={touched.sms_price && errors.sms_price ? true : false}
              currencySymbol={values.currency}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={12}>
          <Paper>
            {values.regions.map((data, key) => {
              return (
                <Chip
                  key={key}
                  label={data}
                  onDelete={async () => {
                    let regions = values.regions.filter((chip) => chip !== data)

                    await setFieldValue("regions", regions)
                  }}
                  className={classes.chip}
                />
              )
            })}
          </Paper>
        </Grid>
        <Grid item xs={6} sm={12} style={{ padding: 10 }}>
          <TextField
            required
            id="region"
            name="region"
            label="Ajouter une région"
            value={region}
            onChange={async (e) => {
              await setRegion(e.target.value)
            }}
            fullWidth
            helperText={
              touched.regions && errors.regions
                ? errors.regions
                : errorRegion
                ? errorRegion
                : null
            }
            error={(touched.regions && errors.regions) || errorRegion ? true : false}
          />
          <Button
            //  color="warning.dark"
            style={{ color: "white", backgroundColor: "orange", marginTop: 5 }}
            //disabled={!formState.isValid}
            fullWidth
            size="large"
            variant="contained"
            onClick={async (e) => {
              e.preventDefault()
              const regions = values.regions
              const data = region.trim()
              if (data.length > 0) {
                if (regions.indexOf(region) < 0) {
                  regions.push(data)
                  await setFieldValue("regions", regions)
                  await setRegion("")
                  await setErrorRegion("")
                } else {
                  await setErrorRegion("région déjà ajoutée")
                }
              } else {
                await setErrorRegion("champ requis")
              }
            }}
          >
            Ajouter une région
          </Button>
        </Grid>

        <Grid item xs>
          <Button
            color="primary"
            //disabled={!formState.isValid}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
          >
            Enregistrer
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}
