import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/styles"
import { TableCell, TableRow, Avatar } from "@material-ui/core"
import EditIcon from "@material-ui/icons/Edit"
import inconnu from "../../../assets/inconnu.jpg"
import { renderDate } from "utils/func"

const useStyles = makeStyles((theme) => ({
  statsIcon: {
    color: theme.palette.icon,
    marginRight: theme.spacing(1),
  },
}))

export const Service = (props) => {
  const { item, selectForUpdate, time_zone } = props

  const classes = useStyles()

  return (
    <TableRow key={item.id}>
      {item.picture && (
        <TableCell>
          <Avatar size="tiny" src={item.picture} alt={item.name} />
        </TableCell>
      )}

      <TableCell>{item.name}</TableCell>
      <TableCell>{item.price}</TableCell>
      <TableCell>{renderDate(item.createdAt, time_zone)}</TableCell>
      <TableCell style={{ color: item.is_active ? "green" : "red" }}>
        {item.is_active ? "actif" : "désactif"}
      </TableCell>

     

      <TableCell>
        <EditIcon
          className={classes.statsIcon}
          onClick={async (e) => {
            e.preventDefault()
            await selectForUpdate(item)
          }}
        />
      </TableCell>
    </TableRow>
  )
}
