import React from "react"
import { Grid, Button, TextField } from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Loading from "components/Loading"
import { MessageField } from "components/MessageField"
import CurrencyTextField from "@unicef/material-ui-currency-textfield/dist/CurrencyTextField"

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: "100%",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}))
export const SubscriptionCompCreate = ({
  values,
  touched,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  globalError,
  setFieldValue,
  currency
 
}) => {
  const theme = useTheme()
  const classes = useStyles()

  if (isSubmitting) {
    return <Loading />
  }

  return (
    <form
      className={classes.root}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      {globalError && <MessageField message={globalError} severity={"error"} />}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="name"
            name="name"
            label="Nom"
            onChange={handleChange}
            error={touched.name && errors.name ? true : false}
            fullWidth
            helperText={touched.name && errors.name ? errors.name : null}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            
            id="description"
            name="description"
            label="Description"
            onChange={handleChange}
            error={touched.description && errors.description ? true : false}
            multiline
            fullWidth
            helperText={touched.description && errors.description ? errors.description : null}
          />
         
        </Grid>
         
        <Grid item xs={12} sm={6}>
          <CurrencyTextField
            as={TextField}
            fullWidth
            value={values.price}
            name="price"
            id="price"
            variant="standard"
           // disabled={!values.show_price}
            label={`Prix en ${currency}`}
            onChange={async (_event, value) => await setFieldValue("price", value)}
            helperText={touched.price && errors.price ? errors.price : null}
            error={touched.price && errors.price ? true : false}
            currencySymbol={currency}
          />
        </Grid>
         
        
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            inputProps={{ min: "0", step: "1" }}
            label="Nombre Personnel"
            value={values.workers_number}
            name="workers_number"
            id="workers_number"
            onChange={handleChange}
            type="number"
            helperText={
              touched.workers_number && errors.workers_number
                ? errors.workers_number
                : null
            }
            error={touched.workers_number && errors.workers_number ? true : false}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            inputProps={{ min: "0", step: "1" }}
            label="Nombre de Services"
            value={values.services_number}
            name="services_number"
            id="services_number"
            onChange={handleChange}
            type="number"
            helperText={
              touched.services_number && errors.services_number
                ? errors.services_number
                : null
            }
            error={touched.services_number && errors.services_number ? true : false}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Button
            color="primary"
            //disabled={!formState.isValid}
            fullWidth
            size="large"
            type="submit"
            onClick={handleSubmit}
            variant="contained"
          >
            enregistrer
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}
