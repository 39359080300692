import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/styles"
import { TableCell, TableRow, Avatar } from "@material-ui/core"
import EditIcon from "@material-ui/icons/Edit"
import inconnu from "../../../assets/inconnu.jpg"
import { renderDate } from "utils/func"
 

const useStyles = makeStyles((theme) => ({
  statsIcon: {
    color: theme.palette.icon,
    marginRight: theme.spacing(1),
    cursor:'pointer'
  },
}))

export const Team = (props) => {
  const { item, selectForUpdate,time_zone } = props;
 
  const classes = useStyles()
  const gender = item.gender == "male" ? "Monsieur" : "Madame"
  return (
    <TableRow key={item.id}>
      {item.picture && (
        <TableCell>
          <Avatar size="tiny" src={item.picture} alt={item.fullname} />
        </TableCell>
      )}

      {!item.picture && (
        <TableCell>
          <Avatar size="tiny" src={inconnu} alt={item.fullname} />
        </TableCell>
      )}
      <TableCell>
        {gender + " "}
        {item.fullname}
      </TableCell>
      <TableCell>{item.phone}</TableCell>

      <TableCell>
        {item.roles.filter(r=>r.is_from_senrv).map((r) => (
          <span key={r.id}>{r.name + " "}</span>
        ))}
      </TableCell>

      <TableCell>{item.email}</TableCell>
      {item.is_active && <TableCell style={{ color: "green" }}>actif</TableCell>}
      {!item.is_active && <TableCell style={{ color: "red" }}>inactif</TableCell>}
      {item.lat && item.lng && (
        <TableCell>
          <a
            target="_blank"
            href={`https://www.google.com/maps/?q=${item.lat},${item.lng}`}
          >
            Géolocalisé
          </a>
        </TableCell>
      )}

      {/*   <TableCell>
        <RemoveIcon
          className={classes.statsIcon}
          onClick={async (e) => {
            e.preventDefault()
            await selectForRemove(item)
          }}
        />
      </TableCell> */}
      <TableCell>{renderDate(item.createdAt, time_zone)}</TableCell>

      <TableCell>
        <EditIcon
          className={classes.statsIcon}
          onClick={async (e) => {
            e.preventDefault()
            await selectForUpdate(item)
          }}
        />
      </TableCell>
    </TableRow>
  )
}

Team.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object.isRequired,
}
