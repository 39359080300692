import React from "react"
import { Grid } from "@material-ui/core"
import Pagination from "@material-ui/lab/Pagination"
import { Category } from "./Category"

export const CategoryList = ({
  data,
  selectCategory,
  selectCatToDelete,
  onPageChange,
  time_zone,
  canAdd,
}) => {

if(!data){
  return null
}
  const { docs, page, totalPages }=data
  return (
    <Grid>
      <div style={{ margin: '25px 0px' }}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={async (event, value) => {
            if (value.toString() !== page.toString()) {
              await onPageChange(value)
            }
          }}
        />
      </div>
      <Grid container spacing={3} style={{padding:'0px 16px'}}>
        {docs.map((cat) => (
          <Grid item key={cat.id} lg={4} md={6} xs={12}>
            <Category
              key={cat.id}
              time_zone={time_zone}
              category={cat}
              canAdd={canAdd}
              selectCategory={selectCategory}
              selectCatToDelete={selectCatToDelete}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}
