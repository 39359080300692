import React from "react"
import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Pagination from "@material-ui/lab/Pagination"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"

import { Team } from "./Team"

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
})

export const TeamList = ({
  docs,
  page,
  totalPages,
  selectForUpdate,
  selectForRemove,
  onPageChange,
  time_zone
}) => {
  const classes = useStyles()

  if (!docs || docs.length == 0) {
    return null
  }
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={async (_event, value) => {
            if (value.toString() !== page.toString()) {
              await onPageChange(value)
            }
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Nom</TableCell>
                <TableCell>Téléphone</TableCell>
                <TableCell>Roles</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Etat compte</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Modifier</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {docs.map((row) => (
                <Team
                time_zone={time_zone}
                  key={row.id}
                  item={row}
                  selectForRemove={selectForRemove}
                  selectForUpdate={selectForUpdate}
                  
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}
