import React from "react"
import { Grid, Button, TextField } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Loading from "components/Loading"
import { MessageField } from "components/MessageField"
import { SelectField } from "components/SelectField"

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: "100%",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}))
export const ProfileEdit = ({
  values,
  touched,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  item,
  globalError,
  setFieldValue,
}) => {
  const classes = useStyles()

  if (!item || !item.id) {
    return null
  }
  if (isSubmitting) {
    return <Loading />
  }

  return (
    <form
      className={classes.root}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      {globalError && <MessageField message={globalError} severity={"error"} />}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <SelectField
            required={true}
            multiple={false}
            handleChange={async ({ target: { value } }) => {
              try {
                await setFieldValue("gender", value)
              } catch (error) {}
            }}
            name="gender"
            label={"civilité"}
            value={values.gender}
            // theme={theme}
            dataSource={["Monsieur", "Madame"]}
            helperText={touched.gender && errors.gender ? errors.gender : null}
            error={touched.gender && errors.gender ? true : false}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="name"
            name="name"
            label="Nom"
            value={values.name}
            onChange={handleChange}
            error={touched.name && errors.name ? true : false}
            fullWidth
            helperText={touched.name && errors.name ? errors.name : null}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            id="lastname"
            name="lastname"
            label="Prénom"
            value={values.lastname}
            onChange={handleChange}
            error={touched.name && errors.lastname ? true : false}
            fullWidth
            helperText={touched.lastname && errors.lastname ? errors.lastname : null}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            id="email"
            name="email"
            label="Email"
            value={values.email}
            onChange={handleChange}
            error={touched.email && errors.email ? true : false}
            fullWidth
            helperText={touched.email && errors.email ? errors.email : null}
          />
        </Grid>

        <div className="controle-btn-bottom">
          <Button
            className="buttonLeft"
            color="primary"
            //disabled={!formState.isValid}
           
            type="submit"
            onClick={handleSubmit}
            variant="contained"
          >
            enregistrer
          </Button>
        </div>
      </Grid>
    </form>
  )
}
