import gql from "graphql-tag"

export const findJobsQuery = gql`
  query findJobs($page: Int!, $limit: Int, $region: String) {
    findJobs(page: $page, limit: $limit, region: $region) {
      totalDocs
      hasPrevPage
      totalPages
      limit
      offset
      page
      prevPage
      nextPage
      docs {
        id
        title
        description

        createdAt
        updatedAt
        region
        author {
          id
          gender
          name
          lastname
          phone
        }
      }
    }
  }
`
