import React from "react"
import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Pagination from "@material-ui/lab/Pagination"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"

import { Bill } from "./Bill"

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
})

export const BillList = ({
  docs,
  page,
  totalPages,
  currency,
  onPageChange,
  time_zone,
}) => {
  const classes = useStyles()

  if (!docs || docs.length == 0) {
    return null
  }
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={async (_event, value) => {
            if (value.toString() !== page.toString()) {
              await onPageChange(value)
            }
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Entreprise</TableCell>
                <TableCell>{`Total en ${currency}`}</TableCell>
                <TableCell>Etat facture</TableCell>
                <TableCell>Contact</TableCell>

                <TableCell>Abonnement</TableCell>
                <TableCell>sms</TableCell>
                <TableCell>Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {docs.map((row) => (
                <Bill
                  key={row.id}
                  item={row}
                  time_zone={time_zone}

                  /* selectForRemove={selectForRemove}
                  selectForUpdate={selectForUpdate} */
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}
