import React, { useState } from "react"
import moment from "moment-timezone"
import { makeStyles } from "@material-ui/styles"
import { Accordion } from "components/Accordion"
import { useMutation, useQuery } from "@apollo/react-hooks"
import { totalMoneyQuery, findCompBillsQuery } from "graphql/query/comp_bill"
import { generateBillsMutation } from "graphql/mutation/comp_bill"
import { Grid, Button } from "@material-ui/core"
import { oneSettingAdminQuery } from "graphql/query/setting_admin"
import { BillList } from "./components/BillList"
import { TextField } from "@material-ui/core"
import Loading from "components/Loading"

const useStyles = makeStyles((theme) => ({
  root: {
    padding:"9px 25px"
  },
  // content: {
  //   padding:"9px 25px"
  // },
  pagination: {
    marginTop: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
}))

const BillView = () => {
  const classes = useStyles()
  const [expanded1, setExpanded1] = useState(true)
  const [expanded2, setExpanded2] = useState(true)
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const from = moment().clone().startOf("month")
  const to = moment().clone().endOf("month")
  const from_date = from.format("YYYY-MM-DD")
  const to_date = to.format("YYYY-MM-DD")

  const [genBills] = useMutation(generateBillsMutation)
  const {
    loading: loading1,
    data: totalManyData,
    refetch: refetchMoney,
  } = useQuery(totalMoneyQuery, {
    variables: { from_date, to_date },
  })
  const {
    loading: loading3,
    data: compBillData,
    fetchMore,
    refetch,
  } = useQuery(findCompBillsQuery, {
    page,
  })
  const { loading: loading2, data: generalSetting } = useQuery(oneSettingAdminQuery)

  const timezone =
    generalSetting &&
    generalSetting.oneSettingAdmin &&
    generalSetting.oneSettingAdmin.time_zone
      ? generalSetting.oneSettingAdmin.time_zone
      : "Africa/Dakar"

  const currency =
    generalSetting &&
    generalSetting.oneSettingAdmin &&
    generalSetting.oneSettingAdmin.currency
      ? generalSetting.oneSettingAdmin.currency
      : "Africa/Dakar"
  const generateBill = async () => {
    try {
      await setLoading(true)
      const res = await genBills({ variables: { from_date, to_date } })
      const { ok, errors } = res.data.generateBills
      await setLoading(false)
      if (ok) {
        await setLoading(false)

        await setPage(1)
        await refetch({ variables: { page: 1, from_date, to_date } })
        await refetchMoney({
          variables: {
            from_date,
            to_date,
          },
        })
      } else {
        await setLoading(false)

        window.alert(errors[0].message)
      }
    } catch (error) {
      await setLoading(false)
      window.alert(error.message)
    }
  }
  const onPageChange = async (page) => {
    try {
      await setPage(page)
      await fetchMore({
        variables: { page, from_date, to_date },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev

          const { findCompBills } = fetchMoreResult
          return Object.assign({}, prev, {
            findCompBills,
          })
        },
      })

      //    await setLoading(false)
    } catch (error) {
      throw error
    }
  }

   

  if (loading || loading1 || loading2 || loading3) {
    return <Loading />
  }
  return (
    <div className={classes.root}>
      <Accordion
        id={"search-client-bill-total"}
        title={"Filtrer "}
        expanded={expanded1}
        setExpanded={setExpanded1}
      >
        <Grid
          container
          spacing={0}
         
          style={{ padding: '0px 20px' }}
        >
          <Grid container spacing={3} style={{ width: "100%", marginBottom: 20 }}>
            <Grid item xs={12} sm={12}>
              <TextField
                id="total"
                name="name"
                label={`Revenu total  du   ${from.format("ll")}  au   ${to.format(
                  "ll"
                )}`}
                fullWidth
                value={
                  totalManyData &&
                  totalManyData.totalMoney &&
                  totalManyData.totalMoney.total
                    ? totalManyData.totalMoney.total + " " + currency
                    : "0" + " " + currency
                }
              />
            </Grid>
          </Grid>
          <Button
            color="primary"
            variant="contained"
            onClick={generateBill}
            style={{ float: "right" }}
          >
            Générer facture
          </Button>
        </Grid>
      </Accordion>
      <Accordion
        id={"search-client-bill-list"}
        title={`Liste des factures du   ${from.format("ll")}  au   ${to.format(
          "ll"
        )}`}
        expanded={expanded2}
        setExpanded={setExpanded2}
      >
        <BillList
          time_zone={timezone}
          currency={currency}
          data={
            compBillData && compBillData.findCompBills
              ? compBillData.findCompBills
              : null
          }
          docs={
            compBillData &&
            compBillData.findCompBills &&
            compBillData.findCompBills.docs
              ? compBillData.findCompBills.docs
              : null
          }
          page={page}
          totalPages={
            compBillData &&
            compBillData.findCompBills &&
            compBillData.findCompBills.totalPages
              ? compBillData.findCompBills.totalPages
              : null
          }
          onPageChange={onPageChange}
        />
      </Accordion>
    </div>
  )
}

export default BillView
