import gql from "graphql-tag"

export const findContactsQuery = gql`
  query findContacts($page: Int!, $limit: Int) {
    findContacts(page: $page, limit: $limit) {
      totalDocs
      hasPrevPage
      totalPages
      limit
      offset
      page
      prevPage
      nextPage
      docs {
        id
        createdAt
        updatedAt
        description
        author {
          id
          phone
          name
          lastname
          gender
          phone
        }
        name
        lastname

        phone
        email
      }
    }
  }
`
