import gql from "graphql-tag"

export const deleteSignalMutation = gql`
  mutation deleteSignal($id: String!) {
    deleteSignal(id: $id) {
      ok
      id
      errors {
        path
        message
      }
    }
  }
`
