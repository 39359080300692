import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import { useMutation } from "react-apollo";

import { loginMutation } from "../../graphql/mutation/user";
import { LOGIN_USER_MUTATION } from "../../graphql/mutation/user";
import Login from "./components/Login";

const loginSchema = Yup.object().shape({
  password: Yup.string()
    .trim()
    .min(5, "minimun 5 caractères")
    .max(50, "maximum 50 caractères")
    .required("champ requis"),
  phone: Yup.string()
    .trim()
    .min(13, "Téléphone incorrect")
    .max(13, "Téléphone incorrect")
    .required("champ requis")
});

const SignIn = props => {
  const [login] = useMutation(loginMutation);
  const [setUser] = useMutation(LOGIN_USER_MUTATION);

  return (
    <Formik
      initialValues={{
        phone: "",
        password: ""
      }}
      validationSchema={loginSchema}
      onSubmit={async (
        values,

        { setSubmitting, setFieldError }
      ) => {
        try {
          const res = await login({
            variables: {
              ...values,
              phone: values.phone.trim()
            }
          });

          const { ok, errors, token } = res.data.loginAdmin;

          if (ok) {
            const currentUser = {
              authenticated: true,
              token
            };
            await setUser({
              variables: {
                currentUser
              }
            });
            // await history.push(CONDITIONS_PATH);
          } else {
            errors.forEach(async error => {
              await setFieldError(error.path, error.message);
              await setSubmitting(false);
            });
          }
        } catch (error) {
          throw error;
        }
      }}
    >
      {props => <Login {...props} />}
    </Formik>
  );
};

SignIn.propTypes = {
  history: PropTypes.object
};

export default withRouter(SignIn);
