import React, { Fragment, useState } from "react"
import * as Yup from "yup"
import { Formik } from "formik"
import { oneCompanyQuery } from "graphql/query/company"
import { makeStyles } from "@material-ui/styles"
import { Typography, Grid, Button, Box } from "@material-ui/core"
import EditIcon from "@material-ui/icons/Edit"
import InfoIcon from "@material-ui/icons/Info"
import LocationCityIcon from "@material-ui/icons/LocationCity"
import { useMutation, useQuery } from "@apollo/react-hooks"
import { Accordion } from "components/Accordion"
import Loading from "components/Loading"
import { updateCompanyMutation } from "graphql/mutation/company"
import { ModalDialog } from "components/ModalDialog"
import { findSubscriptionsQuery } from "graphql/query/subscription"
import { CompanyEdit } from "./components/CompanyEdit"
import { categoriesQuery } from "graphql/query/category"
import { senrvUsersQuery, currentUserQuery } from "graphql/query/user"
import { oneSettingAdminQuery } from "graphql/query/setting_admin"
import { renderDate } from "utils/func"
import { toast } from "react-toastify"
//import "/../../assets/scss/index.scss"

const updateSchema = Yup.object().shape({
  subscription_price: Yup.number().nullable(),
  rv_price: Yup.number().nullable(),
  subscription_services_number: Yup.number().nullable(),
  subscription_workers_number: Yup.number().nullable(),
  region: Yup.string().trim().nullable(),
  is_active: Yup.boolean().default(false).typeError("champ requis"),
  owner_phone: Yup.string().trim().nullable(),
  category_ids: Yup.array().of(Yup.string()).nullable(),
  senrv_manager_ids: Yup.array().of(Yup.string()).nullable(),
})
const useStyles = makeStyles((theme) => ({
  root: {},
  imageContainer: {
    height: 64,
    width: 64,
    margin: "0 auto",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "5px",
    overflow: "hidden",
    display: "flex",
  },
  image: {
    width: "100%",
  },
  statsItem: {
    display: "flex",
  },
  imgCompany: {
    marginBottom: 20,
    width: "100%",
    maxWidth: "100%",
    height: 300,
  },

  iconminiContentHeader: {
    fontSize: 35,
    position: "relative",
    top: "8px",
  },
}))

export default function CompanyView({ company_id, time_zone }) {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [globalEditError, setGlobalEditError] = useState("")
  const [updateModal, setUpdateModal] = useState(false)
  const [expanded1, setExpanded1] = useState(true)
  const [updateComp] = useMutation(updateCompanyMutation)
  const {
    loading: loading1,
    data: compData,
    refetch,
  } = useQuery(oneCompanyQuery, {
    skip: company_id ? false : true,
    variables: { company_id },
  })
  const { loading: loading2, data: subsData } = useQuery(findSubscriptionsQuery)
  const { loading: loading3, data: cats } = useQuery(categoriesQuery)
  const { data: settingAdminData } = useQuery(oneSettingAdminQuery)
  const { loading: loading4, data: senrvUsersData } = useQuery(senrvUsersQuery)
  const { loading: loading5, data: userData } = useQuery(currentUserQuery, {
    fetchPolicy: "network-only",
  })

  const roles =
    userData && userData.onlineUser && userData.onlineUser.roles
      ? userData.onlineUser.roles
      : []
  const item =
    compData && compData.oneCompanyAdmin && compData.oneCompanyAdmin.id
      ? compData.oneCompanyAdmin
      : null
  const regions =
    settingAdminData &&
    settingAdminData.oneSettingAdmin &&
    settingAdminData.oneSettingAdmin.regions
      ? settingAdminData.oneSettingAdmin.regions
      : null

  const phone_code =
    settingAdminData &&
    settingAdminData.oneSettingAdmin &&
    settingAdminData.oneSettingAdmin.phone_code
      ? settingAdminData.oneSettingAdmin.phone_code
      : null

  const currency =
    settingAdminData &&
    settingAdminData.oneSettingAdmin &&
    settingAdminData.oneSettingAdmin.currency
      ? settingAdminData.oneSettingAdmin.currency
      : "cfa"

  const phone_max_size =
    settingAdminData &&
    settingAdminData.oneSettingAdmin &&
    settingAdminData.oneSettingAdmin.phone_max_size
      ? settingAdminData.oneSettingAdmin.phone_max_size
      : null

  const phone_min_size =
    settingAdminData &&
    settingAdminData.oneSettingAdmin &&
    settingAdminData.oneSettingAdmin.phone_min_size
      ? settingAdminData.oneSettingAdmin.phone_min_size
      : null
  const update = async (
    values,

    { setSubmitting }
  ) => {
    try {
     //  console.log('item subscription',item.subscription)
      const {
        subscription_price,
        rv_price,
        subscription_services_number,
        subscription_workers_number,
        category_ids,
        senrv_manager_ids,
        owner_phone,
        region,
        is_active,
      } = values
      let variables = { id: company_id }
      if (owner_phone) {
        const phoneLength = parseInt(owner_phone.trim().length)

        if (
          !owner_phone.startsWith(phone_code) ||
          phoneLength < phone_min_size ||
          phoneLength > phone_max_size
        ) {
          /*  await setFieldError(
        'phone',
        `Le téléphone doit commencer par ${phone_code} ,taille minimale de ${phone_min_size} et maximale de ${phone_max_size}`,
      ); */
          window.alert(
            `Le téléphone doit commencer par ${phone_code} ,taille minimale de ${phone_min_size} et maximale de ${phone_max_size}`
          )
          return
        }
      }
      if (window.confirm("Etes vous sûr(e) de vouloir continuer cette action")) {
       
        const categories = []
        category_ids.forEach((el) => {
          cats.categories.forEach((cat) => {
            if (el.toString() === cat.name.toString()) {
              categories.push(cat.id)
            }
          })
        })
        if (category_ids.length > 0) {
          const oldCats = item.categories.map((c) => c.id)
          const diff = oldCats
            .filter((x) => categories.indexOf(x) < 0)
            .concat(categories.filter((x) => oldCats.indexOf(x) < 0))

          if (diff.length > 0) {
            variables.category_ids = categories
          }
        }
        const managers = []

        if (senrvUsersData && senrvUsersData.senrvUsers) {
          senrv_manager_ids.forEach((el) => {
            const splitted = el.split("->")
            const phone = splitted[1]
            senrvUsersData.senrvUsers.forEach((u) => {
              if (phone == u.phone) {
                managers.push(u.id)
              }
            })
          })
        }

        if (managers.length > 0) {
          const old = item.senrvmanagers.map((c) => c.id)
          const diff = old
            .filter((x) => managers.indexOf(x) < 0)
            .concat(managers.filter((x) => old.indexOf(x) < 0))

          if (diff.length > 0) {
            variables.senrv_manager_ids = managers
          }
        }
        //senrv_manager_ids
        if (
         /*  subscription_price &&
          item.subscription &&
          item.subscription.price && */
          parseFloat(item.subscription.price) != parseFloat(subscription_price)
        ) {
          variables.subscription_price = parseFloat(subscription_price)
        }

        if (
         /*  rv_price &&
          item.subscription &&
          item.subscription.rv_price && */
          parseFloat(item.subscription.rv_price) != parseFloat(rv_price)
        ) {
          variables.rv_price = parseFloat(rv_price)
        }
 
        if (
         /*  subscription_services_number &&
          item.subscription &&
          item.subscription.services_number && */
          parseInt(item.subscription.services_number) !=
            parseInt(subscription_services_number)
        ) {
           
          variables.subscription_services_number = subscription_services_number
        }

        if (
         /*  subscription_workers_number &&
          item.subscription &&
          item.subscription.workers_number && */
          parseInt(item.subscription.workers_number) !=
            parseInt(subscription_workers_number)
        ) {
          variables.subscription_workers_number = subscription_workers_number
        }

        if (region && item.region != region) {
          variables.region = region
        }

        if (is_active != undefined && is_active != item.is_active) {
          variables.is_active = is_active
        }

        if (owner_phone) {
          variables.owner_phone = owner_phone.trim()
        }
      
        if (Object.keys(variables).length < 2) {
          return
        }

        console.log("variables", variables)
        const res = await updateComp({ variables })

        const { ok, errors } = res.data.updateCompanyAdmin

        if (ok) {
          await setSubmitting(false)
          await setUpdateModal(false)
          await refetch({ variables: { company_id } })
          await toast.success("Modifications enregistrées")
        } else {
          await setSubmitting(false)
          window.alert(errors[0].message)
        }
      }
    } catch (error) {
      console.log("error",error)
      window.alert(error.message)
    }
  }
  
  const initialValues = {
    subscription_price:
      item && item.subscription && item.subscription.price
        ? item.subscription.price
        : null,

    rv_price:
      item && item.subscription && item.subscription.rv_price
        ? item.subscription.rv_price
        : 0,
    owner_phone: "",
    subscription_services_number:
      item && item.subscription && item.subscription.services_number
        ? item.subscription.services_number
        : 0,
    subscription_workers_number:
      item && item.subscription && item.subscription.workers_number
        ? item.subscription.workers_number
        : 0,
    category_ids:
      item && item.categories ? item.categories.map((cat) => cat.name) : [],
    senrv_manager_ids:
      item && item.senrvmanagers
        ? item.senrvmanagers.map((user) => {
            const gender = user.gender == "male" ? "Monsieur" : "Madame"
            return `${gender} ${user.name} ${user.lastname}->${user.phone}`
          })
        : [],
    region: item && item.region ? item.region : "",

    is_active: item && item.is_active,
  }
  return (
    <Accordion
      id={"search-client-notices-company"}
      title={"Entreprise"}
      expanded={expanded1}
      setExpanded={setExpanded1}
    >
      {item && (
        <h1
          style={{
            backgroundColor: item.is_active ? "green" : "red",
            textAlign: "center",
            color: "white",
          }}
        >
          {item.is_active ? "Compte actif" : "Compte désactivé"}
        </h1>
      )}
      {updateModal && (
        <Formik
          initialValues={initialValues}
          validationSchema={updateSchema}
          onSubmit={update}
        >
          {(props) => (
            <ModalDialog
              open={updateModal}
              cancel={async () => {
                await setUpdateModal(false)

                await props.handleReset()
              }}
              title={"Edition Entreprise"}
            >
              <CompanyEdit
                phone_code={phone_code}
                canUpdateManager={
                  roles.findIndex((r) => r.is_admin || r.is_super_admin) > -1
                }
                {...props}
                item={item}
                senrvusers={
                  senrvUsersData && senrvUsersData.senrvUsers
                    ? senrvUsersData.senrvUsers.map((user) => {
                        const gender = user.gender == "male" ? "Monsieur" : "Madame"
                        return `${gender} ${user.name} ${user.lastname}->${user.phone}`
                      })
                    : []
                }
                currency={currency}
                globalError={globalEditError}
                categories={
                  cats && cats.categories
                    ? cats.categories.map((cat) => cat.name)
                    : []
                }
                regions={regions ? regions : []}
                subscriptions={
                  subsData && subsData.findSubscriptions
                    ? subsData.findSubscriptions.map((e) => e.name)
                    : []
                }
              />
            </ModalDialog>
          )}
        </Formik>
      )}
      {(loading1 || loading4 || loading5 || loading3 || loading2) && <Loading />}
      {!loading1 && !loading4 && !loading5 && !loading3 && !loading2 && item && (
        <Fragment>
          <Grid xs={12}>
            {item.picture && (
              <img
                className={classes.imgCompany}
                src={item.picture}
                alt={item.name}
              />
            )}
          </Grid>
          <Box padding={3}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Box className={"miniContent"}>
                  <div className={"miniContentHeader"}>
                    <Typography variant="h3">
                      <InfoIcon className={classes.iconminiContentHeader} />
                      Infos Générales
                    </Typography>
                  </div>
                  <Box className={"miniContentMain"}>
                    <Typography style={{ flex: 2 }} variant="body2">
                      Nom Entreprise
                    </Typography>
                    <p className={"infoText"}>{item.name ? item.name : ""}</p>
                  </Box>

                  <Box className={"miniContentMain"}>
                    <Typography style={{ flex: 2 }} variant="body2">
                      Propriétaire
                    </Typography>
                    <p className={"infoText"}>
                      {item.owner.gender == "male" ? "Monsieur" : "Madame"}{" "}
                      {item.owner.name +
                        " " +
                        item.owner.lastname +
                        " " +
                        item.owner.phone}
                    </p>
                  </Box>

                  {item.created_by_senrv && item.author && (
                    <Box className={"miniContentMain"}>
                      <Typography style={{ flex: 2 }} variant="body2">
                        Créateur de l'entreprise
                      </Typography>
                      <p className={"infoText"}>
                        {item.author.gender == "male" ? "Monsieur" : "Madame"}{" "}
                        {item.author.name +
                          " " +
                          item.author.lastname +
                          " " +
                          item.author.phone +
                          "\n"}
                      </p>
                    </Box>
                  )}

                  <Box className={"miniContentMain"}>
                    <Typography style={{ flex: 2 }} variant="body2">
                      Date de création
                    </Typography>
                    <p className={"infoText"}>
                      {renderDate(item.createdAt, time_zone)}
                    </p>
                  </Box>

                  <Box className={"miniContentMain"}>
                    <Typography style={{ flex: 2 }} variant="body2">
                      Reference
                    </Typography>
                    <p className={"infoText"}>
                      {item.reference ? item.reference : ""}
                    </p>
                  </Box>
                  <Box className={"miniContentMain"}>
                    <Typography style={{ flex: 2 }} variant="body2">
                      Catégories
                    </Typography>
                    <p className={"infoText"}>
                      {item.categories && item.categories.length > 0
                        ? item.categories.map((el) => el.name + " ")
                        : ""}
                    </p>
                  </Box>
                  <Box style={{ paddingTop: 30 }}>
                    <Typography style={{ flex: 2 }} variant="body2">
                      Description
                    </Typography>
                    <Typography style={{ paddingTop: 10 }}>
                      {item.description && item.description}
                      {!item.description && "Aucune description"}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box className={"miniContent"}>
                  <div className={"miniContentHeader"}>
                    <Typography variant="h3">
                      <LocationCityIcon className={classes.iconminiContentHeader} />{" "}
                      Adresse
                    </Typography>
                  </div>
                  <Box className={"miniContentMain"}>
                    <Typography style={{ flex: 2 }} variant="body2">
                      Région
                    </Typography>
                    <p className={"infoText"}>
                      {item.region && item.region}
                      {!item.region && "Aucune Region"}
                    </p>
                  </Box>
                  <Box className={"miniContentMain"}>
                    <Typography style={{ flex: 2 }} variant="body2">
                      Ville/Quartier
                    </Typography>
                    <p className={"infoText"}>
                      {item.city && item.city}
                      {!item.city && "Aucune"}
                    </p>
                  </Box>
                  <Box className={"miniContentMain"}>
                    <Typography style={{ flex: 2 }} variant="body2">
                      Complément adresse
                    </Typography>
                    <p className={"infoText"}>
                      {item.description_address && item.description_address}
                      {!item.description_address && "Aucun complément"}
                    </p>
                  </Box>

                  <Box className={"miniContentMain"}>
                    <Typography style={{ flex: 2 }} variant="body2">
                      Latitude
                    </Typography>
                    <p className={"infoText"}>
                      {item.lat && item.lat}
                      {!item.lat && "aucune géolocalisation"}
                    </p>
                  </Box>
                  <Box className="miniContentMain miniContentMainLast">
                    <Typography style={{ flex: 2 }} variant="body2">
                      Longitude
                    </Typography>
                    <p className={"infoText"}>
                      {item.lng && item.lng}
                      {!item.lng && "aucune géolocalisation"}
                    </p>
                  </Box>

                  {item.lng && item.lat && (
                    <Box className="miniContentMain miniContentMainLast">
                      <Typography style={{ flex: 2 }} variant="body2">
                        <a
                          target="_blank"
                          href={`https://www.google.com/maps/?q=${item.lat},${item.lng}`}
                        >
                          position sur google map
                        </a>
                      </Typography>
                    </Box>
                  )}
                </Box>

                <Grid className={"contentBtn"} item xs={12} sm={12}>
                  <Button
                    onClick={async (e) => {
                      e.preventDefault()
                      await setUpdateModal(true)
                    }}
                    size=""
                    variant="contained"
                    color="primary"
                    className={"buttonUpdate"}
                    startIcon={<EditIcon className={"statsIcon"} />}
                  >
                    Modifier
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Fragment>
      )}
    </Accordion>
  )
}
