import React from "react"
import { Switch, Redirect } from "react-router-dom"
import { RouteWithLayout } from "./components"
import { Main as MainLayout, Minimal as MinimalLayout } from "./layouts"
import {
  //Dashboard as DashboardView,
  // UserList as UserListView,
  // Typography as TypographyView,
  // Icons as IconsView,
  // Account as AccountView,
  // Setting as SettingView,
  // Profile as ProfileView,
  SignIn as SignInView,
  NotFound as NotFoundView,
} from "./views"
import CategoryView from "./views/Category/CategoryView"
import CompanyView from "./views/Company/CompanyView"
import SettingView from "./views/Setting/SettingView"
import ProfileView from "./views/Profile/ProfileView"
import UserView from "views/User/UserView"
import SiteSettingView from "./views/SiteSetting/SettingView"
//import SettingCreateView from "./views/SiteSetting/SettingCreateView"
import SettingEditView from "./views/SiteSetting/SettingEditView"
import {
  CATEGORY_PATH,
  COMPANY_PATH,
  TEAM_PATH,
  LOGIN_PATH,
  SETTING_PATH,
  PROFILE_PATH,
  USERS_PATH,
  SUBSCRIPTION_PATH,
  SITE_SETTING_EDIT_PATH,
  BILLS_PATH,
  ONE_COMPANY_PATH,
  HISTORY_PATH,
  FACTURE_PATH,
  ONE_COMPANY_FACTURE_PATH,
  EVENT_PATH,
  JOB_PATH,
  CONTACT_MESSAGE_PATH,
  COMPANY_COMMERCIAL_PATH,
  COMMERCIAL_BILLS_PATH,
  NOTIF_PATH,
  CLIENT_SIGNAL_COMMERCIAL_PATH,
} from "utils/static_constants"
import UnAuthRoute from "components/UnAuthRoute"
import SubscriptionCompView from "views/SubscriptionComp/SubscriptionCompView"
import TeamView from "views/Team/TeamView"
import BillView from "views/Bill/BillView"
import OneCompanyView from "views/OneCompany/OneCompanyView"
import SenrvHistoryView from "views/SenrvHistory/SenrvHistoryView"
import FactureView from "views/Facture/FactureView"
import OneFactureView from "views/OneFacture/OneFactureView"
import EventView from "views/Event/EventView"
import JobaddView from "views/Jobadd/JobaddView"
import ContactMessageView from "views/ContactMessage/ContactMessageView"
import CompanyCommercialView from "views/CompanyCommercial/CompanyCommercialView"
import FactureCommercialView from "views/FactureCommercial/FactureCommercialView"
import NotifView from "views/Notif/NotifView"
import ClientSignalCommercialView from "views/ClientSignalCommercial/ClientSignalCommercialView"

const Routes = () => {
  return (
    <Switch>
      {/*   <Redirect exact from="/" to={DASHBOARD_PATH} />
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path={DASHBOARD_PATH}
      /> */}

      <RouteWithLayout
        component={SubscriptionCompView}
        exact
        layout={MainLayout}
        path={SUBSCRIPTION_PATH}
      />

      <RouteWithLayout
        component={ProfileView}
        exact
        layout={MainLayout}
        path={PROFILE_PATH}
      />

      {/*  <RouteWithLayout
        component={SiteSettingView}
        exact
        layout={MainLayout}
        path={SITE_SETTING_PATH}
      /> */}

      <RouteWithLayout
        component={NotifView}
        exact
        layout={MainLayout}
        path={NOTIF_PATH}
      />

      <RouteWithLayout
        component={ClientSignalCommercialView}
        exact
        layout={MainLayout}
        path={CLIENT_SIGNAL_COMMERCIAL_PATH}
      />
      <RouteWithLayout
        component={FactureCommercialView}
        exact
        layout={MainLayout}
        path={COMMERCIAL_BILLS_PATH}
      />
      <RouteWithLayout
        component={SettingView}
        exact
        layout={MainLayout}
        path={SETTING_PATH}
      />
      <RouteWithLayout
        component={TeamView}
        exact
        layout={MainLayout}
        path={TEAM_PATH}
      />

      <RouteWithLayout
        component={EventView}
        exact
        layout={MainLayout}
        path={EVENT_PATH}
      />

      <RouteWithLayout
        component={JobaddView}
        exact
        layout={MainLayout}
        path={JOB_PATH}
      />

      <RouteWithLayout
        component={ContactMessageView}
        exact
        layout={MainLayout}
        path={CONTACT_MESSAGE_PATH}
      />

      <RouteWithLayout
        component={BillView}
        exact
        layout={MainLayout}
        path={BILLS_PATH}
      />

      <RouteWithLayout
        component={OneFactureView}
        exact
        layout={MainLayout}
        path={`${ONE_COMPANY_FACTURE_PATH}/:company_id/:bill_id`}
      />

      <RouteWithLayout
        component={FactureView}
        exact
        layout={MainLayout}
        path={FACTURE_PATH}
      />
      <RouteWithLayout
        component={SettingEditView}
        exact
        layout={MainLayout}
        path={SITE_SETTING_EDIT_PATH}
      />
      <RouteWithLayout
        component={UserView}
        exact
        layout={MainLayout}
        path={USERS_PATH}
      />

      <RouteWithLayout
        component={CategoryView}
        exact
        layout={MainLayout}
        path={CATEGORY_PATH}
      />
      <RouteWithLayout
        component={CompanyView}
        exact
        layout={MainLayout}
        path={COMPANY_PATH}
      />

      <RouteWithLayout
        component={CompanyCommercialView}
        exact
        layout={MainLayout}
        path={COMPANY_COMMERCIAL_PATH}
      />
      <RouteWithLayout
        component={SenrvHistoryView}
        exact
        layout={MainLayout}
        path={HISTORY_PATH}
      />

      <RouteWithLayout
        component={OneCompanyView}
        exact
        layout={MainLayout}
        path={`${ONE_COMPANY_PATH}/:company_id`}
      />

      <UnAuthRoute
        component={SignInView}
        exact
        layout={MinimalLayout}
        path={LOGIN_PATH}
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  )
}

export default Routes
