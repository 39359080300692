import React, { useState } from 'react'
import { useQuery, useMutation } from 'react-apollo'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { makeStyles } from '@material-ui/styles'
import Loading from 'components/Loading'
import { TeamList } from './components/TeamList'
import { TeamToolbar } from './components/TeamToolbar'
import { TeamCreate } from './components/TeamCreate'
import { ModalDialog } from 'components/ModalDialog'
import { TeamEdit } from './components/TeamEdit'

import { currentUserQuery, findCustomersQuery } from 'graphql/query/user'
import {
  registerUserAdminMutation,
  removeUserAdminMutation,
  updateUserAdminMutation,
  regeneratePasswordMutation
} from 'graphql/mutation/user'
import { Accordion } from 'components/Accordion'
import { oneSettingAdminQuery } from 'graphql/query/setting_admin'
import { toast } from 'react-toastify'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '9px 25px'
    // padding: theme.spacing(3),
  },
  // content: {
  //   marginTop: theme.spacing(2),
  // },
  pagination: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  }
}))
const createSchema = Yup.object().shape({
  gender: Yup.string().required('champ requis'),
  sms_notify: Yup.boolean()
    .typeError('champ requis')
    .required('champ requis'),
  name: Yup.string()
    .trim()
    .min(2, 'minimun 2 caractères')
    .max(50, 'maximum 50 caractères')
    .required('champ requis'),
  lastname: Yup.string()
    .trim()
    .min(2, 'minimun 2 caractères')
    .max(50, 'maximum 50 caractères')
    .required('champ requis'),
  password: Yup.string()
    .trim()
    .min(6, 'minimun 6 caractères')
    .max(50, 'maximum 50 caractères')
    .required('champ requis'),
  email: Yup.string().email('email invalide'),

  phone: Yup.string()
    .trim()
    .required('champ requis')
})

const updateSchema = Yup.object().shape({
  roles: Yup.array()
    .of(Yup.string())
    .nullable(),
  is_active: Yup.boolean()
    .typeError('champ requis')
    .nullable()
})

const removeSchema = Yup.object().shape({
  company_id: Yup.string().required('champ requis')
})
const UserView = () => {
  const classes = useStyles()

  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [page, setCurrentPage] = useState(1)
  const [limit, setLimit] = useState(10)

  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [rolename, setRolename] = useState([])
  const [is_active, setIsActive] = useState(true)
  const [expanded1, setExpanded1] = useState(true)
  const [expanded2, setExpanded2] = useState(true)
  const [createModal, setCreateModal] = useState(false)
  const [globalCreateError, setGlobalCreateError] = useState('')
  const [globalEditError, setGlobalEditError] = useState('')
  const [editModal, setEditModal] = useState(false)
  const [editedItem, setEditedItem] = useState(null)
  const [globalDeleteError, setGlobalDeleteError] = useState('')
  const [deleteModal, setDeleteModal] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [deleteItem, setDeleteItem] = useState(null)
  const { data: currentUserData, loading: loading2 } = useQuery(currentUserQuery)
  const { loading: loading1, data, fetchMore, refetch: refetchUsers } = useQuery(
    findCustomersQuery,
    {
      variables: { page, is_active }
    }
  )

  const { data: settingAdminData, loading: loading3 } = useQuery(
    oneSettingAdminQuery
  )
  const phone_code =
    settingAdminData &&
    settingAdminData.oneSettingAdmin &&
    settingAdminData.oneSettingAdmin.phone_code
      ? settingAdminData.oneSettingAdmin.phone_code
      : null
  const phone_min_size =
    settingAdminData &&
    settingAdminData.oneSettingAdmin &&
    settingAdminData.oneSettingAdmin.phone_min_size
      ? settingAdminData.oneSettingAdmin.phone_min_size
      : null

  const phone_max_size =
    settingAdminData &&
    settingAdminData.oneSettingAdmin &&
    settingAdminData.oneSettingAdmin.phone_max_size
      ? settingAdminData.oneSettingAdmin.phone_max_size
      : null

  const time_zone =
    settingAdminData &&
    settingAdminData.oneSettingAdmin &&
    settingAdminData.oneSettingAdmin.time_zone
      ? settingAdminData.oneSettingAdmin.time_zone
      : null

  const [regeneratepass] = useMutation(regeneratePasswordMutation)
  const [createItem] = useMutation(registerUserAdminMutation, {
    async update (
      _cache,
      {
        data: {
          registerUserAdmin: { ok }
        }
      }
    ) {
      if (ok) {
        const variables = await computeVariables(page)

        await refetchUsers({ variables })
        /*  const { findCustomers } = await cache.readQuery({
          query: findCustomersQuery,
          variables,
        })

        const docs = findCustomers.docs.unshift(user)
        await cache.writeQuery({
          query: findCustomersQuery,
          variables,
          data: {
            findCustomers: {
              ...findCustomers,
              totalDocs: findCustomers.totalDocs + 1,
              docs,
            },
          },
        }) */
      }
    }
  })
  const [removeItem] = useMutation(removeUserAdminMutation, {
    async update (
      cache,
      {
        data: {
          removeUserAdmin: { ok, user }
        }
      }
    ) {
      if (ok) {
        const variables = await computeVariables(page)
        const { findCustomers } = await cache.readQuery({
          query: findCustomersQuery,
          variables
        })

        let docs = findCustomers.docs
        const userIndex = findCustomers.docs.findIndex(
          (d) => d.id.toString() === user.id.toString()
        )

        if (userIndex > -1) {
          docs.splice(userIndex, 1)
        }
        await cache.writeQuery({
          query: findCustomersQuery,
          variables,
          data: {
            findCustomers: {
              ...findCustomers,
              totalDocs: findCustomers.totalDocs - 1,
              docs
            }
          }
        })
      }
    }
  })
  const [updateItem] = useMutation(updateUserAdminMutation, {
    async update (
      cache,
      {
        data: {
          updateUserAdmin: { ok, user }
        }
      }
    ) {
      if (ok) {
        const variables = await computeVariables(page)
        const { findCustomers } = await cache.readQuery({
          query: findCustomersQuery,
          variables
        })

        let docs = findCustomers.docs
        const userIndex = findCustomers.docs.findIndex(
          (d) => d.id.toString() === user.id.toString()
        )

        if (userIndex > -1) {
          docs[userIndex] = user
        }
        await cache.writeQuery({
          query: findCustomersQuery,
          variables,
          data: {
            findCustomers: {
              ...findCustomers,

              docs
            }
          }
        })
      }
    }
  })
  const passwordRegen = async (id) => {
    try {
      const res = await regeneratepass({ variables: { id } })

      const { ok, errors } = res.data.regeneratePassword
      if (ok) {
        await toast.success('sms envoyé ')
      } else {
        errors.forEach(async (error) => {
          window.alert(error.message)
        })
      }
    } catch (error) {
      window.alert(error.message)
    }
  }
  const clearFilter = async () => {
    await setPhone('')
    await setName('')
    await setEmail('')
    await setRolename([])
    await setCurrentPage(1)
    let variables = await computeVariables(1)
    await refetchUsers({ variables })
    await setExpanded1(false)
  }

  /*  const checkUserCredentials = async () => {
    try {
      const userRoles =
        currentUserData &&
        currentUserData.onlineUser &&
        currentUserData.onlineUser.roles
          ? currentUserData.onlineUser.roles.filter((r) => r.is_super_admin)
          : []
      await setIsSuperAdmin(userRoles.length > 0)
      const isUserAdmin =
        currentUserData.onlineUser && currentUserData.onlineUser.roles
          ? currentUserData.onlineUser.roles.findIndex((r) => r.is_admin) > -1
          : false

      const roleData =
        userRoles.length > 0
          ? [SUPER_ADMIN, ADMIN, MANAGER, WORKER, COMMERCIAL]
          : [MANAGER, WORKER]
      await setRoleData(roleData)
      let adminData =
        data &&
        data.findCustomers &&
        data.findCustomers.docs &&
        data.findCustomers.docs.length > 0
          ? data.findCustomers.docs.filter(
              (u) =>
                u.roles &&
                u.roles.length > 0 &&
                u.roles.findIndex((r) => !r.is_super_admin) > -1
            )
          : []
      if (isUserAdmin) {
        const users = adminData.filter(
          (u) => u.roles.findIndex((r) => !r.is_comp_admin) > -1
        )

        await setTeamData(users)
      } else {
        await setTeamData(adminData)
      }

      return true
    } catch (error) {
      throw error
    }
  } */

  const create = async (
    values,

    { setSubmitting, setFieldError }
  ) => {
    const phoneLength = parseInt(values.phone.trim().length)

    if (
      !values.phone.toString().startsWith(phone_code.toString()) ||
      phoneLength < parseInt(phone_min_size) ||
      phoneLength > parseInt(phone_max_size)
    ) {
      /* await setFieldError(
        'phone',
        `Le téléphone doit commencer par ${phone_code} ,taille minimale de ${phone_min_size} et maximale de ${phone_max_size}`,
      ); */
      window.alert(
        `Le téléphone doit commencer par ${phone_code} ,taille minimale de ${phone_min_size} et maximale de ${phone_max_size}`
      )
      return
    }
    const variables = {
      ...values,
      email: values.email.trim()
    }

    const res = await createItem({
      variables
    })

    const { ok, errors } = res.data.registerUserAdmin
    if (ok) {
      await setSubmitting(false)
      await setCreateModal(false)
      await toast.success('personnel ajouté avec succès')
    } else {
      errors.forEach(async (error) => {
        if (error.path && error.path.toString() === 'global') {
          await setGlobalCreateError(error.message)
        } else {
          await setFieldError(error.path, error.message)
          await setSubmitting(false)
        }
      })
    }
  }

  const update = async (
    values,

    { setSubmitting, setFieldError, resetForm }
  ) => {
    if (editedItem.is_active != values.is_active) {
      let variables = {
        id: editedItem.id,
        is_active: values.is_active
      }

      if (values.is_active != editedItem.is_active) {
        variables.is_active = values.is_active
      }
      const res = await updateItem({
        variables
      })

      const { ok, errors } = res.data.updateUserAdmin
      if (ok) {
        await resetForm()
        await setSubmitting(false)
        await setEditModal(false)
        await setEditedItem(null)
        await setGlobalCreateError('')
        await setGlobalDeleteError('')
        await setGlobalEditError('')
        await toast.success('personnel modifié avec succès')
      } else {
        errors.forEach(async (error) => {
          if (error.path && error.path.toString() === 'global') {
            await setGlobalEditError(error.message)
          } else {
            await setFieldError(error.path, error.message)
            await setSubmitting(false)
          }
        })
      }
    }
  }

  /*  const removeUser = async () => {
    try {
      if (!isSuperAdmin) {
        window.alert("Vous n'avez pas les privilèges pour supprimer un utilisateur")
        return
      }
      if (window.confirm("Voulez-vous supprimer ce personnel")) {
        const res = await removeItem({
          variables: {
            id: editedItem.id,
          },
        })

        const { ok, errors } = res.data.removeUserAdmin
        if (ok) {
          await setLoadingDelete(false)

          await setEditModal(false)
          await setEditedItem(null)
          await setGlobalCreateError("")
          await setGlobalDeleteError("")
          await setGlobalEditError("")
        } else {
          await setLoadingDelete(false)
          errors.forEach(async (error) => {
            if (error.path && error.path.toString() === "global") {
              await window.alert(error.message)
            } else {
              await window.alert(error.message)
            }
          })
        }
      }
    } catch (error) {
      window.alert(error.message)
    }
  } */
  const remove = async (
    values,

    { setSubmitting, setFieldError, resetForm }
  ) => {
    const variables = {
      //...values,

      id: deleteItem.id
    }

    const res = await removeItem({
      variables
    })

    const { ok, errors } = res.data.removeUserAdmin
    if (ok) {
      await resetForm()
      await setSubmitting(false)
      await setDeleteModal(false)
      await setDeleteItem(null)
      await setGlobalCreateError('')
      await setGlobalDeleteError('')
      await setGlobalEditError('')
      await toast.success('personnel supprimé avec succès')
    } else {
      errors.forEach(async (error) => {
        if (error.path && error.path.toString() === 'global') {
          await setGlobalDeleteError(error.message)
        } else {
          await setFieldError(error.path, error.message)
          await setSubmitting(false)
        }
      })
    }
  }
  const computeVariables = async (page) => {
    let variables = { page, limit, is_active }

    await setCurrentPage(page)

    if (phone) {
      variables.phone = phone
    }

    if (email) {
      variables.email = email
    }
    if (rolename.length > 0) {
      variables.rolename = rolename
    }

    if (name) {
      variables.name = name
    }

    return variables
  }
  const onPageChange = async (page) => {
    try {
      const variables = await computeVariables(page)
      await setLoading(true)
      fetchMore({
        variables,
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev

          const { findCustomers } = fetchMoreResult
          return Object.assign({}, prev, {
            findCustomers
          })
        }
      })

      await setLoading(false)
    } catch (error) {
      throw error
    }
  }
  const search = async (
    values,

    _props
  ) => {
    try {
      const variables = await computeVariables(page)
      await setLoading(true)

      fetchMore({
        variables,
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev

          const { findCustomers } = fetchMoreResult
          return Object.assign({}, prev, {
            findCustomers
          })
        }
      })
      await setLoading(false)
    } catch (error) {
      throw error
    }
  }
  const selectForUpdate = async (item) => {
    try {
      await setEditedItem(item)
      await setEditModal(true)
    } catch (error) {
      throw error
    }
  }

  const selectForRemove = async (item) => {
    try {
      await setDeleteItem(item)
      await setDeleteModal(true)
    } catch (error) {
      throw error
    }
  }

  const createInitialValues = {
    name: '',
    lastname: '',
    phone: '',
    email: '',
    password: '',

    gender: '',
    sms_notify: false
  }

  if (loading1 || loading2 || loading3) {
    return <Loading />
  }

  return (
    <div className={classes.root}>
      <Accordion
        id={'search-client-filter'}
        title={'Filtrer '}
        expanded={expanded1}
        setExpanded={setExpanded1}
      >
        <TeamToolbar
          email={email}
          setEmail={setEmail}
          name={name}
          phone={phone}
          phone_code={phone_code}
          clearFilter={clearFilter}
          selectedRoles={rolename}
          setRoles={setRolename}
          setName={setName}
          setPhone={setPhone}
          search={search}
          is_active={is_active}
          setIsActive={setIsActive}
          openModal={async () => await setCreateModal(true)}
          totalPages={
            data && data.findCustomers && data.findCustomers.totalPages
              ? data.findCustomers.totalPages
              : null
          }
        />
      </Accordion>
      <Formik
        initialValues={createInitialValues}
        validationSchema={createSchema}
        onSubmit={create}
      >
        {(props) => (
          <ModalDialog
            open={createModal}
            cancel={async () => {
              await props.handleReset()
              await setCreateModal(false)
            }}
            title={'Ajout personnel'}
          >
            <TeamCreate globalError={globalCreateError} {...props} />
          </ModalDialog>
        )}
      </Formik>

      {editModal && (
        <Formik
          initialValues={{
            user_id: editedItem && editedItem.id ? editedItem.id : '',
            roles:
              editedItem && editedItem.roles
                ? editedItem.roles.map((role) => role.name)
                : [],
            is_active: editedItem.is_active
          }}
          validationSchema={updateSchema}
          onSubmit={update}
        >
          {(props) => (
            <ModalDialog
              open={editModal}
              cancel={async () => {
                await setEditModal(false)
                await setEditedItem(null)

                await props.handleReset()
              }}
              title={'Edition Utilisateur'}
            >
              <TeamEdit
                passwordRegen={passwordRegen}
                globalError={globalEditError}
                {...props}
                loadingDelete={loadingDelete}
                item={editedItem ? editedItem : null}
              />
            </ModalDialog>
          )}
        </Formik>
      )}
      {/*  <Formik
        initialValues={{
          company_id: localStorage.getItem(COMPANY_ID_STORAGE),
        }}
        validationSchema={removeSchema}
        onSubmit={remove}
      >
        {(props) => (
          <ConfirmDialog
            open={deleteModal}
            cancel={async () => {
              await setDeleteModal(false)
              await setDeleteItem(null)
              await props.handleReset()
            }}
            confirmAction={async () => await props.handleSubmit()}
            title={"Suppression Service"}
          >
            <TeamDelete
              {...props}
              item={deleteItem}
              globalError={globalDeleteError}
            />
          </ConfirmDialog>
        )}
      </Formik> */}

      <Accordion
        id={'search-client-personnel'}
        title={'Personnel'}
        expanded={expanded2}
        setExpanded={setExpanded2}
      >
        <TeamList
          time_zone={time_zone}
          data={data && data.findCustomers ? data.findCustomers : null}
          docs={
            data && data.findCustomers && data.findCustomers.docs
              ? data.findCustomers.docs
              : null
          }
          totalDocs={
            data && data.findCustomers && data.findCustomers.totalDocs
              ? data.findCustomers.totalDocs
              : null
          }
          page={page}
          totalPages={
            data && data.findCustomers && data.findCustomers.totalPages
              ? data.findCustomers.totalPages
              : null
          }
          onPageChange={onPageChange}
          selectForUpdate={selectForUpdate}
        />
      </Accordion>
    </div>
  )
}

export default UserView
