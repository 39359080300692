import React from "react"

import { makeStyles } from "@material-ui/styles"
import { TableCell, TableRow } from "@material-ui/core"
import EditIcon from "@material-ui/icons/Edit"
import DeleteIcon from "@material-ui/icons/DeleteForever"
import { renderDate } from "utils/func"

const useStyles = makeStyles((theme) => ({
  statsIcon: {
    color: theme.palette.icon,
    marginRight: theme.spacing(1),
    cursor:'pointer'
  },
}))

export const Job = (props) => {
  const { item, selectForUpdate, selectForRemove, time_zone } = props

  const classes = useStyles()

  return (
    <TableRow key={item.id}>
      <TableCell></TableCell>

      <TableCell>{item.title}</TableCell>
      <TableCell>
        {item.name} {item.lastname} {item.phone}
      </TableCell>
      {!item.author && <TableCell>Auteur inconnu</TableCell>}

      {item.author && (
        <TableCell>
          {item.author.name} {item.author.lastname} {item.author.phone}
        </TableCell>
      )}
      <TableCell>{renderDate(item.createdAt, time_zone)}</TableCell>

      <TableCell>
        <EditIcon
          className={classes.statsIcon}
          onClick={async (e) => {
            e.preventDefault()
            await selectForUpdate(item)
          }}
        />
      </TableCell>

      <TableCell>
        <DeleteIcon
          className={classes.statsIcon}
          onClick={async (e) => {
            e.preventDefault()
            await selectForRemove(item.id)
          }}
        />
      </TableCell>
    </TableRow>
  )
}
