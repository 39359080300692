import React from "react"
import { TableCell, TableRow } from "@material-ui/core"

import { renderDate } from "utils/func"
import { Link } from "react-router-dom"
import { ONE_COMPANY_PATH } from "utils/static_constants"

export const Bill = (props) => {
  const { item, time_zone } = props

  return (
    <TableRow key={item.id}>
      <TableCell>
        <Link to={`${ONE_COMPANY_PATH}/${item.company.id}`}>
          {item.company.name}
        </Link>
      </TableCell>
      <TableCell>{item.final_bill}</TableCell>
      <TableCell style={{ color: item.bill_paid ? "green" : "red" }}>
        {item.bill_paid ? "facture payée" : "non payée"}
      </TableCell>

      <TableCell>{item.phone}</TableCell>
      <TableCell>{item.subscription_price}</TableCell>

      <TableCell>{item.total_sms}</TableCell>

      <TableCell>{renderDate(item.createdAt, time_zone)}</TableCell>
    </TableRow>
  )
}
