import gql from "graphql-tag"

export const compSignalQuery = gql`
  query compSignal($page: Int!, $limit: Int, $company_id: String!) {
    compSignal(page: $page, limit: $limit, company_id: $company_id) {
      totalDocs
      hasPrevPage
      hasNextPage
      totalPages
      limit
      offset
      page
      prevPage
      nextPage
      docs {
        id
        message
        author_name
        service {
          id
          name
        }
        company {
          id
          name
          reference
        }
        createdAt
        updatedAt
      }
    }
  }
`

export const compSignalCommercialQuery = gql`
  query compSignalCommercial($page: Int!) {
    compSignalCommercial(page: $page) {
      totalDocs
      hasPrevPage
      hasNextPage
      totalPages
      limit
      offset
      page
      prevPage
      nextPage
      docs {
        id
        message
        author_name
        service {
          id
          name
        }
        company {
          id
          name
          reference
        }
        createdAt
        updatedAt
      }
    }
  }
`
