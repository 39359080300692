import React from 'react'
import {
  Grid,
  Button,
  TextField,
  FormControlLabel,
  Checkbox
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Loading from 'components/Loading'
import { MessageField } from 'components/MessageField'
import { SelectField } from 'components/SelectField'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: '100%'
  },
  chip: {
    margin: theme.spacing(0.5)
  }
}))
export const TeamEdit = ({
  values,
  touched,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  removeUser,
  loadingDelete,
  globalError,
  setFieldValue,
  roles,
  item,
  isSuperAdmin,
  passwordRegen
}) => {
  const theme = useTheme()
  const classes = useStyles()

  if (isSubmitting || loadingDelete) {
    return <Loading />
  }
  const gender = item.gender == 'male' ? 'Monsieur' : 'Madame'

  return (
    <form
      className={classes.root}
      noValidate
      autoComplete='off'
      onSubmit={handleSubmit}
    >
      {globalError && <MessageField message={globalError} severity={'error'} />}

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Button
            color='primary'
            //disabled={!formState.isValid}
            //type="submit"
            onClick={async () => {
              await passwordRegen(values.user_id)
            }}
            variant='contained'
          >
            Regenérer password
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={
              <Checkbox
                id='is_active'
                name='is_active'
                checked={values.is_active}
                onChange={async (_e, value) =>
                  await setFieldValue('is_active', value)
                }
              />
            }
            label={values.is_active ? 'Désactiver ce compte' : 'Activer ce compte'}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required={false}
            id='lastname'
            name='lastname'
            label='Prénom'
            disabled={false}
            value={gender + ' ' + item.lastname}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required={false}
            id='name'
            name='name'
            label='Nom'
            disabled={false}
            value={item.name}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required={false}
            id='email'
            name='email'
            label='Email'
            disabled={false}
            value={item.email}
          />
        </Grid>
        {/*  <Grid item xs={12} sm={6} style={style}>
          <SelectField
            multiple={true}
            handleChange={async ({ target: { value } }) => await setRoles(value)}
            name="rolename"
            label={"Role"}
            value={selectedRoles}
            theme={theme}
            dataSource={roles}
          />
        </Grid> */}
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required={false}
            id='phone'
            name='phone'
            label='phone'
            disabled={false}
            value={item.phone}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Button
            color='primary'
            //disabled={!formState.isValid}
            type='submit'
            onClick={handleSubmit}
            variant='contained'
          >
            enregistrer
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}
