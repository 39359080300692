import gql from "graphql-tag"

export const createSettingAdminMutation = gql`
  mutation createSettingAdmin(
    $country: String
    $regions: [String]
    $currency: String
    $phone_code: String
    $time_zone: String
    $sms_price: Float
    $phone_min_size: Int
    $phone_max_size: Int
    $minimal_cancel:Int
  ) {
    createSettingAdmin(
      country: $country
      regions: $regions
      currency: $currency
      phone_code: $phone_code
      time_zone: $time_zone
      sms_price: $sms_price
      phone_min_size: $phone_min_size
      phone_max_size: $phone_max_size
      minimal_cancel:$minimal_cancel
    ) {
      ok
      errors {
        path
        message
      }
      setting {
        id
        country
        regions
        currency
        sms_price
        phone_code
        time_zone
        phone_min_size
        phone_max_size
        minimal_cancel
        createdAt
        updatedAt
      }
    }
  }
`

export const updateSettingAdminMutation = gql`
  mutation updateSettingAdmin(
    $id: String!
    $country: String
    $regions: [String]
    $currency: String
    $phone_code: String
    $time_zone: String
    $sms_price: Float
    $phone_min_size: Int
    $phone_max_size: Int
    $minimal_cancel:Int
  ) {
    updateSettingAdmin(
      id: $id
      country: $country
      regions: $regions
      currency: $currency
      phone_code: $phone_code
      time_zone: $time_zone
      sms_price: $sms_price
      phone_min_size: $phone_min_size
      phone_max_size: $phone_max_size
      minimal_cancel:$minimal_cancel
    ) {
      ok
      errors {
        path
        message
      }
      setting {
        id
        country
        regions
        currency
        phone_code
        sms_price
        time_zone
        phone_min_size
        phone_max_size
        minimal_cancel
        createdAt
        updatedAt
      }
    }
  }
`
