import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { colors } from "utils/constants"
import { Grid } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // backgroundColor: colors.LIGHT_GRAY,
    //  margin:20
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    backgroundColor: colors.WHITE,
    color: colors.SECONDARY
  },
}))

export const Accordion = ({ title, children, id, expanded, setExpanded }) => {
  const classes = useStyles()

  return (
    <Grid justify="center" style={{ margin: 10 }}>
      <ExpansionPanel expanded={expanded}>
        <ExpansionPanelSummary
          style={{ backgroundColor: colors.WHITE }}
          expandIcon={<ExpandMoreIcon style={{ color: colors.SECONDARY }} />}
          aria-controls="panel1a-content"
          id={id}
          onClick={async (e) => {
            e.preventDefault()
            await setExpanded(!expanded)
          }}
        >
          <Typography className={classes.heading}>{title}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>{children}</ExpansionPanelDetails>
      </ExpansionPanel>
    </Grid>
  )
}
