import gql from 'graphql-tag'

export const totalMoneyQuery = gql`
  query totalMoney($from_date: String, $to_date: String) {
    totalMoney(from_date: $from_date, to_date: $to_date) {
      ok
      errors {
        path
        message
      }
      total
    }
  }
`
//

export const totalMoneyCaQuery = gql`
  query totalMoneyCa($from_date: String, $to_date: String) {
    totalMoneyCa(from_date: $from_date, to_date: $to_date) {
      ok
      errors {
        path
        message
      }
      total
      total_paid
      total_unpaid
    }
  }
`
export const findCompBillsQuery = gql`
  query findCompBills(
    $page: Int
    $limit: Int
    $from_date: String
    $to_date: String
    $bill_paid: Boolean
    $company_id: String
  ) {
    findCompBills(
      page: $page
      limit: $limit

      from_date: $from_date
      to_date: $to_date
      bill_paid: $bill_paid
      company_id: $company_id
    ) {
      totalDocs
      hasPrevPage
      totalPages
      limit
      offset
      page
      prevPage
      nextPage
      docs {
        id
        company {
          id
          name
          is_active
        }
        phone
        final_bill
        final_bill_ht
        vat
        total_vat
        subscription_price
        total_sms_price
        total_sms
        from_date
        to_date
        bill_paid
        bill_paid_date
        bill_paid_canceled
        bill_paid_canceled_date
        createdAt
        updatedAt
      }
    }
  }
`

export const findBillsCommercialQuery = gql`
  query findBillsCommercial($page: Int!) {
    findBillsCommercial(page: $page) {
      totalDocs
      hasPrevPage
      totalPages
      limit
      offset
      page
      prevPage
      nextPage
      docs {
        id
        company {
          id
          name
          is_active
        }
        phone
        final_bill
        final_bill_ht
        vat
        total_vat
        subscription_price
        total_sms_price
        total_sms
        from_date
        to_date
        bill_paid
        bill_paid_date
        bill_paid_canceled
        bill_paid_canceled_date
        createdAt
        updatedAt
      }
    }
  }
`

export const compBillByIdQuery = gql`
  query compBillById($id: String!) {
    compBillById(id: $id) {
      ok
      errors {
        path
        message
      }
      bill {
        id
        company {
          id
          name
          is_active
          reference
          region
        }
        phone
        final_bill
        final_bill_ht
        vat
        total_vat
        subscription_price

        total_rv_price
        total_rv
        total_sms
        total_sms_price
        from_date
        to_date
        bill_paid
        bill_paid_date
        bill_paid_canceled
        bill_paid_canceled_date
        createdAt
        updatedAt
      }
    }
  }
`

export const findCompBillsAdminQuery = gql`
  query findCompBillsAdmin(
    $page: Int!
    $limit: Int
    $from_date: String
    $to_date: String
    $bill_paid: Boolean
    $company_id: String
  ) {
    findCompBillsAdmin(
      page: $page
      bill_paid: $bill_paid
      limit: $limit
      from_date: $from_date
      to_date: $to_date

      company_id: $company_id
    ) {
      totalDocs
      hasPrevPage
      totalPages
      limit
      offset
      page
      prevPage
      nextPage
      docs {
        id
        company {
          id
          name
          is_active
        }
        phone
        final_bill
        final_bill_ht
        vat
        total_vat
        subscription_price
        total_sms
        total_sms_price
        from_date
        to_date
        bill_paid
        bill_paid_date
        bill_paid_canceled
        bill_paid_canceled_date
        createdAt
        updatedAt
      }
    }
  }
`

export const findSmsCompQuery = gql`
  query findSmsComp(
    $page: Int!
    $limit: Int
    $bill_id: String
    $company_id: String!
    $from_date: String
    $to_date: String
  ) {
    findSmsComp(
      page: $page
      limit: $limit
      bill_id: $bill_id
      company_id: $company_id
      from_date: $from_date
      to_date: $to_date
    ) {
      totalDocs
      hasPrevPage
      hasNextPage
      totalPages
      limit
      offset
      page
      prevPage
      nextPage
      docs {
        id
        phone
        author {
          name
          lastname
          gender
        }
        title
        createdAt
      }
    }
  }
`
