import React, { useState } from 'react'
import { useLazyQuery, useQuery } from 'react-apollo'
import moment from 'moment-timezone'
import { findEventsAdminQuery, notifyEventQuery } from '../../graphql/query/event'
import Loading from '../../components/Loading'

import { oneSettingAdminQuery } from 'graphql/query/setting_admin'
import { categoriesQuery } from 'graphql/query/category'
import { EventList } from './components/EventList'

import { EventToolbar } from './components/EventToolbar'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '9px 25px'
    // padding: theme.spacing(3),
  }
}))

export default function EventView () {
  const classes = useStyles()
  //setGlobalSignalError

  const [page, setPage] = useState(1)
  const [eventNumber, setEventNumber] = useState('')
  const [categories, setCategories] = useState([])
  const [author_phone, setAuthorPhone] = useState('')

  const [from_date, setFromDate] = useState(undefined)
  const [to_date, setToDate] = useState(undefined)
  const [canceled, setCanceled] = useState(undefined)

  const { loading: loading1, data: generalSetting } = useQuery(oneSettingAdminQuery)
  const { loading: loading3, data: catsData } = useQuery(categoriesQuery)

  const [notifyEvent,_] = useLazyQuery(notifyEventQuery)
  
  const { loading: loading2, data, fetchMore, refetch: refetchEvents } = useQuery(
    findEventsAdminQuery,
    {
      variables: {
        page
      }
      //fetchPolicy: "cache-and-network",
    }
  )

  const timezone =
    generalSetting &&
    generalSetting.oneSettingAdmin &&
    generalSetting.oneSettingAdmin.time_zone
      ? generalSetting.oneSettingAdmin.time_zone
      : 'Africa/Dakar'

  const phone_code =
    generalSetting &&
    generalSetting.oneSettingAdmin &&
    generalSetting.oneSettingAdmin.phone_code
      ? generalSetting.oneSettingAdmin.phone_code
      : 'Africa/Dakar'

  if (loading1 || loading2) {
    return <Loading />
  }

  const computeVariables = async (page) => {
    let variables = { page }

    await setPage(page)

    if (eventNumber) {
      variables.event_number = eventNumber
    }
    if (canceled != undefined) {
      variables.canceled = canceled
    }
    if (from_date) {
      variables.from_date = moment(from_date).format('MM-DD-YYYY')
    }

    if (to_date) {
      variables.to_date = moment(to_date).format('MM-DD-YYYY')
    }

    if (author_phone) {
      variables.author_phone = author_phone
    }

    if (categories.length > 0) {
      const category_ids = []

      catsData.categories.forEach((cat) => {
        categories.forEach((c) => {
          if (c == cat.name) {
            category_ids.push(cat.id)
          }
        })
      })

      if (category_ids.length > 0) {
        variables.category_ids = category_ids
      }
    }
    return variables
  }
  const onPageChange = async (page) => {
    try {
      let variables = computeVariables(page)

      fetchMore({
        variables,
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev

          const { findEventsAdmin } = fetchMoreResult
          return Object.assign({}, prev, {
            findEventsAdmin
          })
        }
      })
    } catch (error) {
      throw error
    }
  }
  const search = async () => {
    try {
      let variables = await computeVariables(1)

      fetchMore({
        variables,
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev

          const { findEventsAdmin } = fetchMoreResult
          return Object.assign({}, prev, {
            findEventsAdmin
          })
        }
      })
    } catch (error) {
      throw error
    }
  }

  const clearFilter = async () => {
    try {
      await setPage(1)
      await setEventNumber('')
      await setCategories([])
      await setAuthorPhone('')

      await setFromDate(undefined)
      await setToDate(undefined)
      await setCanceled(undefined)

      await refetchEvents({ variables: { page: 1 } })
    } catch (error) {
      window.alert(error.message)
    }
  }

  return (
    <div className={classes.root}>
      <EventToolbar
        notifyEvent={notifyEvent}
        author_phone={author_phone}
        phone_code={phone_code}
        setAuthorPhone={setAuthorPhone}
        eventNumber={eventNumber}
        setEventNumber={setEventNumber}
        from_date={from_date}
        setFromDate={setFromDate}
        to_date={to_date}
        setToDate={setToDate}
        canceled={canceled}
        setCanceled={setCanceled}
        search={search}
        categories={categories}
        setCategories={setCategories}
        clearFilter={clearFilter}
        catsData={
          catsData && catsData.categories
            ? catsData.categories.map((c) => c.name)
            : []
        }
      />
      <EventList
        data={data && data.findEventsAdmin ? data.findEventsAdmin : null}
        onPageChange={onPageChange}
        // selectEvent={selectEvent}
        timezone={timezone}
      />
    </div>
  )
}
