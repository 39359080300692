import gql from "graphql-tag";

export const categoriesQuery = gql`
  query categories {
    categories {
      id
      name
      createdAt
      priority
      description
      picture
    }
  }
`;

export const findCatsQuery = gql`
  query findCats($page: Int, $name: String) {
    findCats(page: $page, name: $name) {
      totalDocs
      hasPrevPage
      totalPages
      limit
      offset
      page
      prevPage
      nextPage
      docs {
        id
        name
        priority
        description
        picture
        createdAt
        is_active
        author {
          id
          name
          lastname
        }
      }
    }
  }
`;
