import React from "react"

const opts = {
  height: "390",
  width: "640",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
  },
}
const VideoYoutube = ({ id }) => {
  return (
    <iframe width={opts.width} height={opts.height} src={`https://www.youtube.com/embed/${id}`} title="YouTube video player" 
    frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
    allowfullscreen></iframe>
  
  )
}

export default VideoYoutube
