import gql from 'graphql-tag'

export const loginMutation = gql`
  mutation loginAdmin($phone: String, $password: String) {
    loginAdmin(phone: $phone, password: $password) {
      ok
      errors {
        path
        message
      }
      token
    }
  }
`

 

export const regeneratePasswordMutation = gql`
  mutation regeneratePassword($id: String!) {
    regeneratePassword(id: $id) {
      ok
      errors {
        path
        message
      }
    }
  }
`
export const logoutSMutation = gql`
  mutation logoutS {
    logoutS {
      ok
    }
  }
`
export const LOGIN_USER_MUTATION = gql`
  mutation loginUser($currentUser: CurrentUserInput) {
    loginUser(currentUser: $currentUser) @client
  }
`

export const LOGOUT_USER_MUTATION = gql`
  mutation logoutUser {
    logoutUser @client
  }
`
export const updateProfileMutation = gql`
  mutation updateProfile(
    $id: String!
    $name: String
    $lastname: String
    $file: Upload
    $email: String
    $phone: String
    $password: String
  ) {
    updateProfile(
      id: $id
      name: $name
      lastname: $lastname
      file: $file
      email: $email
      phone: $phone
      password: $password
    ) {
      ok
      errors {
        path
        message
      }
      user {
        id
        name
        lastname
        fullname
        email
        phone
        picture
        is_active
        phone_confirmed
      }
    }
  }
`

export const createUserAdminMutation = gql`
  mutation createUserAdmin(
    $name: String!
    $lastname: String!
    $phone: String!
    $email: String
    $password: String!
    $gender: String!
    $roles: [String]!
  ) {
    createUserAdmin(
      name: $name
      lastname: $lastname
      phone: $phone
      email: $email
      password: $password
      gender: $gender
      roles: $roles
    ) {
      ok
      errors {
        path
        message
      }
      user {
        id
        name
        lastname
        fullname
        email
        picture
        phone
        is_active
        phone_confirmed
        createdAt

        roles {
          _id
          name
          is_admin
          is_manager
          is_worker
          is_super_admin
          is_commercial
          is_from_senrv
        }
      }
    }
  }
`

export const registerUserAdminMutation = gql`
  mutation registerUserAdmin(
    $name: String!
    $lastname: String!
    $phone: String!
    $email: String
    $password: String!
    $gender: String!
  ) {
    registerUserAdmin(
      name: $name
      lastname: $lastname
      phone: $phone
      email: $email
      password: $password
      gender: $gender
    ) {
      ok
      errors {
        path
        message
      }
      user {
        id
        name
        lastname
        fullname
        email
        gender
        picture
        phone
        is_active
        phone_confirmed
        createdAt
      }
    }
  }
`
export const changePasswordMutation = gql`
  mutation changePassword($password: String!, $phone: String!) {
    changePassword(password: $password, phone: $phone) {
      ok
      must_change_password
      errors {
        path
        message
      }
    }
  }
`
export const updateAccountMutation = gql`
  mutation updateAccount(
    $email: String
    $phone: String
    $name: String
    $lastname: String
    $gender: String
  ) {
    updateAccount(
      email: $email
      phone: $phone
      name: $name
      lastname: $lastname
      gender: $gender
    ) {
      ok
      gender
      name
      email
      phone
      lastname
      errors {
        path
        message
      }
    }
  }
`
export const updateUserAdminMutation = gql`
  mutation updateUserAdmin(
    $id: String!
    $is_active: Boolean
    $phone_confirmed: Boolean
    $roles: [String]
  ) {
    updateUserAdmin(
      id: $id
      is_active: $is_active
      phone_confirmed: $phone_confirmed
      roles: $roles
    ) {
      ok
      errors {
        path
        message
      }
      user {
        id
        name
        lastname
        fullname
        email
        picture
        phone
        is_active
        phone_confirmed
        createdAt

        roles {
          _id
          name
          is_admin
          is_manager
          is_worker
          is_super_admin
          is_commercial
          is_customer
          is_from_senrv
        }
      }
    }
  }
`
export const removeUserAdminMutation = gql`
  mutation removeUserAdmin($id: String!) {
    removeUserAdmin(id: $id) {
      ok
      errors {
        path
        message
      }
      id
    }
  }
`
