import React from "react"
import { Button, TextField, Input } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Loading from "components/Loading"
import { MessageField } from "components/MessageField"
import { Grid } from "@material-ui/core"
const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: "100%",
  },
}))
export const CategoryCreate = ({
  values,
  touched,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  globalError,
}) => {
  const classes = useStyles()

  if (isSubmitting) {
    return <Loading />
  }
  return (
    <form
      className={classes.root}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      {globalError && <MessageField message={globalError} severity={"error"} />}

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            id="name"
            name="name"
            label="Nom"
            onChange={handleChange}
            error={touched.name && errors.name ? true : false}
            fullWidth
            helperText={touched.name && errors.name ? errors.name : null}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            label="Priorité"
            value={values.priority}
            inputProps={{ min: "1", step: "1" }}
            name="priority"
            id="priority"
            onChange={handleChange}
            type="number"
            helperText={touched.priority && errors.priority ? errors.priority : null}
            error={touched.priority && errors.priority ? true : false}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            multiline
            fullWidth
            required
            label="Description"
            value={values.description}
            name="description"
            id="description"
            onChange={handleChange}
            type="text"
            helperText={
              touched.description && errors.description ? errors.description : null
            }
            error={touched.description && errors.description ? true : false}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Input
            fullWidth
            type="file"
            name="file"
            id="file"
            onChange={async ({
              target: {
                _validity,
                files: [file],
              },
            }) => {
              await setFieldValue("file", file)
            }}
            style={{ display: "none" }}
          />
          <label htmlFor="file">
            <Button variant="contained" component="span" color="secondary">
              Ajouter une photo
            </Button>
          </label>
        </Grid>

        {values.file && (
          <Grid item xs={12} sm={12}>
            <div>
              <img
                src={URL.createObjectURL(values.file)}
                alt="photo"
                width="100%"
                height="300"
              />
            </div>
            {touched.file && errors.file && (
              <span style={{ color: "red" }}>{errors.file}</span>
            )}
          </Grid>
        )}

        <Grid item lg={12} md={12} xs={12} sm={12}>
          <Button
            color="primary"
            type="submit"
            variant="contained"
          >
            Créer
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}
