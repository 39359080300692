import React from "react"
import { useQuery, useMutation } from "react-apollo"
import { makeStyles } from "@material-ui/core/styles"
import Alert from "@material-ui/lab/Alert"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"

import NotificationIcon from "@material-ui/icons/NotificationImportant"
import Loading from "../../components/Loading"

import { userCompNofifsQuery } from "graphql/query/notif"
import { oneSettingAdminQuery } from "graphql/query/setting_admin"
import { renderDate } from "utils/func"
import {
  removeNotifMutation,
  updateCompNofifsMutation,
} from "graphql/mutation/notif"
// import { useComponentWillMount } from "components/useComponentWillMount"
import { useComponentDidMount } from "components/useComponentDidMount"
import client from "graphql/apollo"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "10px 15px",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  // notif: {
  //   display: "flex",
  //   alignItems: "center",
  //   padding: "0px 30px!important",
  // },
  // descriptionNotif: {
  //   textAlign: "center",
  // },
  notNotif: {
    height: "80vh",
    color: "#546e7a",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  notNotifText: {
    marginTop: 5,
    fontSize: 25,
    fontFamily: "Roboto",
  },
}))

export default function NotifView(props) {
  const classes = useStyles()
  const { loading: loading1, data: generalSetting } = useQuery(oneSettingAdminQuery)
  const { loading: loading2, data } = useQuery(userCompNofifsQuery, {
    variables: {},
  })
  const [removeItem] = useMutation(removeNotifMutation, {
    async update(
      cache,
      {
        data: {
          removeAdminNotif: { ok, id },
        },
      }
    ) {
      if (ok) {
        const { userAdminNotifs } = await cache.readQuery({
          query: userCompNofifsQuery,
          variables: {},
        })

        let docs = userAdminNotifs

        const eventIndex = userAdminNotifs.findIndex(
          (d) => d.id.toString() === id.toString()
        )

        if (eventIndex > -1) {
          docs.splice(eventIndex, 1)
        }

        await cache.writeQuery({
          query: userCompNofifsQuery,
          data: {
            userAdminNotifs: {
              docs,
            },
          },
        })
      }
    },
  })

  const [updateNotif] = useMutation(updateCompNofifsMutation)

  const updateNotification = async () => {
    try {
      const { userAdminNotifs } = await client.readQuery({
        query: userCompNofifsQuery,
        variables: {},
      })

      let docs = userAdminNotifs.map((notif) => ({ ...notif, seen: true }))

      await client.writeQuery({
        query: userCompNofifsQuery,
        data: {
          userAdminNotifs: docs,
        },
      })
      await updateNotif({ variables: {  } })

      return true
    } catch (error) {
      window.alert(error.message)
    }
  }

  useComponentDidMount(() => {
    updateNotification()
      .then(() => console.log("notif updated"))
      .catch((e) => console.log("error update notif", e))
  })
  if (loading1 || loading2) {
    return <Loading />
  }
  const timezone =
    generalSetting &&
    generalSetting.oneSettingAdmin &&
    generalSetting.oneSettingAdmin.timezone
      ? generalSetting.oneSettingAdmin.timezone
      : "Africa/dakar"

  const notifs =
    data && data.userAdminNotifs && data.userAdminNotifs.length > 0
      ? data.userAdminNotifs
      : null

  if (!notifs) {
    return (
      <div className={classes.root}>
        <div className={classes.notNotif}>
          <NotificationIcon style={{ fontSize: 50 }} />
          <p className={classes.notNotifText}>Aucune notification</p>
        </div>
      </div>
    )
  }
  return (
    <div className={classes.root}>
      {notifs.map((notif) => (
        <Alert
          className={"notif"}
          key={notif.id}
          severity={notif.notif_type === "cancel_event" ? "warning" : "success"}
          icon={<NotificationIcon />}
          action={
            <React.Fragment>
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={async () => {
                  await removeItem({ variables: { id: notif.id } })
                }}
              >
                <CloseIcon fontSize="inherit" color="error" />
              </IconButton>
              {/*   <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={async () => {
                  await props.history.push(`${EVENT_ONE_PATH}/${notif.notif_type_id}`)
                }}
                style={{ margin: 30 }}
              >
                <CalendarIcon fontSize="inherit" color="action" />
              </IconButton> */}
            </React.Fragment>
          }
        >
          <p className={"descriptionNotif"}>{notif.description}</p>
          <p>
            <span style={{ textAlign: "center" }}>
              {renderDate(notif.createdAt, timezone)}
            </span>
          </p>
        </Alert>
      ))}
    </div>
  )
}
