import React, { useState, Fragment } from "react"
import { useQuery, useMutation } from "react-apollo"
import { Accordion } from "components/Accordion"
import { findServicesAdminQuery } from "../../graphql/query/service"
import Loading from "components/Loading"
import { ServiceList } from "./components/ServiceList"
import { ModalDialog } from "components/ModalDialog"
import { Grid } from "@material-ui/core"
import { Button } from "@material-ui/core"
import { toast } from "react-toastify"
import { updateServiceAdminMutation } from "graphql/mutation/service"
import client from "graphql/apollo"

export default function ServiceListView({ company_id, currency, time_zone }) {
  const [expandService, setExpandService] = useState(true)
  const [loading, setLoading] = useState(false)
  const [selectedService, setSelectedService] = useState(null)
  const [serviceModal, setServiceModal] = useState(false)
  const [updateService] = useMutation(updateServiceAdminMutation, {
    async update(
      _client,
      {
        data: {
          updateServiceAdmin: { ok, is_active, id },
        },
      }
    ) {
      if (ok) {
        const variables = { page: pageService, company_id }
        const { findServicesAdmin } = await client.readQuery({
          query: findServicesAdminQuery,
          variables,
        })

        let docs = findServicesAdmin.docs
        const index = findServicesAdmin.docs.findIndex(
          (d) => d.id.toString() === id.toString()
        )

        if (index > -1) {
          let service = docs[index]
          service.is_active = is_active
          docs[index] = service
          await client.writeQuery({
            query: findServicesAdminQuery,
            variables,
            data: {
              findServicesAdmin: {
                ...findServicesAdmin,

                docs,
              },
            },
          })
        }
      }
    },
  })

  const [pageService, setPageService] = useState(1)
  const {
    loading: loadingServices,
    data: servicesData,
    fetchMore: fetchMoreServices,
  } = useQuery(findServicesAdminQuery, {
    skip: company_id ? false : true,
    variables: { company_id, page: pageService },
  })

  const onPageChangeService = async (page) => {
    try {
      await setPageService(page)
      const variables = {
        company_id,
        page,
      }

      fetchMoreServices({
        variables,
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev

          const { findServicesAdmin } = fetchMoreResult
          return Object.assign({}, prev, {
            findServicesAdmin,
          })
        },
      })
    } catch (error) {
      throw error
    }
  }

  const selectedServiceForUpdate = async (service) => {
    try {
      await setSelectedService(service)
      await setServiceModal(true)
    } catch (error) {
      window.alert(error.message)
    }
  }
  const updateSelectedService = async () => {
    try {
      if (window.confirm("Vous êtes sûr(e) de vouloir faire cette action")) {
        await setLoading(true)
        const res = await updateService({
          variables: {
            company_id,
            id: selectedService.id,
            is_active: !selectedService.is_active,
          },
        })
        
        const { ok, errors } = res.data.updateServiceAdmin
        await setLoading(false)
        if (ok) {
          await setServiceModal(false)
          await setSelectedService(null)
          await toast.success("Opération effectuée avec succès")
        } else {
          window.alert(errors[0].message)
        }
      }
    } catch (error) {
      window.alert(error.message)
    }
  }
  return (
    <Accordion
      id={"services-comp"}
      title={"Liste des services"}
      expanded={expandService}
      setExpanded={setExpandService}
    >
      {loadingServices && <Loading />}
      {!loadingServices && servicesData && servicesData.findServicesAdmin && (
        <ServiceList
          time_zone={time_zone}
          currency={currency}
          data={
            servicesData && servicesData.findServicesAdmin
              ? servicesData.findServicesAdmin
              : null
          }
          docs={
            servicesData &&
            servicesData.findServicesAdmin &&
            servicesData.findServicesAdmin.docs
              ? servicesData.findServicesAdmin.docs
              : []
          }
          page={pageService}
          totalPages={
            servicesData &&
            servicesData.findServicesAdmin &&
            servicesData.findServicesAdmin.totalPages
              ? servicesData.findServicesAdmin.totalPages
              : null
          }
          onPageChange={onPageChangeService}
          selectForUpdate={selectedServiceForUpdate}
        />
      )}

      {selectedService && serviceModal && (
        <ModalDialog
          open={serviceModal}
          cancel={async () => {
            await setServiceModal(false)
            await setSelectedService(null)
          }}
          title={"Modification service"}
        >
          <Fragment>
            {loading && <Loading />}
            {!loading && (
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <h1 style={{ textAlign: "center" }}>{selectedService.name}</h1>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <img
                    size="big"
                    src={selectedService.picture}
                    alt={selectedService.name}
                    style={{ width: "100%", height: 300 }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Button
                    style={{
                      backgroundColor: selectedService.is_active ? "red" : "green",
                      color: "white",
                    }}
                    //disabled={!formState.isValid}
                    fullWidth
                    size="large"
                    //type="submit"
                    onClick={updateSelectedService}
                    variant="contained"
                  >
                    {selectedService.is_active
                      ? "Désactiver ce service"
                      : "Activer ce service"}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Fragment>
        </ModalDialog>
      )}
    </Accordion>
  )
}
