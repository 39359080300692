import React from "react"
import {
  Grid,
  Button,
  TextField,
  Input,
  Checkbox,
  FormControlLabel,
  InputLabel,
} from "@material-ui/core"

import { makeStyles } from "@material-ui/core/styles"
import Loading from "components/Loading"
import { MessageField } from "components/MessageField"
import CurrencyTextField from "@unicef/material-ui-currency-textfield/dist/CurrencyTextField"
const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: "100%",
  },
}))
export const SubscriptionCompEdit = ({
  values,
  touched,
  errors,
  handleChange,

  handleSubmit,
  isSubmitting,
  setFieldValue,
  item,
  globalError,
  currency,
}) => {
  const classes = useStyles()

  if (!item) {
    return null
  }
  if (isSubmitting) {
    return <Loading />
  }

  return (
    <form
      className={classes.root}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      {globalError && <MessageField message={globalError} severity={"error"} />}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={
              <Checkbox
                id="is_active"
                name="is_active"
                checked={values.is_active}
                onChange={async (_e, value) =>
                  await setFieldValue("is_active", value)
                }
              />
            }
            label={values.is_active ? "Désactiver" : "Activer"}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <label style={{ color: "green" }}>Etat Actuel</label>
          <h3 style={{ color: values.is_active ? "green" : "red" }}>
            {values.is_active ? "Actif" : "Inactif"}
          </h3>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="name"
            name="name"
            label="Nom"
            onChange={handleChange}
            error={touched.name && errors.name ? true : false}
            fullWidth
            helperText={touched.name && errors.name ? errors.name : null}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <label style={{ color: "green" }}>Nom Actuel</label>
          <h3>{item.name}</h3>
        </Grid>

        <Grid item xs={12} sm={6}>
          <label>Description</label>
          <textarea
            id="description"
            name="description"
            label="Description"
            onChange={handleChange}
            rows={5}
            style={{ width: "100%" }}
          ></textarea>
          <span></span>

          {touched.description && errors.description && (
            <span style={{ color: "red" }}>{errors.description}</span>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <label style={{ color: "green" }}>Description actuelle</label>
          <p>{item.description}</p>
        </Grid>

        <Grid item xs={12} sm={6}>
         

          <CurrencyTextField
            as={TextField}
            fullWidth
            value={values.price}
            name="price"
            id="price"
            variant="standard"
           // disabled={!values.show_price}
            label={`Prix en ${currency}`}
            onChange={async (_event, value) => await setFieldValue("price", value)}
            helperText={touched.price && errors.price ? errors.price : null}
            error={touched.price && errors.price ? true : false}
            currencySymbol={currency}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <label style={{ color: "green" }}>Prix actuel</label>
          <p>{item.price}</p>
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputLabel required>Nombre de services</InputLabel>

          <TextField
            fullWidth
            value={values.services_number}
            inputProps={{ min: "0", step: "1" }}
            name="services_number"
            id="services_number"
            onChange={handleChange}
            type="number"
            helperText={
              touched.services_number && errors.services_number
                ? errors.services_number
                : null
            }
            error={touched.services_number && errors.services_number ? true : false}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <label style={{ color: "green" }}>Nombre actuel de services</label>
          <p>{item.services_number}</p>
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputLabel required>Nombre de personnel</InputLabel>

          <TextField
            fullWidth
            inputProps={{ min: "0", step: "1" }}
            value={values.workers_number}
            name="workers_number"
            id="workers_number"
            onChange={handleChange}
            type="number"
            helperText={
              touched.workers_number && errors.workers_number
                ? errors.workers_number
                : null
            }
            error={touched.workers_number && errors.workers_number ? true : false}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <label style={{ color: "green" }}>Nombre actuel de personnel</label>
          <p>{item.workers_number}</p>
        </Grid>
        <div>
          <Button
            color="primary"
            //disabled={!formState.isValid}
            fullWidth
            size="large"
            type="submit"
            onClick={handleSubmit}
            variant="contained"
          >
            enregistrer
          </Button>
        </div>
      </Grid>
    </form>
  )
}
