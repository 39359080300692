import React from "react"

 
import { Button } from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/Delete"
import EditIcon from "@material-ui/icons/Edit"
import CheckIcon from '@material-ui/icons/Check';
import Grid from '@material-ui/core/Grid';
import'../subscription.scss'
export const SubscriptionComp = ({ item, currency, selectForUpdate }) => {
  return (
   <Grid xs={3}>
      <div className="item-precing">
          <div className="precing-header">
            <h4 className="name-precing">{item.name}</h4>
              <p className="price-precing">{item.price} {currency}<span>/mois</span></p>
              <p className="status-precing">
                  <strong>Status: </strong><span style={{ color: item.is_active ? "green" : "red" }}>
                  {item.is_active ? " Active" : " Inactif"}
                  </span>
              </p>
          </div>
            <div className="precing-detail">

              <p><CheckIcon className="iconCheck"/> services: <strong>{item.services_number}</strong></p>
              <p><CheckIcon className="iconCheck"/> personnel: <strong>{item.workers_number}</strong></p>
              <p><CheckIcon className="iconCheck"/> Entreprises abonnées: <strong>{item && item.companies && item.companies.length > 0
                ? item.companies.length
                : 0}</strong></p>
            </div>

            <div className="precing-footer">

              <Button
                startIcon={<EditIcon />}
                color="primary"
                onClick={async (e) => {
                  e.preventDefault()
                  await selectForUpdate(item)
                }}
              >
                Modifier
              </Button>
              <br />
              <br />
              <Button startIcon={<DeleteIcon />} style={{ color: "red" }}>
                Supprimer
              </Button>

            </div>
        </div>
   </Grid>

  )
}
