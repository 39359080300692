import React, { useState } from "react"
import { useQuery, useMutation } from "react-apollo"
import * as Yup from "yup"
import { Formik } from "formik"
import { makeStyles } from "@material-ui/styles"
import { SubscriptionCompList } from "./components/SubscriptionCompList"
import { SubscriptionCompToolbar } from "./components/SubscriptionCompToolbar"
import { SubscriptionCompCreate } from "./components/SubscriptionCompCreate"
import Loading from "components/Loading"
import { ModalDialog } from "components/ModalDialog"
import { findSubscriptionsQuery } from "graphql/query/subscription"
import { oneSettingAdminQuery } from "graphql/query/setting_admin"
import { createSubMutation, updateSubMutation } from "graphql/mutation/subscription"
import { SubscriptionCompEdit } from "./components/SubscriptionCompEdit"
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
}))

const createSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .typeError("champ requis")
    .min(2, "minimum 2 caractères")
    .max(100, "maximum 100 caractères")
    .required("champ requis"),

  description: Yup.string()
    /* .trim()
    .min(100, "minimum 100 caractères")
    .required("champ requis")
    .max(500, "maximum 500 caractères"), */,
  price: Yup.number().required("champ requis"),
  services_number: Yup.number().required("champ requis"),
  workers_number: Yup.number().required("champ requis"),
})

const updateSchema = Yup.object().shape({
  id: Yup.string().trim().nullable(),
  name: Yup.string()
    .trim()
    .typeError("champ requis")
    .min(2, "minimum 2 caractères")
    .max(100, "maximum 100 caractères")
    .nullable(),
  description: Yup.string()
    .trim()
    /* .min(50, "minimum 50 caractères")
    .nullable()
    .max(500, "maximum 500 caractères"), */,
  price: Yup.number().nullable(),
  services_number: Yup.number().nullable(),
  workers_number: Yup.number().nullable(),
})

const SubscriptionCompView = () => {
  const classes = useStyles()
  const [createModal, setCreateModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState("")
  const [page, setPage] = useState(1)

  const [globalCreateError, setGlobalCreateError] = useState("")
  const [globalEditError, setGlobalEditError] = useState("")
  const [editModal, setEditModal] = useState(false)
  const [editedItem, setEditedItem] = useState(null)
  const [globalDeleteError, setGlobalDeleteError] = useState("")
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteItem, setDeleteItem] = useState(null)
  const { loading: loading1, data: subsData } = useQuery(findSubscriptionsQuery)
  const { loading: loading2, data: generalSetting } = useQuery(oneSettingAdminQuery)
  const [createItem] = useMutation(createSubMutation, {
    async update(
      cache,
      {
        data: {
          createSub: { ok, subscription },
        },
      }
    ) {
      if (ok) {
        const { findSubscriptions } = await cache.readQuery({
          query: findSubscriptionsQuery,
        })

        const docs = findSubscriptions.unshift(subscription)
        await cache.writeQuery({
          query: findSubscriptionsQuery,
          data: {
            findSubscriptions: {
              ...docs,
            },
          },
        })
      }
    },
  })

  const [updateItem] = useMutation(updateSubMutation, {
    async update(
      cache,
      {
        data: {
          updateSub: { ok, subscription },
        },
      }
    ) {
      
      if (ok) {
        const { findSubscriptions } = await cache.readQuery({
          query: findSubscriptionsQuery,
        })

        let docs = findSubscriptions
        const index = docs.findIndex(
          (d) => d.id.toString() === subscription.id.toString()
        )
        if (index > -1) {
          docs[index] = subscription
        }
        await cache.writeQuery({
          query: findSubscriptionsQuery,
          data: {
            findSubscriptions: docs,
          },
        })
      }
    },
  })
  const selectForUpdate = async (item) => {
    try {
      await setEditedItem(item)
      await setEditModal(true)
    } catch (error) {
      throw error
    }
  }
  const create = async (
    values,

    { setSubmitting, setFieldError }
  ) => {
    try {
      const { name, description, services_number, workers_number, price } = values

      const res = await createItem({
        variables: {
          name,
          description,
          services_number,
          workers_number,
          price,
        },
      })
      const { ok, errors } = res.data.createSub

      if (ok) {
        await setSubmitting(false)
        await setCreateModal(false)
      } else {
        errors.forEach(async (error) => {
          if (error.path && error.path.toString() === "global") {
            await setGlobalCreateError(error.message)
          } else {
            await setFieldError(error.path, error.message)
            await setSubmitting(false)
          }
        })
      }
    } catch (error) {
      window.alert(error.message)
    }
  }

  const update = async (
    values,

    { setSubmitting, setFieldError }
  ) => {
    try {
      const {
        name,
        description,
        services_number,
        workers_number,
        price,
        is_active,
        id,
      } = values

      let variables = { is_active }

      if (id) {
        variables.id = id
      }
      if (name) {
        variables.name = name
      }
      if (description) {
        variables.description = description
      }
      if (services_number) {
        variables.services_number = services_number
      }
      if (workers_number) {
        variables.workers_number = workers_number
      }

      if (price !=null) {
        variables.price = price
      }
       
      const res = await updateItem({
        variables,
      })
      const { ok, errors } = res.data.updateSub

      if (ok) {
        await setSubmitting(false)

        await setEditModal(false)
        await setEditedItem(null)
      } else {
        errors.forEach(async (error) => {
          if (error.path && error.path.toString() === "global") {
            await setGlobalEditError(error.message)
          } else {
            await setFieldError(error.path, error.message)
            await setSubmitting(false)
          }
        })
      }
    } catch (error) {
      await setGlobalEditError(error.message)
    }
  }

  if (loading1 || loading2) {
    return <Loading />
  }
  const currency =
    generalSetting &&
    generalSetting.oneSettingAdmin &&
    generalSetting.oneSettingAdmin.currency
      ? generalSetting.oneSettingAdmin.currency
      : ""
  const editInitialValues = {
    id: editedItem && editedItem.id ? editedItem.id : "",
    name: "",
    description: "",
    services_number: "",
    workers_number: "",
    price: null,
    is_active: editedItem ? editedItem.is_active : false,
  }

  return (
    <div className={classes.root}>
      
      <SubscriptionCompToolbar openModal={async () => await setCreateModal(true)} />

      <Formik
        initialValues={{
          name: "",
          description: "",
          price: "",
          services_number: "",
          workers_number: "",
        }}
        validationSchema={createSchema}
        onSubmit={create}
      >
        {(props) => (
          <ModalDialog
            open={createModal}
            cancel={async () => {
              await setCreateModal(false)

              await props.handleReset()
            }}
            title={"Nouvel Abonnement"}
          >
            <SubscriptionCompCreate
              {...props}
              globalError={globalCreateError}
              currency={currency}
            />
          </ModalDialog>
        )}
      </Formik>

      {editModal && (
        <Formik
          initialValues={editInitialValues}
          validationSchema={updateSchema}
          onSubmit={update}
        >
          {(props) => (
            <ModalDialog
              open={editModal}
              maxWidth={"lg"}
              cancel={async () => {
                await setEditModal(false)
                await setEditedItem(null)

                await props.handleReset()
              }}
              title={"Edition Abonnement"}
            >
              <SubscriptionCompEdit
                {...props}
                item={editedItem}
                globalError={globalEditError}
                currency={currency}
              />
            </ModalDialog>
          )}
        </Formik>
      )}
      <SubscriptionCompList
        currency={currency}
        selectForUpdate={selectForUpdate}
        items={
          subsData && subsData.findSubscriptions ? subsData.findSubscriptions : null
        }
      />
    </div>
  )
}

export default SubscriptionCompView
