import React, { useState } from "react"
import { useQuery } from "react-apollo"
import Loading from "components/Loading"
import { Divider, Grid } from "@material-ui/core"
import Pagination from "@material-ui/lab/Pagination"
import { Accordion } from "components/Accordion"
import { readNumber } from "utils/readnumber"
import { oneCompanyQuery } from "graphql/query/company"
import { companyRatingsQuery } from "graphql/query/comp_ratings"
import { Rating } from "@material-ui/lab"

const RatingListView = ({ company_id }) => {
  const [page, setPage] = useState(1)
  const [limit] = useState(10)
  const [loading] = useState(false)
  const [expanded1, setExpanded1] = useState(false)
  const { loading: loading1, data: compData } = useQuery(oneCompanyQuery, {
    skip: company_id ? false : true,
    variables: { company_id },
  })

  const rating =
    compData && compData.oneCompany && compData.oneCompany.rating
      ? compData.oneCompany.rating
      : null

  const {
    loading: loading2,
    data: commentData,
    fetchMore,
  } = useQuery(companyRatingsQuery, {
    skip: company_id ? false : true,
    variables: {
      company_id,
      page,
      limit,
    },
  })
  const clients =
    commentData && commentData.companyRatings && commentData.companyRatings.docs
      ? commentData.companyRatings
      : null

  const computeVariables = async (page) => {
    let variables = { page, limit }

    await setPage(page)

    return variables
  }

  const onPageChange = async (page) => {
    try {
      if (
        commentData &&
        commentData.companyRatings &&
        commentData.companyRatings.hasNextPage
      ) {
        let variables = await computeVariables(page)

        const oldData =
          commentData &&
          commentData.companyRatings &&
          commentData.companyRatings.docs
            ? commentData.companyRatings.docs
            : []
        fetchMore({
          variables,
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev

            const { companyRatings } = fetchMoreResult
            const docs = [...oldData, ...companyRatings.docs]

            return Object.assign({}, prev, {
              companyRatings: {
                ...companyRatings,
                docs,
              },
            })
          },
        })
      }
      return
    } catch (error) {
      window.alert(error.message)
    }
  }

  if (loading1 || loading2 || loading) {
    return <Loading />
  }

  return (
    <Accordion
      id={"search-client-notices"}
      title={"Avis des clients"}
      expanded={expanded1}
      setExpanded={setExpanded1}
    >
      <Grid container spacing={3}>
        {clients && (
          <Grid item xs={12} sm={12}>
            <Pagination
              count={clients.totalPages}
              page={page}
              onChange={async (_event, value) => {
                if (value.toString() !== page.toString()) {
                  await onPageChange(value)
                }
              }}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={12} style={{ textAlign: "center" }}>
          <p>{readNumber(clients.totalDocs) + " avis"}</p>
          <Rating
            value={rating && rating.average ? parseFloat(rating.average) : 0}
            readOnly={true}
            max={5}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          {clients &&
            clients.docs.map((item, index) => (
              <div key={index}>
                <h5>{item.author_name}</h5>
                <Rating max={5} value={item.rate} />
                <p> {item.description}</p>
                <Divider
                  variant="middle"
                  primary
                  style={{ marginTop: 10, marginBottom: 10 }}
                />
              </div>
            ))}
        </Grid>
      </Grid>
    </Accordion>
  )
}

export default RatingListView 
