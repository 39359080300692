import React, { Fragment } from "react"
import {
  Grid,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Loading from "components/Loading"
import { MessageField } from "components/MessageField"
import { SelectField } from "components/SelectField"
import { PhoneField } from "components/PhoneField"
import CurrencyTextField from "@unicef/material-ui-currency-textfield/dist/CurrencyTextField"
import { Autocomplete } from "@material-ui/lab"

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: "100%",
  },
}))
export const CompanyEdit = ({
  values,
  touched,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  item,
  globalError,
  categories,
  regions,
  currency,
  senrvusers,
  canUpdateManager,
  phone_code,
}) => {
  const classes = useStyles()
  const theme = useTheme()

  if (!item) {
    return null
  }
  if (isSubmitting) {
    return <Loading />
  }

  return (
    <form
      className={classes.root}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      {globalError && <MessageField message={globalError} severity={"error"} />}
      <Grid container spacing={3}>
        {canUpdateManager && (
          <Fragment>
            <Grid item xs={12} sm={12}>
              <MessageField
                message={"Responsable de l'entreprise"}
                severity="success"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Autocomplete
                multiple={true}
                noOptionsText={"Aucun résultat"}
                id="senrv_manager_ids"
                name="senrv_manager_ids"
                label={"Managers"}
                defaultValue={values.senrv_manager_ids}
                options={senrvusers}
                onChange={async (e, newVal) => {
                  e.preventDefault()

                  await setFieldValue("senrv_manager_ids", newVal)
                }}
                getOptionLabel={(option) => option}
                //   style={{ margin: 100 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Personnel entreprise"
                    variant="outlined"
                    required
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Fragment>
        )}

        <Grid item xs={12} sm={12}>
          <MessageField
            message={values.is_active ? "Compte actif" : "Compte désactivé"}
            severity={values.is_active ? "success" : "error"}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={
              <Checkbox
                id="is_active"
                name="is_active"
                checked={values.is_active}
                onChange={async (_e, value) =>
                  await setFieldValue("is_active", value)
                }
              />
            }
            label={values.is_active ? "Désactiver" : "Activer"}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <MessageField message={"Nom de l' entreprise"} severity="success" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="name"
            name="name"
            value={item.name}
            required
            label="Nom de l'entreprise"
            readOnly={true}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <MessageField message={"référence de l'entreprise"} severity="success" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="reference"
            name="reference"
            label="Reférence"
            value={item.reference}
            readOnly={true}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <MessageField
            message={"Secteurs géographiques et d'activités"}
            severity="success"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            multiple={false}
            handleChange={handleChange}
            name={"region"}
            label={"Régions"}
            value={values.region}
            theme={theme}
            dataSource={regions}
            helperText={touched.region && errors.region ? errors.region : null}
            error={touched.region && errors.region ? true : false}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            multiple={true}
            handleChange={handleChange}
            name={"category_ids"}
            label={"Secteurs d'activités"}
            value={values.category_ids}
            theme={theme}
            dataSource={categories}
            helperText={
              touched.category_ids && errors.category_ids
                ? errors.category_ids
                : null
            }
            error={touched.category_ids && errors.category_ids ? true : false}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <MessageField message={"Propriétaire"} severity="success" />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            label={"Propriétaire"}
            value={`${item.owner.gender == "male" ? "Monsieur" : "Madame"} ${
              item.owner.name
            } ${item.owner.lastname} ${item.owner.phone}`}
            readOnly={true}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            label={"Email"}
            value={item.owner.email}
            readOnly={true}
            fullWidth
          />
        </Grid>
        {canUpdateManager && (
          <Fragment>
            <Grid item xs={12} sm={12}>
              <MessageField
                message={"Changer propriétaire de l'entreprise"}
                severity="warning"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                value={values.owner_phone}
                onChange={handleChange}
                label="Changer de Propriétaire avec un nouveau téléphone"
                name="owner_phone"
                id="owner_phone"
                placeholder={phone_code}
                helperText={
                  touched.owner_phone && errors.owner_phone
                    ? errors.owner_phone
                    : null
                }
                error={touched.owner_phone && errors.owner_phone ? true : false}
                fullWidth={true}
              />
            </Grid>
          </Fragment>
        )}
        <Grid item xs={12} sm={12}>
          <MessageField message={"Tarification"} severity="success" />
        </Grid>

        <Grid item xs={12} sm={6}>
          <CurrencyTextField
            as={TextField}
            fullWidth
            value={values.subscription_price}
            name="subscription_price"
            id="subscription_price"
            variant="standard"
            // disabled={!values.show_price}
            label={`Prix en ${currency}`}
            onChange={async (_event, value) =>
              await setFieldValue("subscription_price", value)
            }
            helperText={
              touched.subscription_price && errors.subscription_price
                ? errors.subscription_price
                : null
            }
            error={
              touched.subscription_price && errors.subscription_price ? true : false
            }
            currencySymbol={currency}
          />
        </Grid>



        <Grid item xs={12} sm={6}>
          <CurrencyTextField
            as={TextField}
            fullWidth
            value={values.rv_price}
            name="rv_price"
            id="rv_price"
            variant="standard"
            // disabled={!values.show_price}
            label={`Commission sur rendez-vous en  ${currency}`}
            onChange={async (_event, value) =>
              await setFieldValue("rv_price", value)
            }
            helperText={
              touched.rv_price && errors.rv_price
                ? errors.rv_price
                : null
            }
            error={
              touched.rv_price && errors.rv_price ? true : false
            }
            currencySymbol={currency}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            inputProps={{ min: "0", step: "1" }}
            label="Nombre de Service(s)"
            value={values.subscription_services_number}
            name="subscription_services_number"
            id="subscription_services_number"
            onChange={handleChange}
            type="number"
            helperText={
              touched.subscription_services_number &&
              errors.subscription_services_number
                ? errors.subscription_services_number
                : null
            }
            error={
              touched.subscription_services_number &&
              errors.subscription_services_number
                ? true
                : false
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            inputProps={{ min: "0", step: "1" }}
            label="Nombre de Personnel"
            value={values.subscription_workers_number}
            name="subscription_workers_number"
            id="subscription_workers_number"
            onChange={handleChange}
            type="number"
            helperText={
              touched.subscription_workers_number &&
              errors.subscription_workers_number
                ? errors.subscription_workers_number
                : null
            }
            error={
              touched.subscription_workers_number &&
              errors.subscription_workers_number
                ? true
                : false
            }
          />
        </Grid>

        <div>
          <Button
            color="primary"
            //disabled={!formState.isValid}
            fullWidth
            size="large"
            type="submit"
            onClick={handleSubmit}
            variant="contained"
          >
            enregistrer
          </Button>
        </div>
      </Grid>
    </form>
  )
}
