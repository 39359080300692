import { ApolloClient } from "apollo-client"
import { InMemoryCache } from "apollo-cache-inmemory"
//import { setContext } from "apollo-link-context";
import { split, ApolloLink } from "apollo-link"
import { createUploadLink } from "apollo-upload-client"
import { onError } from "apollo-link-error"
import { getMainDefinition } from "apollo-utilities"
//import { TOKEN_NAME } from "../utils/static_constants";
import { typeDefs, resolvers } from "./store/resolvers"

//prod
//const graphqlUrl = `https://guetret.tk/graphql`;
//const graphqlUrl = `http://localhost:3001/graphql`;

const graphqlUrl = `https://server.doctosen.com/graphql`

const cache = new InMemoryCache()

const httpLink = createUploadLink({
  uri: graphqlUrl,
  credentials: "include",
})
const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors)
    graphQLErrors.map((error) => console.log("error graphql", error))
})

/* const authLink = setContext(async (_, { headers }) => {
  const token = await localStorage.getItem(TOKEN_NAME);
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
}); */
//127.0.0.1,173.249.22.229,173.249.28.166,173.249.58.31,194.163.155.85,164.68.110.67
const finaleLink = split(
  ({ query }) => {
    const { kind } = getMainDefinition(query)
    return kind === "OperationDefinition"
  },

  // authLink.concat(httpLink)
  httpLink
)
const client = new ApolloClient({
  connectToDevTools: true,
  link: ApolloLink.from([errorLink, finaleLink]),
  cache,
  typeDefs,
  resolvers,
})

cache.writeData({
  data: {
    currentUser: {
      authenticated: false,
      token: null,

      __typename: "currentUser",
    },
    smallMenu: {
      __typename: "smallMenu",
      smallMenu: false,
    },
  },
})
export default client
