import React, { useState } from "react"
import EditIcon from "@material-ui/icons/RemoveCircle"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { useQuery, useMutation } from "react-apollo"
import Loading from "components/Loading"
import { Grid, makeStyles } from "@material-ui/core"
import Pagination from "@material-ui/lab/Pagination"
import { Accordion } from "components/Accordion"
import { readNumber } from "utils/readnumber"
import { compSignalQuery } from "graphql/query/client_signal"
import { Fragment } from "react"
import { renderDate } from "utils/func"
import { deleteSignalMutation } from "graphql/mutation/comp_signal"
import client from "graphql/apollo"
import { toast } from "react-toastify"

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },

  statsIcon: {
    color: 'red',
    marginRight: theme.spacing(1),
  },
}))
const ClientSignalView = ({ company_id, time_zone }) => {
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [expanded1, setExpanded1] = useState(false)
  const [deleteSignal] = useMutation(deleteSignalMutation, {
    async update(
      _client,
      {
        data: {
          deleteSignal: { ok, id },
        },
      }
    ) {
      if (ok) {
        const variables = { page: page, company_id }
        const { compSignal } = await client.readQuery({
          query: compSignalQuery,
          variables,
        })

        let docs = compSignal.docs
        const index = compSignal.docs.findIndex(
          (d) => d.id.toString() === id.toString()
        )

        if (index > -1) {
          docs.splice(index, 1)
          await client.writeQuery({
            query: compSignalQuery,
            variables,
            data: {
              compSignal: {
                ...compSignal,

                docs,
              },
            },
          })
        }
      }
    },
  })
  const {
    loading: loading1,
    data,
    error,
    fetchMore,
  } = useQuery(compSignalQuery, {
    skip: company_id ? false : true,
    variables: { page, company_id },
  })

  const classes = useStyles()
  const clients = data && data.compSignal ? data.compSignal : null

  const onPageChange = async (page) => {
    try {
      await setLoading(true)
      await setPage(page)

      let variables = { page, company_id }

      fetchMore({
        variables,
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev

          const { compSignal } = fetchMoreResult
          return Object.assign({}, prev, {
            compSignal,
          })
        },
      })

      await setLoading(false)
    } catch (error) {
      throw error
    }
  }

  const remove = async (id) => {
    try {
      if (window.confirm("Etes vous sûr(e) de vouloir supprimer cette alerte")) {
        await setLoading(true)
        const res = await deleteSignal({ variables: { id } })
        const { ok, errors } = res.data.deleteSignal
        await setLoading(false)

        if (ok) {
          await toast.success("Alerte supprimmée")
        } else {
          window.alert(errors[0].message)
        }
      }
    } catch (error) {
      await setLoading(false)
      window.alert(error.message)
    }
  }
  return (
    <Accordion
      id={"search-client-form"}
      title={"Alertes des utilisateurs"}
      expanded={expanded1}
      setExpanded={setExpanded1}
    >
      <Grid container spacing={3}>
        {clients && (
          <Grid item xs={12} sm={12}>
            <Pagination
              count={clients.totalPages}
              page={page}
              onChange={async (_event, value) => {
                if (value.toString() !== page.toString()) {
                  await onPageChange(value)
                }
              }}
            />
          </Grid>
        )}

        {(loading1 || loading) && <Loading />}
        {!loading1 && !loading && clients && (
          <Fragment>
            <Grid item xs={12} sm={12} style={{ float: "right" }}>
              {readNumber(clients.totalDocs) + " alertes"}
            </Grid>
            <Grid item xs={12} sm={12}>
              <TableContainer>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Alertes des utilisateurs</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!loading &&
                      clients &&
                      clients.docs.map((evt, index) => (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>

                          <TableCell>{evt.author_name}</TableCell>

                          <TableCell>{evt.message}</TableCell>
                          {evt.service && evt.service.name && (
                            <TableCell>{evt.service.name}</TableCell>
                          )}

                          {!evt.service && <TableCell>Entreprise</TableCell>}

                          <TableCell>
                            {renderDate(evt.createdAt, time_zone)}
                          </TableCell>

                          <TableCell>
                            <EditIcon
                              className={classes.statsIcon}
                              onClick={async (e) => {
                                e.preventDefault()
                                await remove(evt.id)
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Fragment>
        )}
      </Grid>
    </Accordion>
  )
}
export default ClientSignalView
