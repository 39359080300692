import gql from "graphql-tag"

export const oneCompanyQuery = gql`
  query oneCompanyAdmin($company_id: String!) {
    oneCompanyAdmin(company_id: $company_id) {
      id
      name
      picture
      reference
      is_active
      description_address
      description
      region
      city
      lat
      lng
      created_by_senrv
      author {
        id
        name
        lastname
        gender
        phone
        email
      }
      senrvmanagers {
        id
        name
        lastname
        gender
        phone
        email
      }
      owner {
        id
        name
        lastname
        gender
        phone
        email
      }
      subscription {
        price
        services_number
        workers_number
        rv_price
      }
      rating {
        average
        ratecount
      }
      createdAt
      updatedAt
      categories {
        id
        name
      }
    }
  }
`
export const findCompaniesQuery = gql`
  query findCompaniesAM(
    $name: String
    $reference: String
    $senrv_manager_ids: [String]
    $regions: [String]
    $category_ids: [String]
    $page: Int
    $limit: Int
    $is_active: Boolean
  ) {
    findCompaniesAM(
      name: $name
      reference: $reference
      senrv_manager_ids: $senrv_manager_ids
      regions: $regions
      category_ids: $category_ids
      page: $page
      limit: $limit
      is_active: $is_active
    ) {
      totalDocs
      hasPrevPage
      totalPages
      limit
      offset
      page
      prevPage
      nextPage
      docs {
        id
        name
        is_active
        createdAt
        picture
        description_address
        reference
        region
        city
        categories {
          id
          name
        }
      }
    }
  }
`

export const findCompaniesCommercialQuery = gql`
  query findCompaniesAMCommercial(
    $name: String
    $reference: String
    $regions: [String]
    $category_ids: [String]
    $page: Int
    $limit: Int
    $is_active: Boolean
  ) {
    findCompaniesAMCommercial(
      name: $name
      reference: $reference

      regions: $regions
      category_ids: $category_ids
      page: $page
      limit: $limit
      is_active: $is_active
    ) {
      totalDocs
      hasPrevPage
      totalPages
      limit
      offset
      page
      prevPage
      nextPage
      docs {
        id
        name
        is_active
        createdAt
        picture
        description_address
        reference
        region
        city
        categories {
          id
          name
        }
      }
    }
  }
`
