import React, { useState } from "react"
import { useQuery, useMutation } from "react-apollo"
import * as Yup from "yup"
import { Formik } from "formik"
import { makeStyles } from "@material-ui/styles"
import Loading from "components/Loading"

import { toast } from "react-toastify"
import { JobCreate } from "./components/JobCreate"
import { JobEdit } from "./components/JobEdit"
import {
  createJobMutation,
  deleteJobMutation,
  updateJobMutation,
} from "graphql/mutation/job_add"
import { findJobsQuery } from "graphql/query/jobb_add"
import { ModalDialog } from "components/ModalDialog"
import { Grid } from "@material-ui/core"
import { Button } from "@material-ui/core"
import { JobList } from "./components/JobList"
import { oneSettingAdminQuery } from "graphql/query/setting_admin"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
}))
const createSchema = Yup.object().shape({
  title: Yup.string()
    .trim()
    .min(2, "minimun 2 caractères")
    .max(50, "maximum 50 caractères")
    .required("champ requis"),
  description: Yup.string().required("champ requis"),
})

const updateSchema = Yup.object().shape({
  title: Yup.string().nullable(),
  description: Yup.string().nullable(),
})

const JobaddView = () => {
  const classes = useStyles()

  const [editedItem, setEditedItem] = useState(undefined)
  const [editModal, setEditModal] = useState(false)
  const [createModal, setCreateModal] = useState(false)
  const [page, setPage] = useState(1)
  const {
    loading: loading1,
    data,
    fetchMore,
  } = useQuery(findJobsQuery, {
    variables: {
      page,
    },
  })

  const { data: settingAdminData, loading: loading2 } =
    useQuery(oneSettingAdminQuery)
  const [createItem] = useMutation(createJobMutation, {
    async update(
      cache,
      {
        data: {
          createJob: { ok, job },
        },
      }
    ) {
      if (ok) {
        const { findJobs } = await cache.readQuery({
          query: findJobsQuery,
          variables: { page },
        })

        const docs = findJobs.docs.unshift(job)
        await cache.writeQuery({
          query: findJobsQuery,
          variables: { page },
          data: {
            findJobs: {
              ...findJobs,
              totalDocs: findJobs.totalDocs + 1,
              docs,
            },
          },
        })
      }
    },
  })
  const [updateItem] = useMutation(updateJobMutation, {
    async update(
      cache,
      {
        data: {
          updateJob: { ok, job },
        },
      }
    ) {
      if (ok) {
        const { findJobs } = await cache.readQuery({
          query: findJobsQuery,
          variables: { page },
        })

        let docs = findJobs.docs
        const index = docs.findIndex((d) => d.id == job.id)

        if (index > -1) {
          docs[index] = job
          await cache.writeQuery({
            query: findJobsQuery,
            variables: { page },
            data: {
              findJobs: {
                ...findJobs,

                docs,
              },
            },
          })
        }
      }
    },
  })
  const [deleteItem] = useMutation(deleteJobMutation, {
    async update(
      cache,
      {
        data: {
          deleteJob: { ok, id },
        },
      }
    ) {
      if (ok) {
        const { findJobs } = await cache.readQuery({
          query: findJobsQuery,
          variables: { page },
        })

        let docs = findJobs.docs
        const index = docs.findIndex((d) => d.id == id)

        if (index > -1) {
          docs.splice(index, 1)
          await cache.writeQuery({
            query: findJobsQuery,
            variables: { page },
            data: {
              findJobs: {
                ...findJobs,
                totalDocs: findJobs.totalDocs - 1,
                docs,
              },
            },
          })
        }
      }
    },
  })

  const onPageChange = async (page) => {
    try {
      await setPage(page)
      let variables = { page }

      fetchMore({
        variables,
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev

          const { findJobs } = fetchMoreResult
          return Object.assign({}, prev, {
            findJobs,
          })
        },
      })
    } catch (error) {
      window.alert(error.message)
    }
  }
  const createInitialValues = {
    title: "",
    description: "",
  }

  const create = async (
    values,

    { setSubmitting, setFieldError }
  ) => {
    try {
      const variables = {
        ...values,
      }

      const res = await createItem({
        variables,
      })

      const { ok, errors } = res.data.createJob
      if (ok) {
        await setCreateModal(false)
        await toast.success("Offre ajoutée  avec succès")
      } else {
        errors.forEach(async (error) => {
          if (error.path && error.path.toString() === "global") {
            window.alert(error.message)
          } else {
            await setFieldError(error.path, error.message)
            window.alert(error.message)
          }
        })
      }
    } catch (error) {
      window.alert(error.message)
    } finally {
      await setSubmitting(false)
    }
  }

  const update = async (
    { title, description },

    { setSubmitting, setFieldError }
  ) => {
    try {
      let variables = {}

      if (title && title != editedItem.title) {
        variables.title = title
      }

      if (description && description != editedItem.description) {
        variables.description = description
      }
      if (Object.entries(variables).length === 0) {
        return
      }
      const res = await updateItem({
        variables: { ...variables, id: editedItem.id },
      })

      const { ok, errors } = res.data.updateJob
      if (ok) {
        await setEditModal(false)
        await setEditedItem(null)
        await toast.success("Offre mise à jour  avec succès")
      } else {
        errors.forEach(async (error) => {
          if (error.path && error.path.toString() === "global") {
            window.alert(error.message)
          } else {
            await setFieldError(error.path, error.message)
            window.alert(error.message)
          }
        })
      }
    } catch (error) {
      window.alert(error.message)
    } finally {
      await setSubmitting(false)
    }
  }

  const selectForUpdate = async (item) => {
    try {
      await setEditedItem(item)
      await setEditModal(true)
    } catch (error) {
      window.alert(error.message)
    }
  }

  const selectForDelete = async (id) => {
    try {
      if (window.confirm("Etes vous sûr(e) de vouloir supprimer")) {
        await deleteItem({ variables: { id } })
      }
    } catch (error) {
      window.alert(error.message)
    }
  }

  const time_zone =
    settingAdminData &&
    settingAdminData.oneSettingAdmin &&
    settingAdminData.oneSettingAdmin.time_zone
      ? settingAdminData.oneSettingAdmin.time_zone
      : null
  if (loading1 || loading2) {
    return <Loading />
  }
  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ padding: 20 }}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={async () => await setCreateModal(true)}
          style={{ float: "right" }}
        >
          Nouvelle offre d'emploi
        </Button>
      </Grid>
      <Formik
        initialValues={createInitialValues}
        validationSchema={createSchema}
        onSubmit={create}
      >
        {(props) => (
          <ModalDialog
            open={createModal}
            cancel={async () => {
              await props.handleReset()
              await setCreateModal(false)
            }}
            title={"Ajout offre d'emploi"}
          >
            <JobCreate {...props} />
          </ModalDialog>
        )}
      </Formik>

      {editModal && (
        <Formik
          initialValues={{
            title: editedItem && editedItem.title ? editedItem.title : "",
            description:
              editedItem && editedItem.description ? editedItem.description : "",
          }}
          validationSchema={updateSchema}
          onSubmit={update}
        >
          {(props) => (
            <ModalDialog
              open={editModal}
              cancel={async () => {
                await setEditModal(false)
                await setEditedItem(null)

                await props.handleReset()
              }}
              title={"Edition offre d'emploi"}
            >
              <JobEdit {...props} item={editedItem ? editedItem : null} />
            </ModalDialog>
          )}
        </Formik>
      )}

      <JobList
        time_zone={time_zone}
        data={data && data.findJobs ? data.findJobs : null}
        docs={
          data && data.findJobs && data.findJobs.docs ? data.findJobs.docs : null
        }
        page={page}
        totalPages={
          data && data.findJobs && data.findJobs.totalPages
            ? data.findJobs.totalPages
            : null
        }
        onPageChange={onPageChange}
        selectForUpdate={selectForUpdate}
        selectForRemove={selectForDelete}
      />
    </div>
  )
}

export default JobaddView
