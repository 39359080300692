/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from "react"
import { NavLink as RouterLink } from "react-router-dom"
import NotificationIcon from "@material-ui/icons/NotificationImportant"
import clsx from "clsx"
import { makeStyles } from "@material-ui/styles"
import { ToastContainer } from "react-toastify"
import { List, ListItem, Button, colors, Badge } from "@material-ui/core"
import { NOTIF_PATH } from "utils/static_constants"
import { useQuery } from "react-apollo"
import { userCompNofifsQuery } from "graphql/query/notif"
import { currentUserQuery } from "graphql/query/user"

import Loading from "components/Loading"

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: colors.blueGrey[800],
    padding: "10px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontWeight: theme.typography.fontWeightMedium,
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1),
  },
  active: {
    color: theme.palette.error.main,
    fontWeight: theme.typography.fontWeightMedium,
    "& $icon": {
      color: theme.palette.error.main,
    },
  },
}))

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
))

const SidebarNav = (props) => {
  const { pages, className, ...rest } = props

  const { loading: loading1, data: notifData } = useQuery(userCompNofifsQuery, {
    variables: {},
    pollInterval: 100000,
  })

  const { data: currentUserData, loading: loading2 } = useQuery(currentUserQuery)

  const classes = useStyles()

  const notifNumber =
    notifData &&
    notifData.userAdminNotifs &&
    notifData.userAdminNotifs.length &&
    notifData.userAdminNotifs.filter((n) => !n.seen).length > 0
      ? notifData.userAdminNotifs.filter((n) => !n.seen).length
      : 0

  if (loading2 || loading1) {
    return <Loading />
  }

  return (
    <List {...rest} className={clsx(classes.root, className)}>
      <ListItem className={classes.item} disableGutters key={"Notification"}>
        <Button
          activeClassName={classes.active}
          className={classes.button}
          component={CustomRouterLink}
          to={NOTIF_PATH}
        >
          <Badge
            badgeContent={notifNumber}
            color="error"
            style={{ padding: 5 }}
            invisible={notifNumber == 0}
          >
            <div className={classes.icon}>
              <NotificationIcon />
            </div>

            {"Notification"}
          </Badge>
        </Button>
      </ListItem>

      {pages.map((page) => (
        <ListItem className={classes.item} disableGutters key={page.title}>
          <Button
            activeClassName={classes.active}
            className={classes.button}
            component={CustomRouterLink}
            to={page.href}
          >
            <div className={classes.icon}>{page.icon}</div>
            {page.title}
          </Button>
        </ListItem>
      ))}

      <ToastContainer
        autoClose={false}
        style={{
          marginTop: 70,
          padding: 30,
          fontSize: 12,
          fontStyle: "italic",
          width: "30%",
        }}
      />
    </List>
  )
}

export default SidebarNav
