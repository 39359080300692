import React from "react"
import { useQuery } from "react-apollo"
import { makeStyles } from "@material-ui/styles"
import Loading from "components/Loading"
import { SettingToolbar } from "./components/SettingToolbar"
import { oneSiteSettingAdminQuery } from "graphql/query/site_setting"
import { Setting } from "./components/Setting"
import {
  SITE_SETTING_CREATE_PATH,
  SITE_SETTING_EDIT_PATH,
} from "utils/static_constants"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}))

const SettingView = ({ history }) => {
  const classes = useStyles()

  const { loading, data } = useQuery(oneSiteSettingAdminQuery)

  if (loading) {
    return <Loading />
  }

  const isSetting =
    data && data.oneSiteSettingAdmin && data.oneSiteSettingAdmin.id
      ? data.oneSiteSettingAdmin
      : null

  return (
    <div className={classes.root}>
      <SettingToolbar
        show={
          !data || !data.oneSettingAdmin || !data.oneSettingAdmin.id ? true : false
        }
        title={isSetting ? "Modifier" : "Paramétrer"}
        openModal={async () => {
          if (isSetting) {
            await history.push(SITE_SETTING_EDIT_PATH)
          } else {
            await history.push(SITE_SETTING_CREATE_PATH)
          }
        }}
      />

      <div className={classes.content}>
        <Setting item={isSetting} />
      </div>
    </div>
  )
}

export default SettingView
