import gql from "graphql-tag"

export const userCompNofifsQuery = gql`
  query userAdminNotifs {
    userAdminNotifs {
      id
      title
      description
      seen
      author_id
      notif_type
      notif_type_id
      createdAt
      updatedAt
    }
  }
`
