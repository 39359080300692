import React, { useState } from "react"
import { Button, TextField, Grid, Input } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Loading from "components/Loading"
import { MessageField } from "components/MessageField"
import VideoYoutube from "components/VideoYoutube"
import { Accordion } from "components/Accordion"
import "../../../assets/scss/default.scss"

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: "100%",
  },
  chip: {
    margin: theme.spacing(0.5),
  },

  SetingIframe: {
    width: "100%",
    marginTop: 15,
  },
}))

export const SettingEdit = ({
  values,
  touched,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  globalError,
  picture,
}) => {
  const classes = useStyles()

  const [expanded1, setExpanded1] = useState(false)
  const [expanded2, setExpanded2] = useState(false)
  const [expanded3, setExpanded3] = useState(false)
  const [expanded4, setExpanded4] = useState(false)
  const [expanded5, setExpanded5] = useState(false)
  const [expanded6, setExpanded6] = useState(false)
  const [expanded7, setExpanded7] = useState(false)
  const [expanded8, setExpanded8] = useState(false)
  const [expanded9, setExpanded9] = useState(false)
  const [expanded10, setExpanded10] = useState(false)
  const [expanded11, setExpanded11] = useState(false)
  const [expanded12, setExpanded12] = useState(false)
  const [expanded13, setExpanded13] = useState(false)
  const [expanded14, setExpanded14] = useState(false)
  const [expanded15, setExpanded15] = useState(false)

  const split_youtube_link = values.youtube_link
    ? values.youtube_link.split("/")
    : []

  const split_youtube_link_comp = values.youtube_link_comp
    ? values.youtube_link_comp.split("/")
    : []

  const youtube_link_l1 = split_youtube_link[split_youtube_link.length - 1]
  const youtube_link_l2 = split_youtube_link_comp[split_youtube_link_comp.length - 1]

  if (isSubmitting) {
    return <Loading />
  }
  return (
    <form
      className={classes.root}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      
      <Grid container spacing={0}>
        {globalError && <MessageField message={globalError} severity={"error"} />}
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <label htmlFor="file">
            <Button
              style={{ borderRadius: "100px",marginLeft:'12px'}}
              variant="contained"
              component="span"
              color="secondary"
            >
              Changer de photo
            </Button>
          </label>
        </Grid>
        
          {/* {picture && (
            <Grid xs={12}>
              <img
                style={{
                  marginBottom: 20,
                  width: "100%",
                  maxWidth: "100%",
                  height: 300,
                }}
                src={picture}
                alt={"main-image-application-senrv"}
              />
            </Grid>
          )} */}
        
        <Grid item lg={5} md={5} sm={6} xs={12}>
          <Input
            fullWidth
            type="file"
            name="file"
            id="file"
            onChange={async ({
              target: {
                files: [file],
              },
            }) => {
              await setFieldValue("file", file)
            }}
            style={{ display: "none" }}
          />

          {values.file && (
            <>
              <br />
              <img
                src={URL.createObjectURL(values.file)}
                alt="service-edit"
                style={{ width: "100%", Minheight: 200, marginLeft:'12px' }}
              />
            </>
          )}
          <br />
          {touched.file && errors.file && (
            <span style={{ color: "red" }}>{errors.file}</span>
          )}
        </Grid>

        <Grid item xs={12} sm={12}>
          <Accordion
            id={"client"}
            title={"Application Utilisateur"}
            expanded={expanded13}
            setExpanded={setExpanded13}
          >
            <div style={{ width: "90%" }}>
              <TextField
                id="client_web_link"
                name="client_web_link"
                value={values.client_web_link}
                required
                label={"Site web"}
                onChange={handleChange}
                fullWidth
                helperText={
                  touched.client_web_link && errors.client_web_link
                    ? errors.client_web_link
                    : null
                }
                error={
                  touched.client_web_link && errors.client_web_link ? true : false
                }
              />
              <br />
              <TextField
                id="client_playstore_link"
                name="client_playstore_link"
                value={values.client_playstore_link}
                required
                label={"Lien Playstore"}
                onChange={handleChange}
                fullWidth
                helperText={
                  touched.client_playstore_link && errors.client_playstore_link
                    ? errors.client_playstore_link
                    : null
                }
                error={
                  touched.client_playstore_link && errors.client_playstore_link
                    ? true
                    : false
                }
              />
              <br />
              <TextField
                id="client_appletore_link"
                name="client_appletore_link"
                value={values.client_appletore_link}
                required
                label={"Lien AppleStore"}
                onChange={handleChange}
                fullWidth
                helperText={
                  touched.client_appletore_link && errors.client_appletore_link
                    ? errors.client_appletore_link
                    : null
                }
                error={
                  touched.client_appletore_link && errors.client_appletore_link
                    ? true
                    : false
                }
              />
              <br />
            </div>
          </Accordion>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Accordion
            id={"saler"}
            title={"Application Entreprise"}
            expanded={expanded14}
            setExpanded={setExpanded14}
          >
            <div style={{ width: "90%" }}>
              <TextField
                id="saler_web_link"
                name="saler_web_link"
                value={values.saler_web_link}
                required
                label={"Site web"}
                onChange={handleChange}
                fullWidth
                helperText={
                  touched.saler_web_link && errors.saler_web_link
                    ? errors.saler_web_link
                    : null
                }
                error={
                  touched.saler_web_link && errors.saler_web_link ? true : false
                }
              />
              <br />
              <TextField
                id="saler_playstore_link"
                name="saler_playstore_link"
                value={values.saler_playstore_link}
                required
                label={"Lien Playstore"}
                onChange={handleChange}
                fullWidth
                helperText={
                  touched.saler_playstore_link && errors.saler_playstore_link
                    ? errors.saler_playstore_link
                    : null
                }
                error={
                  touched.saler_playstore_link && errors.saler_playstore_link
                    ? true
                    : false
                }
              />
              <br />
              <TextField
                id="saler_appletore_link"
                name="saler_appletore_link"
                value={values.saler_appletore_link}
                required
                label={"Lien AppleStore"}
                onChange={handleChange}
                fullWidth
                helperText={
                  touched.saler_appletore_link && errors.saler_appletore_link
                    ? errors.saler_appletore_link
                    : null
                }
                error={
                  touched.saler_appletore_link && errors.saler_appletore_link
                    ? true
                    : false
                }
              />
              <br />
            </div>
          </Accordion>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Accordion
            id={"saler"}
            title={"Application Entreprise Worker"}
            expanded={expanded15}
            setExpanded={setExpanded15}
          >
            <div style={{ width: "90%" }}>
              <TextField
                id="worker_playstore_link"
                name="worker_playstore_link"
                value={values.worker_playstore_link}
                required
                label={"Lien Playstore"}
                onChange={handleChange}
                fullWidth
                helperText={
                  touched.worker_playstore_link && errors.worker_playstore_link
                    ? errors.worker_playstore_link
                    : null
                }
                error={
                  touched.worker_playstore_link && errors.worker_playstore_link
                    ? true
                    : false
                }
              />
              <br />
              <TextField
                id="worker_appletore_link"
                name="worker_appletore_link"
                value={values.worker_appletore_link}
                required
                label={"Lien AppleStore"}
                onChange={handleChange}
                fullWidth
                helperText={
                  touched.worker_appletore_link && errors.worker_appletore_link
                    ? errors.worker_appletore_link
                    : null
                }
                error={
                  touched.worker_appletore_link && errors.worker_appletore_link
                    ? true
                    : false
                }
              />
              <br />
            </div>
          </Accordion>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Accordion
            id={"phone"}
            title={"Téléphone Portable"}
            expanded={expanded1}
            setExpanded={setExpanded1}
          >
            <TextField
              id="phone"
              name="phone"
              value={values.phone}
              required
              label="Téléphone"
              onChange={handleChange}
              fullWidth
              helperText={touched.phone && errors.phone ? errors.phone : null}
              error={touched.phone && errors.phone ? true : false}
            />
          </Accordion>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Accordion
            id={"phone_fix"}
            title={"Téléphone Fixe"}
            expanded={expanded2}
            setExpanded={setExpanded2}
          >
            <TextField
              id="phone_fix"
              name="phone_fix"
              value={values.phone_fix}
              required
              label="Téléphone fixe"
              onChange={handleChange}
              fullWidth
              helperText={
                touched.phone_fix && errors.phone_fix ? errors.phone_fix : null
              }
              error={touched.phone_fix && errors.phone_fix ? true : false}
            />
          </Accordion>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Accordion
            id={"email"}
            title={"Adresse email"}
            expanded={expanded3}
            setExpanded={setExpanded3}
          >
            <TextField
              id="email"
              name="email"
              value={values.email}
              required
              label="Email"
              onChange={handleChange}
              fullWidth
              helperText={touched.email && errors.email ? errors.email : null}
              error={touched.email && errors.email ? true : false}
            />
          </Accordion>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Accordion
            id={"facebook_page"}
            title={"Page Facebook"}
            expanded={expanded4}
            setExpanded={setExpanded4}
          >
            <TextField
              id="facebook_page"
              name="facebook_page"
              value={values.facebook_page}
              required
              label={"Page Facebook"}
              onChange={handleChange}
              fullWidth
              helperText={
                touched.facebook_page && errors.facebook_page
                  ? errors.facebook_page
                  : null
              }
              error={touched.facebook_page && errors.facebook_page ? true : false}
            />
          </Accordion>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Accordion
            id={"twitter_page"}
            title={"Page Twitter"}
            expanded={expanded5}
            setExpanded={setExpanded5}
          >
            <TextField
              id="twitter_page"
              name="twitter_page"
              value={values.twitter_page}
              required
              label={"Page Twitter"}
              onChange={handleChange}
              fullWidth
              helperText={
                touched.twitter_page && errors.twitter_page
                  ? errors.twitter_page
                  : null
              }
              error={touched.twitter_page && errors.twitter_page ? true : false}
            />
          </Accordion>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Accordion
            id={"instagram_page"}
            title={"Page Instagram"}
            expanded={expanded11}
            setExpanded={setExpanded11}
          >
            <TextField
              id="instagram_page"
              name="instagram_page"
              value={values.instagram_page}
              required
              label={"Page Instagram"}
              onChange={handleChange}
              fullWidth
              helperText={
                touched.instagram_page && errors.instagram_page
                  ? errors.instagram_page
                  : null
              }
              error={touched.instagram_page && errors.instagram_page ? true : false}
            />
          </Accordion>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Accordion
            id={"linkedin_page"}
            title={"Page Linkedin"}
            expanded={expanded12}
            setExpanded={setExpanded12}
          >
            <TextField
              id="linkedin_page"
              name="linkedin_page"
              value={values.linkedin_page}
              required
              label={"Page Linkedin"}
              onChange={handleChange}
              fullWidth
              helperText={
                touched.linkedin_page && errors.linkedin_page
                  ? errors.linkedin_page
                  : null
              }
              error={touched.linkedin_page && errors.linkedin_page ? true : false}
            />
          </Accordion>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Accordion
            title={"Page Youtube"}
            id={"youtube_page"}
            expanded={expanded5}
            setExpanded={setExpanded6}
          >
            <TextField
              id="youtube_page"
              name="youtube_page"
              value={values.youtube_page}
              required
              label={"Page Youtube"}
              onChange={handleChange}
              fullWidth
              helperText={
                touched.youtube_page && errors.youtube_page
                  ? errors.youtube_page
                  : null
              }
              error={touched.youtube_page && errors.youtube_page ? true : false}
            />
          </Accordion>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Accordion
            id={"youtube_link_comp"}
            title={"Video Youtube Pour les entreprise"}
            expanded={expanded7}
            setExpanded={setExpanded7}
          >
            <TextField
              id="youtube_link_comp"
              name="youtube_link_comp"
              value={values.youtube_link_comp}
              required
              label={"Video Youtube Entreprise"}
              onChange={handleChange}
              fullWidth
              helperText={
                touched.youtube_link_comp && errors.youtube_link_comp
                  ? errors.youtube_link_comp
                  : null
              }
              error={
                touched.youtube_link_comp && errors.youtube_link_comp ? true : false
              }
            />

            {values.youtube_link_comp && youtube_link_l2 ? (
              <Grid className={classes.SetingIframe}>
                <VideoYoutube id={youtube_link_l2} />
              </Grid>
            ) : null}
          </Accordion>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Accordion
            title={"Video Youtube Pour les utilisateurs"}
            id={"youtube_link_"}
            expanded={expanded8}
            setExpanded={setExpanded8}
          >
            <TextField
              id="youtube_link"
              name="youtube_link"
              value={values.youtube_link}
              required
              label={"Page Youtube utilisateurs"}
              onChange={handleChange}
              fullWidth
              helperText={
                touched.youtube_link && errors.youtube_link
                  ? errors.youtube_link
                  : null
              }
              error={touched.youtube_link && errors.youtube_link ? true : false}
            />

            {values.youtube_link && youtube_link_l1 ? (
              <Grid className={classes.SetingIframe}>
                <VideoYoutube id={youtube_link_l1} />
              </Grid>
            ) : null}
          </Accordion>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Accordion
            title={"Cgu utilisateurs"}
            id={"cgu"}
            expanded={expanded9}
            setExpanded={setExpanded9}
          >
            <TextField
              id="cgu"
              name="cgu"
              value={values.cgu}
              required
              multiline={true}
              label={"Cgu utilisateurs"}
              onChange={handleChange}
              fullWidth
              helperText={touched.cgu && errors.cgu ? errors.cgu : null}
              error={touched.cgu && errors.cgu ? true : false}
            />
          </Accordion>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Accordion
            title={"Cgu entreprise"}
            id={"cgu"}
            expanded={expanded10}
            setExpanded={setExpanded10}
          >
            <TextField
              id="cgu_comp"
              name="cgu_comp"
              multiline={true}
              value={values.cgu_comp}
              required
              label={"Cgus entreprise"}
              onChange={handleChange}
              fullWidth
              helperText={
                touched.cgu_comp && errors.cgu_comp ? errors.cgu_comp : null
              }
              error={touched.cgu_comp && errors.cgu_comp ? true : false}
            />
          </Accordion>
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Button
            color="primary"
            //disabled={!formState.isValid}
            style={{marginLeft:'12px'}}
            type="submit"
            variant="contained"
          >
            Enregistrer
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}
