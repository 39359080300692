import React from "react"
import MuiPhoneInput from "material-ui-phone-number"
import { TextField, InputLabel } from "@material-ui/core"

export const PhoneField = ({
  value,
  setFieldValue,
  error,
  label,
  name,
  helperText,
}) => {
  return (
    <React.Fragment>
      <InputLabel id={name} required>
        {label}
      </InputLabel>
      <MuiPhoneInput
        defaultCountry="sn"
        onlyCountries={["sn"]}
        region={"africa"}
        as={TextField}
        //  className={classes.textField}
        error={error}
        fullWidth
        helperText={helperText}
        //label={label}
        name={name}
        onChange={async (phone) => setFieldValue("phone", phone)}
        type="text"
        value={value}
        variant="outlined"
      />
    </React.Fragment>
  )
}
