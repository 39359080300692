import gql from "graphql-tag"

/* export const createSiteSettingAdminMutation = gql`
  mutation createSiteSettingAdmin(
    $cgu: String
    $phone: String
    $email: String
    $facebook_page: String
    $twitter_page: String
    $linkedin_page: String
    $youtube_link: String
  ) {
    createSiteSettingAdmin(
      cgu: $cgu
      phone: $phone
      email: $email
      facebook_page: $facebook_page
      twitter_page: $twitter_page
      linkedin_page: $linkedin_page
      youtube_link: $youtube_link
    ) {
      ok
      errors {
        path
        message
      }
      setting {
        id
        cgu
        phone
        email
        facebook_page
        twitter_page
        linkedin_page
        youtube_link
        createdAt
        updatedAt
      }
    }
  }
` */

export const updateSiteSettingAdminMutation = gql`
  mutation updateSiteSettingAdmin(
    $id: String!
    $setting: SiteSettingSettingAdminInput
  ) {
    updateSiteSettingAdmin(id: $id, setting: $setting) {
      ok
      errors {
        path
        message
      }
      setting {
        id
        cgu
        cgu_comp
        phone
        phone_fix
        email
        facebook_page
        twitter_page
        instagram_page
        linkedin_page
        youtube_page
        youtube_link
        youtube_link_comp
        saler_playstore_link
        saler_appletore_link
        saler_web_link
        worker_playstore_link
        worker_appletore_link
        client_playstore_link
        client_appletore_link
        client_web_link
        picture
        createdAt
        updatedAt
      }
    }
  }
`
