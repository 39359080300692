import gql from "graphql-tag"

export const createJobMutation = gql`
  mutation createJob($title: String, $description: String, $region: String) {
    createJob(title: $title, description: $description, region: $region) {
      ok
      job {
        id
        title
        description
        createdAt
        updatedAt
        region
        author {
          id
          gender
          name
          lastname
          phone
        }
      }
      errors {
        path
        message
      }
    }
  }
`

export const updateJobMutation = gql`
  mutation updateJob($id: String!, $title: String, $description: String) {
    updateJob(id: $id, title: $title, description: $description) {
      ok
      job {
        id
        title
        description
        createdAt
        updatedAt
        region
        author {
          id
          gender
          name
          lastname
          phone
        }
      }
      errors {
        path
        message
      }
    }
  }
`

export const deleteJobMutation = gql`
  mutation deleteJob($id: String!) {
    deleteJob(id: $id) {
      ok
      id
      errors {
        path
        message
      }
    }
  }
`
