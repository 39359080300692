import React from "react"
import { TableCell, TableRow } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { Link } from "react-router-dom"
import { ONE_COMPANY_FACTURE_PATH, ONE_COMPANY_PATH } from "utils/static_constants"
import moment from "moment-timezone"
import EditIcon from "@material-ui/icons/Edit"

const useStyles = makeStyles((theme) => ({
  statsIcon: {
    color: theme.palette.icon,
    marginRight: theme.spacing(1),
  },
}))
export const Bill = (props) => {
  const { item, selectForUpdate } = props
  const classes = useStyles()

  return (
    <TableRow key={item.id}>
      <TableCell>
        <Link to={`${ONE_COMPANY_PATH}/${item.company.id}`}>
          {item.company.name}
        </Link>
      </TableCell>
      <TableCell style={{ color: item.company.is_active ? "green" : "red" }}>
        {item.company.is_active ? "Actif" : "Compte bloqué"}
      </TableCell>
      <TableCell style={{ color: item.bill_paid ? "green" : "red" }}>
        {item.bill_paid ? "facture payée" : "non payée"}
      </TableCell>
      <TableCell>{item.final_bill}</TableCell>

      <TableCell style={{ fontSize: 12 }}>{item.phone}</TableCell>
      <TableCell>{item.subscription_price}</TableCell>

      <TableCell>{item.total_sms_price}</TableCell>

      <TableCell style={{ fontSize: 11 }}>
        {moment(new Date(parseInt(item.from_date))).format("MMMM-YYYY")}
        <br />
      </TableCell>

      <TableCell>
        <EditIcon
          className={classes.statsIcon}
          onClick={async (e) => {
            e.preventDefault()
            await selectForUpdate(item)
          }}
        />
      </TableCell>

      <TableCell>
        <Link to={`${ONE_COMPANY_FACTURE_PATH}/${item.company.id}/${item.id}`}>
          {"Détails"}
        </Link>
      </TableCell>
    </TableRow>
  )
}
