import gql from 'graphql-tag'

export const findEventsAdminQuery = gql`
  query findEventsAdmin(
    $page: Int!
    $event_number: String
    $reference: String
    $from_date: String
    $to_date: String
    $author_phone: String
    $category_ids: [String]
    $canceled: Boolean
  ) {
    findEventsAdmin(
      page: $page
      event_number: $event_number
      reference: $reference
      from_date: $from_date
      to_date: $to_date
      author_phone: $author_phone
      category_ids: $category_ids
      canceled: $canceled
    ) {
      totalDocs
      hasPrevPage
      totalPages
      limit
      offset
      page
      prevPage
      nextPage
      docs {
        id
        title
        start
        end
        event_number
        finished
        canceled
        canceled_by_author
        cancel_date
        canceled_by_company
        canceled_by_company_author_name
        created_by_company
        created_by_company_author_name
        signaled_by_company_author_name
        event_signaled
        author_presence
        motif_cancel
        createdAt
        updatedAt
        author {
          id
          gender
          name
          lastname
          fullname
          phone
        }
        company {
          id
          name
          region
        }
        workers {
          id
          name
          lastname
          fullname
          phone
        }
        service {
          id
          name

          picture
          category {
            id
            name
          }
        }
      }
    }
  }
`

export const findEventsBillQuery = gql`
  query findEventsBill($page: Int!, $limit: Int, $bill_id: String!) {
    findEventsBill(page: $page, limit: $limit, bill_id: $bill_id) {
      totalDocs
      hasPrevPage
      totalPages
      limit
      offset
      page
      prevPage
      nextPage
      hasNextPage
      docs {
        id
        title
        start
        end
        event_number

        createdAt
        updatedAt
        author {
          id
          name
          lastname
          fullname
          gender
          phone
        }

        service {
          id
          name
        }
      }
    }
  }
`

export const notifyEventQuery = gql`
  query notifyEvent {
    notifyEvent {
      ok
      errors {
        path
        message
      }
    }
  }
`
