import gql from "graphql-tag"

export const oneSettingAdminQuery = gql`
  query oneSettingAdmin {
    oneSettingAdmin {
      id
      country
      regions
      currency
      sms_price
      phone_code
      time_zone
      createdAt
      updatedAt
      phone_min_size
      phone_max_size
      minimal_cancel
    }
  }
`
