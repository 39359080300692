import React, { useState } from "react"
import { useQuery, useMutation } from "react-apollo"

import { makeStyles } from "@material-ui/styles"
import Loading from "components/Loading"
import { toast } from "react-toastify"
import { JobEdit } from "./components/JobEdit"
import { findContactsQuery } from "graphql/query/contact_message"
import { ModalDialog } from "components/ModalDialog"
import { JobList } from "./components/JobList"
import { oneSettingAdminQuery } from "graphql/query/setting_admin"
import { deleteContactMutation } from "graphql/mutation/contact_message"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '15px 35px'
  },
  // content: {
  //   marginTop: theme.spacing(2),
  // },
  pagination: {
    marginTop: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
}))

const ContactMessageView = () => {
  const classes = useStyles()

  const [editedItem, setEditedItem] = useState(undefined)
  const [editModal, setEditModal] = useState(false)

  const [page, setPage] = useState(1)
  const {
    loading: loading1,
    data,
    fetchMore,
    
  } = useQuery(findContactsQuery, {
    variables: {
      page,
    },
     
  })
 
  const { data: settingAdminData, loading: loading2 } =
    useQuery(oneSettingAdminQuery)

  const [deleteItem] = useMutation(deleteContactMutation, {
    async update(
      cache,
      {
        data: {
          deleteContact: { ok, id },
        },
      }
    ) {
      if (ok) {
        const { findContacts } = await cache.readQuery({
          query: findContactsQuery,
          variables: { page },
        })

        let docs = findContacts.docs
        const index = docs.findIndex((d) => d.id == id)

        if (index > -1) {
          docs.splice(index, 1)
          await cache.writeQuery({
            query: findContactsQuery,
            variables: { page },
            data: {
              findContacts: {
                ...findContacts,
                totalDocs: findContacts.totalDocs - 1,
                docs,
              },
            },
          })
        }
      }
    },
  })

  const onPageChange = async (page) => {
    try {
      await setPage(page)
      let variables = { page }

      fetchMore({
        variables,
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev

          const { findContacts } = fetchMoreResult
          return Object.assign({}, prev, {
            findContacts,
          })
        },
      })
    } catch (error) {
      window.alert(error.message)
    }
  }

  const selectForUpdate = async (item) => {
    try {
      await setEditedItem(item)
      await setEditModal(true)
    } catch (error) {
      window.alert(error.message)
    }
  }

  const selectForDelete = async (id) => {
    try {
      if (window.confirm("Etes vous sûr(e) de vouloir supprimer")) {
        await deleteItem({ variables: { id } })
      }
    } catch (error) {
      window.alert(error.message)
    }
  }

  const time_zone =
    settingAdminData &&
    settingAdminData.oneSettingAdmin &&
    settingAdminData.oneSettingAdmin.time_zone
      ? settingAdminData.oneSettingAdmin.time_zone
      : null
  if (loading1 || loading2) {
    return <Loading />
  }
  return (
    <div className={classes.root}>
      {editModal && (
        <ModalDialog
          open={editModal}
          cancel={async () => {
            await setEditModal(false)
            await setEditedItem(null)
          }}
          title={"Message de contact"}
        >
          <JobEdit item={editedItem ? editedItem : null} />
        </ModalDialog>
      )}

      <JobList
        time_zone={time_zone}
        data={data && data.findContacts ? data.findContacts : null}
        docs={
          data && data.findContacts && data.findContacts.docs
            ? data.findContacts.docs
            : null
        }
        page={page}
        totalPages={
          data && data.findContacts && data.findContacts.totalPages
            ? data.findContacts.totalPages
            : null
        }
        onPageChange={onPageChange}
        selectForUpdate={selectForUpdate}
        selectForRemove={selectForDelete}
      />
    </div>
  )
}

export default ContactMessageView
