import React from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import { makeStyles } from "@material-ui/styles"
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Grid,
  Divider,
  CardMedia,
  IconButton,
} from "@material-ui/core"
import RemoveIcon from "@material-ui/icons/Delete"
import VisibilityIcon from "@material-ui/icons/Visibility"
import EditIcon from "@material-ui/icons/Edit"

import { DisplayDate } from "components/DisplayDate"
import { renderDate } from "utils/func"

const useStyles = makeStyles((theme) => ({
  root: {},
  imageContainer: {
    height: 180,
    width: "100%",
    margin: "0 auto",
    border: `1px solid ${theme.palette.divider}`,
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    width: "100%",
  },
  statsItem: {
    display: "flex",
    alignItems: "center",
  },
  statsIcon: {
    color: theme.palette.icon,
    // marginRight: theme.spacing(1),
  },
}))

export const Category = (props) => {
  const {
    className,
    category,
    selectCategory,
    selectCatToDelete,
    time_zone,
    canAdd,
    ...rest
  } = props

  const classes = useStyles()

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardMedia image={category.picture} className={classes.imageContainer} />
      <CardContent>
        {/* <div className={classes.imageContainer}>
          <img alt="Product" className={classes.image} src={category.picture} />
        </div> */}
        <Typography align="center" gutterBottom variant="h4">
          {category.name}
        </Typography>
        <Typography align="center" gutterBottom variant="h4">
          {category.priority}
        </Typography>
        <Typography align="center" variant="body2" lines={3}>
          {renderDate(category.createdAt, time_zone)}
        </Typography>

        <Typography align="center" variant="body1" lines={3}>
          <span style={{ color: category.is_active ? "green" : "red" }}>
            {category.is_active ? "Actif" : "Inactif"}
          </span>
        </Typography>
      </CardContent>
      <Divider />
      <CardActions>
        <Grid container justify="space-between">
          {canAdd && (
            <Grid className={classes.statsItem} item>
              <IconButton
                onClick={async (e) => {
                  e.preventDefault()
                  await selectCatToDelete(category)
                }}
              >
                <RemoveIcon className={classes.statsIcon} />
              </IconButton>
            </Grid>
          )}

          <Grid className={classes.statsItem} item>
            <IconButton
              onClick={async (e) => {
                e.preventDefault()
                await selectCategory(category)
              }}
            >
              <EditIcon  className={classes.statsIcon} />
            </IconButton>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  )
}

Category.propTypes = {
  className: PropTypes.string,
  category: PropTypes.object.isRequired,
}
