import gql from "graphql-tag"

export const removeHistoryAdminMutation = gql`
  mutation removeHistory($from_date: String!, $to_date: String!) {
    removeHistory(from_date: $from_date, to_date: $to_date) {
      ok

      errors {
        path
        message
      }
    }
  }
`
