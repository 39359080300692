import React from "react"
import { Grid, Button, TextField } from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Loading from "components/Loading"
import { MessageField } from "components/MessageField"

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: "100%",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}))
export const JobEdit = ({
  values,
  touched,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  globalError,
}) => {
  const classes = useStyles()

  if (isSubmitting) {
    return <Loading />
  }

  return (
    <form
      className={classes.root}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      {globalError && <MessageField message={globalError} severity={"error"} />}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="title"
            name="title"
            label="Titre de l'annonce"
            value={values.title}
            onChange={handleChange}
            error={touched.title && errors.title ? true : false}
            fullWidth
            helperText={touched.title && errors.title ? errors.title : null}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <TextField
            required
            id="description"
            name="description"
            label="Titre de l'annonce"
            onChange={handleChange}
            value={values.description}
            error={touched.description && errors.description ? true : false}
            fullWidth
            multiline={true}
            helperText={
              touched.description && errors.description ? errors.description : null
            }
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Button
            color="primary"
            //disabled={!formState.isValid}
            fullWidth
            size="large"
            type="submit"
            onClick={handleSubmit}
            variant="contained"
          >
            Enregistrer
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}
