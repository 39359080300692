import gql from "graphql-tag"

export const createCompanyMutation = gql`
  mutation createCompanyAdmin($company: CompanyInput) {
    createCompanyAdmin(company: $company) {
      ok
      errors {
        path
        message
      }
      comp {
        id
        name
        is_active
        picture
        description_address
        description
        reference
        regions
        city
        categories {
          id
          name
        }
        subscription {
          name
          id
        }
        owner {
          id
          name
          lastname
          phone
          email
        }
      }
    }
  }
`

export const registerCompanyAdminMutation = gql`
  mutation registerCompanyAdmin($comp: RegisterAdminInput) {
    registerCompanyAdmin(comp: $comp) {
      ok
      errors {
        path
        message
      }
      comp {
        id
        name
        is_active
        picture
        description_address
        description
        reference
        regions
        city
        categories {
          id
          name
        }
        subscription {
          name
          id
        }
        owner {
          id
          name
          lastname
          phone
          email
        }
      }
    }
  }
`

export const updateCompanyMutation = gql`
  mutation updateCompanyAdmin(
    $id: String!
    $is_active: Boolean
    $category_ids: [String]
    $owner_phone: String
    $senrv_manager_ids: [String]
    $region: String
    $subscription_price: Float
    $rv_price: Float
    $subscription_services_number: Int
    $subscription_workers_number: Int
  ) {
    updateCompanyAdmin(
      id: $id
      is_active: $is_active
      category_ids: $category_ids
      owner_phone: $owner_phone
      senrv_manager_ids: $senrv_manager_ids
      region: $region
      subscription_price: $subscription_price
      rv_price:$rv_price
      subscription_services_number: $subscription_services_number
      subscription_workers_number: $subscription_workers_number
    ) {
      ok
      errors {
        path
        message
      }
    }
  }
`

export const deleteCompanyMutation = gql`
  mutation deleteCompanyAdmin($company_id: String) {
    deleteCompanyAdmin(company_id: $company_id) {
      ok
      errors {
        path
        message
      }
      company_id
    }
  }
`
