import React, { useState } from 'react'
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField
} from '@material-ui/core'
import { Accordion } from 'components/Accordion'
import { Autocomplete } from '@material-ui/lab'
import Loading from 'components/Loading'

const style = { padding: 30 }

export const EventToolbar = (props) => {
  const {
    author_phone,
    setAuthorPhone,
    canceled,
    setCanceled,
    search,
    from_date,
    setFromDate,
    to_date,
    setToDate,
    categories,
    setCategories,
    notifyEvent,
    catsData,

    clearFilter,
    phone_code
  } = props
  const [expanded, setExpanded] = useState(false)
  const [loading, setLoading] = useState(false)

  return (
    <Accordion
      id={'events-list-search'}
      title={'Filtrer les rendez-vous'}
      expanded={expanded}
      setExpanded={setExpanded}
    >
      <Grid container spacing={3} style={{ padding: '0px 20px' }}>
        {!loading && (
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Button
              color='primary'
              //disabled={!formState.isValid}
              // type="submit"

              onClick={async () => {
                try {
                  await setLoading(true)
                  const response = await notifyEvent({ variables: {} })
                  console.log('response', response)
                  await setLoading(false)
                  window.alert(
                    'Les rappels de rendez-vous ont été envoyés avec succès à leurs destinataires'
                  )
                } catch (error) {
                  await setLoading(false)
                  window.alert(error.message)
                }
              }}
              variant='contained'
            >
              Envoyer notification rendez-vous
            </Button>
          </Grid>
        )}

        {loading && (
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Loading />
          </Grid>
        )}

        <Grid item lg={6} md={6} sm={6} xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                id='canceled'
                name='canceled'
                checked={canceled}
                onChange={async (_e, value) => await setCanceled(!canceled)}
              />
            }
            label={'Rendez-vous annulé'}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id='start'
            label='Du '
            name='start'
            type='date'
            fullWidth
            value={from_date}
            onChange={async ({ target: { value } }) => {
              await setFromDate(value)
            }}
            InputLabelProps={{
              shrink: true
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id='end'
            label='Au'
            name='end'
            type='date'
            value={to_date}
            onChange={async ({ target: { value } }) => {
              await setToDate(value)
            }}
            InputLabelProps={{
              shrink: true
            }}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Autocomplete
            multiple={true}
            noOptionsText={'Aucun résultat'}
            id='Secteurs'
            name='Secteurs'
            label={'Secteurs'}
            defaultValue={categories}
            options={catsData}
            onChange={async (e, newVal) => {
              e.preventDefault()

              await setCategories(newVal)
            }}
            getOptionLabel={(option) => option}
            //   style={{ margin: 100 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label='Secteurs'
                variant='outlined'
                required
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required={false}
            id='phone'
            name='phone'
            label={`Téléphone ${phone_code}`}
            value={author_phone}
            onChange={async ({ target: { value } }) => await setAuthorPhone(value)}
          />
        </Grid>
        <Grid item xs={12}>
          <div className='content-btn-setting-generale'>
            <Button
              color='secondary'
              //disabled={!formState.isValid}
              type='submit'
              onClick={clearFilter}
              variant='contained'
            >
              Effacer les filtres
            </Button>

            <Button
              color='primary'
              //disabled={!formState.isValid}
              type='submit'
              onClick={search}
              variant='contained'
            >
              Chercher
            </Button>
          </div>
        </Grid>
      </Grid>
    </Accordion>
  )
}
