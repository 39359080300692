import React from "react"
import { useQuery } from "react-apollo"
import { makeStyles } from "@material-ui/styles"

import { oneSettingAdminQuery } from "graphql/query/setting_admin"

import ServiceListView from "./ServiceListView"
import RatingListView from "./RatingListView"
import ClientSignalView from "./ClientSignalView"
import CompanyView from "./CompanyView"
import { categoriesQuery } from "graphql/query/category"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}))

export default function ProfileView({
  match: {
    params: { company_id },
  },
}) {
  const classes = useStyles()

  const { data: settingAdminData } = useQuery(oneSettingAdminQuery)

  const time_zone =
    settingAdminData &&
    settingAdminData.oneSettingAdmin &&
    settingAdminData.oneSettingAdmin.time_zone
      ? settingAdminData.oneSettingAdmin.time_zone
      : null

  const currency =
    settingAdminData &&
    settingAdminData.oneSettingAdmin &&
    settingAdminData.oneSettingAdmin.currency
      ? settingAdminData.oneSettingAdmin.currency
      : null

  return (
    <div className={classes.root}>
      <CompanyView
        company_id={company_id}
        time_zone={time_zone}
        currency={currency}
      />
      <ServiceListView
        company_id={company_id}
        time_zone={time_zone}
        currency={currency}
      />

      <RatingListView
        company_id={company_id}
        time_zone={time_zone}
        currency={currency}
      />
      <ClientSignalView company_id={company_id} time_zone={time_zone} />
    </div>
  )
}
