import React, { Fragment } from "react"
import {
  Button,
  TextField,
  Grid,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core"

import { makeStyles, useTheme } from "@material-ui/core/styles"
import Loading from "components/Loading"
import { MessageField } from "components/MessageField"
import { SelectField } from "components/SelectField"
import DurationHourInput from "components/DurationHourInput"
import DurationMinuteInput from "components/DurationMinuteInput"
import CurrencyTextField from "@unicef/material-ui-currency-textfield/dist/CurrencyTextField"

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: "100%",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}))

export const CompanyCreate = ({
  values,
  touched,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  globalError,
  categories,
  optionDays,
  regions,
  currency,
}) => {
 
  const classes = useStyles()
  const theme = useTheme()

  if (isSubmitting) {
    return <Loading />
  }
  const dataSourceDays = optionDays.map((d) => d.name)
  return (
    <form
      className={classes.root}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      {globalError && <MessageField message={globalError} severity={"error"} />}

      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <MessageField
            message={"Identification de L' entreprise"}
            severity="success"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="name"
            name="name"
            value={values.name}
            required
            label="Nom de l'entreprise"
            onChange={handleChange}
            fullWidth
            helperText={touched.name && errors.name ? errors.name : null}
            error={touched.name && errors.name ? true : false}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <MessageField message={"référence de l'entreprise"} severity="success" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="reference"
            name="reference"
            label="Reférence"
            value={values.reference}
            onChange={handleChange}
            fullWidth
            helperText={
              touched.reference && errors.reference ? errors.reference : null
            }
            error={touched.reference && errors.reference ? true : false}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <MessageField
            message={"Secteurs géographiques et d'activités"}
            severity="success"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            multiple={false}
            handleChange={handleChange}
            name={"region"}
            label={"Régions"}
            value={values.region}
            theme={theme}
            dataSource={regions}
            helperText={touched.region && errors.region ? errors.region : null}
            error={touched.region && errors.region ? true : false}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            multiple={true}
            handleChange={handleChange}
            name={"category_ids"}
            label={"Secteurs d'activités"}
            value={values.category_ids}
            theme={theme}
            dataSource={categories}
            helperText={
              touched.category_ids && errors.category_ids
                ? errors.category_ids
                : null
            }
            error={touched.category_ids && errors.category_ids ? true : false}
          />
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <TextField
            required
            id="city"
            name="city"
            label="Ville/Quartier"
            value={values.city}
            onChange={handleChange}
            fullWidth
            helperText={touched.city && errors.city ? errors.city : null}
            error={touched.city && errors.city ? true : false}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="description_address"
            name="description_address"
            value={values.description_address}
            required
            label="Complément adresse"
            onChange={handleChange}
            fullWidth
            helperText={
              touched.description_address && errors.description_address
                ? errors.description_address
                : null
            }
            error={
              touched.description_address && errors.description_address
                ? true
                : false
            }
          />
        </Grid> */}

        {/* 
        <Grid item xs={12} sm={12}>
          <InputLabel required>Description</InputLabel>
          <textarea
            id="description"
            name="description"
            label="Description"
            value={values.description}
            onChange={handleChange}
            rows={5}
            style={{ width: "100%" }}
          ></textarea>
          <span></span>

          {touched.description && errors.description && (
            <span style={{ color: "red" }}>{errors.description}</span>
          )}
        </Grid>
        <Grid item xs={12} sm={12}>
          <InputLabel required>Photo</InputLabel>
          <Input
            fullWidth
            type="file"
            name="file"
            id="file"
            onChange={async ({
              target: {
                _validity,
                files: [file],
              },
            }) => {
              await setFieldValue("file", file)
            }}
            style={{ display: "none" }}
          />
          <label htmlFor="file">
            <Button variant="contained" component="span" color="secondary">
              Ajouter une photo
            </Button>
          </label>
          <br />
          <br />
          {values.file && (
            <div>
              <img
                src={URL.createObjectURL(values.file)}
                alt="photo"
                width="100"
                height="100"
              />
            </div>
          )}
          <br />
          {touched.file && errors.file && (
            <span style={{ color: "red" }}>{errors.file}</span>
          )}
        </Grid> */}

        {/*  <Grid item xs={12} sm={12}>
          <SelectField
            multiple={false}
            handleChange={handleChange}
            name={"subscription_id"}
            label={"Abonnement"}
            value={values.subscription_id}
            theme={theme}
            dataSource={subscriptions}
            helperText={
              touched.subscription_id && errors.subscription_id
                ? errors.subscription_id
                : null
            }
            error={touched.subscription_id && errors.subscription_id ? true : false}
          />
        </Grid> */}

        <Grid item xs={12} sm={12}>
          <MessageField message={"Tarification"} severity="success" />
        </Grid>

        <Grid item xs={12} sm={12}>
          <CurrencyTextField
            as={TextField}
            fullWidth
            value={values.subscription_price}
            name="subscription_price"
            id="subscription_price"
            variant="standard"
            // disabled={!values.show_price}
            label={`Prix en ${currency}`}
            onChange={async (_event, value) =>
              await setFieldValue("subscription_price", value)
            }
            helperText={
              touched.subscription_price && errors.subscription_price
                ? errors.subscription_price
                : null
            }
            error={
              touched.subscription_price && errors.subscription_price ? true : false
            }
            currencySymbol={currency}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            inputProps={{ min: "0", step: "1" }}
            label="Nombre de Personnel"
            value={values.subscription_workers_number}
            name="subscription_workers_number"
            id="subscription_workers_number"
            onChange={handleChange}
            type="number"
            helperText={
              touched.subscription_workers_number &&
              errors.subscription_workers_number
                ? errors.subscription_workers_number
                : null
            }
            error={
              touched.subscription_workers_number &&
              errors.subscription_workers_number
                ? true
                : false
            }
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            inputProps={{ min: "0", step: "1" }}
            label="Nombre de Service(s)"
            value={values.subscription_services_number}
            name="subscription_services_number"
            id="subscription_services_number"
            onChange={handleChange}
            type="number"
            helperText={
              touched.subscription_services_number &&
              errors.subscription_services_number
                ? errors.subscription_services_number
                : null
            }
            error={
              touched.subscription_services_number &&
              errors.subscription_services_number
                ? true
                : false
            }
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <MessageField
            message={` Durée moyenne de vos rendez-vous en heures/minutes ${
              values.slotDuration.split(":")[0]
            }h${values.slotDuration.split(":")[1]}min`}
            severity="success"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <DurationHourInput
            name={"heures"}
            handleChange={async ({ target: { value } }) => {
              if (values.slotDuration) {
                const [oldH, oldM] = values.slotDuration.split(":")
                if (oldM) {
                  await setFieldValue("slotDuration", `${value}:${oldM}`)
                } else {
                  await setFieldValue("slotDuration", `${value}:00`)
                }
              }
            }}
            id="slotDuration-hours"
            value={values.slotDuration ? values.slotDuration.split(":")[0] : ""}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <DurationMinuteInput
            name={"minutes"}
            handleChange={async ({ target: { value } }) => {
              if (values.slotDuration) {
                const [oldH, oldM] = values.slotDuration.split(":")
                if (oldH) {
                  await setFieldValue("slotDuration", `${oldH}:${value}`)
                } else {
                  await setFieldValue("slotDuration", `00:${value}`)
                }
              }
            }}
            id="slotDuration-hours"
            value={values.slotDuration ? values.slotDuration.split(":")[1] : ""}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <MessageField
            message={"Votre capacité à gérer plus d'un rendez-vous en même temps"}
            severity="success"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            required
            label=" Capacité Rv"
            value={values.slotEventCount}
            name="slotEventCount"
            id="slotEventCount"
            onChange={handleChange}
            type="number"
            helperText={
              touched.slotEventCount && errors.slotEventCount
                ? errors.slotEventCount
                : null
            }
            error={touched.slotEventCount && errors.slotEventCount ? true : false}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <MessageField message={"Horaires journaliers des activités"} severity="success" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            name="startTime"
            id="startTime"
            label="Heure début d'activité"
            value={values.startTime}
            onChange={handleChange}
            type="time"
            fullWidth
            helperText={
              touched.startTime && errors.startTime ? errors.startTime : null
            }
            error={touched.startTime && errors.startTime ? true : false}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            name="endTime"
            id="endTime"
            label="Heure fin d'activité"
            value={values.endTime}
            onChange={handleChange}
            type="time"
            fullWidth
            helperText={touched.endTime && errors.endTime ? errors.endTime : null}
            error={touched.endTime && errors.endTime ? true : false}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <MessageField
            message={"Caractéristique d'interruption temporaire des activités"}
            severity="success"
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <FormControlLabel
            fullWidth
            control={
              <Checkbox
                id="no_rest"
                name="no_rest"
                checked={values.no_rest}
                onChange={async (_e, value) => await setFieldValue("no_rest", value)}
              />
            }
            label={"Pas de pause"}
          />
        </Grid>

        {!values.no_rest && (
          <Fragment>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                name="startTimeRest"
                id="startTimeRest"
                label="Heure début pause"
                value={values.startTimeRest}
                onChange={handleChange}
                type="time"
                fullWidth
                helperText={
                  touched.startTimeRest && errors.startTimeRest
                    ? errors.startTimeRest
                    : null
                }
                error={touched.startTimeRest && errors.startTimeRest ? true : false}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                required
                name="endTimeRest"
                id="endTimeRest"
                label="Heure fin pause"
                value={values.endTimeRest}
                onChange={handleChange}
                type="time"
                fullWidth
                helperText={
                  touched.endTimeRest && errors.endTimeRest
                    ? errors.endTimeRest
                    : null
                }
                error={touched.endTimeRest && errors.endTimeRest ? true : false}
              />
            </Grid>
          </Fragment>
        )}

        <Grid item xs={12} sm={12}>
          <MessageField message={"Jours d'activités"} severity="success" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            required={true}
            multiple={true}
            label={"jours  activités"}
            value={values.daysOfWeek}
            name="daysOfWeek"
            id="daysOfWeek"
            theme={theme}
            dataSource={dataSourceDays}
            disabled={false}
            helperText={
              touched.daysOfWeek && errors.daysOfWeek ? errors.daysOfWeek : null
            }
            error={touched.daysOfWeek && errors.daysOfWeek ? true : false}
            handleChange={async ({ target: { value } }) => {
              await setFieldValue("daysOfWeek", value)

              const newHiddenDays = []
              optionDays.forEach((el) => {
                if (value.indexOf(el.name) < 0) {
                  newHiddenDays.push(el.name)
                }
              })

              await setFieldValue("hiddenDays", newHiddenDays)
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <SelectField
            required={false}
            name="hiddenDays"
            id="hiddenDays"
            helperText={
              touched.hiddenDays && errors.hiddenDays ? errors.hiddenDays : null
            }
            error={touched.hiddenDays && errors.hiddenDays ? true : false}
            handleChange={async ({ target: { value } }) => {
              await setFieldValue("hiddenDays", value)
              const newDays = []
              optionDays.forEach((el) => {
                if (value.indexOf(el.name) < 0) {
                  newDays.push(el.name)
                }
              })

              await setFieldValue("daysOfWeek", newDays)
            }}
            multiple={true}
            label={"jours hors activités"}
            value={values.hiddenDays}
            theme={theme}
            dataSource={dataSourceDays}
            disabled={false}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <MessageField
            message={"Propriétaire de l'entreprise"}
            severity="success"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="owner_id"
            name="owner_id"
            value={values.owner_id}
            required
            label="Téléphone propriétaire"
            onChange={handleChange}
            fullWidth
            helperText={touched.owner_id && errors.owner_id ? errors.owner_id : null}
            error={touched.owner_id && errors.owner_id ? true : false}
          />
        </Grid>
        <Grid item lg={12} xs={12}>
          <Button
            color="primary"
            //disabled={!formState.isValid}
            type="submit"
            variant="contained"
          >
            Créer
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}
