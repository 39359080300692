import gql from "graphql-tag"

export const createSubMutation = gql`
  mutation createSub(
    $name: String!
    $description: String!
    $services_number: Int!
    $workers_number: Int!
    $price: Float
  ) {
    createSub(
      name: $name
      description: $description
      services_number: $services_number
      workers_number: $workers_number
      price: $price
    ) {
      ok
      errors {
        path
        message
      }
      subscription {
        id
        name
        description
        price
        is_active
        services_number
        workers_number
        author {
          id
          fullname
        }
        companies {
          id
          name
        }
        createdAt
        updatedAt
      }
    }
  }
`

export const updateSubMutation = gql`
  mutation updateSub(
    $id:String!,
    $name:String,
    $description:String
    $is_active:Boolean,
    $services_number:Int,
    $workers_number:Int
    $price:Float  
    
  ) {
    updateSub(
      id:$id,
      name: $name
      description: $description
      is_active:$is_active
      services_number: $services_number
      workers_number: $workers_number
      price: $price
      
    ) {
      ok
      errors {
        path
        message
      }
      subscription {
        id
        name
        description
        price
        services_number
        workers_number
        is_active
        author {
          id
          fullname
        }
        companies {
          id
          name
        }
        createdAt
        updatedAt
      }
    }
  }
`
