import React from "react"
import {
  Grid,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Loading from "components/Loading"
import { MessageField } from "components/MessageField"
import { SelectField } from "components/SelectField"
import { PhoneField } from "components/PhoneField"

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: "100%",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}))
export const TeamCreate = ({
  values,
  touched,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,
  globalError,
  setFieldValue,
  roles,
}) => {
  const theme = useTheme()
  const classes = useStyles()

  if (isSubmitting) {
    return <Loading />
  }

  return (
    <form
      className={classes.root}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      {globalError && <MessageField message={globalError} severity={"error"} />}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <SelectField
            required={true}
            multiple={false}
            handleChange={async ({ target: { value } }) => {
              try {
                await setFieldValue("gender", value)
              } catch (error) {}
            }}
            name="gender"
            label={"civilité"}
            value={values.gender}
            theme={theme}
            dataSource={["Monsieur", "Madame"]}
            helperText={touched.gender && errors.gender ? errors.gender : null}
            error={touched.gender && errors.gender ? true : false}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="name"
            name="name"
            label="Nom"
            onChange={handleChange}
            error={touched.name && errors.name ? true : false}
            fullWidth
            helperText={touched.name && errors.name ? errors.name : null}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="lastname"
            name="lastname"
            label="Prénom"
            onChange={handleChange}
            error={touched.lastname && errors.lastname ? true : false}
            fullWidth
            helperText={touched.lastname && errors.lastname ? errors.lastname : null}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required={false}
            id="email"
            name="email"
            label="Email"
            onChange={handleChange}
            error={touched.email && errors.email ? true : false}
            fullWidth
            helperText={touched.email && errors.email ? errors.email : null}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            error={touched.password && errors.password ? true : false}
            helperText={touched.password && errors.password ? errors.password : null}
            label="Password"
            name="password"
            id="password"
            required
            onChange={handleChange}
            type="password"
            value={values.password}
          />
        </Grid>
     {/*    <Grid item xs={12} sm={6}>
          <SelectField
            required={true}
            multiple={true}
            handleChange={async ({ target: { value } }) =>
              await setFieldValue("roles", value)
            }
            name="roles"
            label={"Roles"}
            value={values.roles}
            theme={theme}
            dataSource={roles}
            helperText={touched.roles && errors.roles ? errors.roles : null}
            error={touched.roles && errors.roles ? true : false}
          />
        </Grid> */}
        <Grid item xs={12} sm={6}>
          <PhoneField
            value={values.phone}
            setFieldValue={setFieldValue}
            label="Téléphone"
            name="phone"
            helperText={touched.phone && errors.phone ? errors.phone : null}
            error={touched.phone && errors.phone ? true : false}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={
              <Checkbox
                id="sms_notify"
                name="sms_notify"
                checked={values.sms_notify}
                onChange={async (_e, value) =>
                  await setFieldValue("sms_notify", value)
                }
              />
            }
            label={"Envoyer un sms de notification"}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            color="primary"
            //disabled={!formState.isValid}
           
            type="submit"
            onClick={handleSubmit}
            variant="contained"
          >
            enregistrer
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}
