import React, { useEffect, useState } from "react"
import { useQuery, useMutation } from "react-apollo"
import moment from "moment-timezone"
import * as Yup from "yup"
import { Formik } from "formik"
import { makeStyles } from "@material-ui/styles"
import { dayNumberFunc, optionDays } from "utils/func"
import { registerCompanyAdminMutation } from "../../graphql/mutation/company"
import { findCompaniesCommercialQuery } from "../../graphql/query/company"
import { categoriesQuery } from "../../graphql/query/category"
import Loading from "components/Loading"
import { CompanyList } from "./components/CompanyList"
import { CompanyToolbar } from "./components/CompanyToolbar"
import { CompanyCreate } from "./components/CompanyCreate"
import { ModalDialog } from "components/ModalDialog"
import { oneSettingAdminQuery } from "graphql/query/setting_admin"
import { findSubscriptionsQuery } from "graphql/query/subscription"
import { currentUserQuery, senrvUsersQuery } from "graphql/query/user"
import client from "graphql/apollo"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '9px 25px'
  },
  // content: {
  //   marginTop: theme.spacing(2),
  // },
  pagination: {
    marginTop: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
}))
/* const FILE_SIZE = 1600 * 1024
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"] */
const createSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(2, "minimun 2 caractères")
    .max(50, "maximum 50 caractères")
    .required("champ requis"),
  reference: Yup.string()
    .trim()
    .min(6, "minimun 6 caractères")

    .required("Champ requis"),
  subscription_price: Yup.number().required("champ requis"),
  subscription_services_number: Yup.number().required("champ requis"),
  subscription_workers_number: Yup.number().required("champ requis"),
  owner_id: Yup.string().trim().required("champ requis"),
  region: Yup.string().trim().required("champ requis"),
  category_ids: Yup.array().of(Yup.string()).required("champ requis"),
  daysOfWeek: Yup.array().of(Yup.string()).required("champ requis"),
  startTime: Yup.string().required("champ requis"),
  endTime: Yup.string().required("champ requis"),
  no_rest: Yup.boolean().default(false).typeError("champ requis"),
  startTimeRest: Yup.string().nullable("champ requis"),
  endTimeRest: Yup.string().nullable("champ requis"),
  slotDuration: Yup.string().required("champ requis"),
  slotEventCount: Yup.number().required("champ requis"),
  hiddenDays: Yup.array().of(Yup.string()).nullable("champ requis"),
})

const CompanyCommercialView = () => {
  const classes = useStyles()

  const [loading, setLoading] = useState(false)
  const [name, setName] = useState("")
  const [reference, setReference] = useState("")
  const [manager_ids, setManagerIds] = useState([])
  const [regionS, setRegions] = useState([])
  const [categories, setCategories] = useState([])
  const [page, setPage] = useState(1)
  const [is_active, setIsActive] = useState(undefined)

  const [createModal, setCreateModal] = useState(false)
  const [globalCreateError, setGlobalCreateError] = useState("")
  const [canModify,setCanModify]=useState(false)

  const {
    loading: loading1,
    data,
    fetchMore,
    refetch,
  } = useQuery(findCompaniesCommercialQuery, {
    variables: { page },
  })

  const { loading: loading2, data: cats } = useQuery(categoriesQuery)
  const { loading: loading3, data: settingAdminData } =
    useQuery(oneSettingAdminQuery)

  const { loading: loading4, data: subsData } = useQuery(findSubscriptionsQuery)
  const { loading: loading5, data: senrvUsersData } = useQuery(senrvUsersQuery)
  const { data: userData } = useQuery(currentUserQuery, {
    //fetchPolicy: "network-only",
  })
  const roles =
    userData && userData.onlineUser && userData.onlineUser.roles
      ? userData.onlineUser.roles
      : []
  const regions =
    settingAdminData &&
    settingAdminData.oneSettingAdmin &&
    settingAdminData.oneSettingAdmin.regions
      ? settingAdminData.oneSettingAdmin.regions
      : []
  const [createItem] = useMutation(registerCompanyAdminMutation, {
    async update(
      _cache,
      {
        data: {
          registerCompanyAdmin: { ok, comp },
        },
      }
    ) {
      if (ok) {
        const variables = await computeVariables(page)
        const { findCompaniesAMCommercial } = await client.readQuery({
          query: findCompaniesCommercialQuery,
          variables,
        })
        let docs = findCompaniesAMCommercial.docs
        const compIndex = findCompaniesAMCommercial.docs.findIndex(
          (el) => el.id.toString() === comp.id.toString()
        )
        if (compIndex < 0) {
          docs.unshift(comp)
        } else {
          docs[compIndex] = comp
        }

        await client.writeQuery({
          query: findCompaniesCommercialQuery,
          variables,
          data: {
            findCompaniesAMCommercial: {
              ...findCompaniesAMCommercial,
              totalDocs: findCompaniesAMCommercial.totalDocs + 1,
              docs,
            },
          },
        })
      }
    },
  })
  const generalLoading =
    loading1 || loading || loading2 || loading3 || loading4 || loading5

  const create = async (
    values,

    { setSubmitting, setFieldError }
  ) => {
    try {
      const categories = []

      const {
        category_ids,
        startTime,
        endTime,
        startTimeRest,
        endTimeRest,
        daysOfWeek,
        slotDuration,
        slotEventCount,
        hiddenDays,
        no_rest,
        owner_id,
        subscription_price,
        subscription_services_number,
        subscription_workers_number,
        region,
        name,
        reference,
      } = values

      let company = { owner_id, name, no_rest, reference }
      const strTimeRest = moment(startTimeRest, "HH:mm")
      const ndTimeRest = moment(endTimeRest, "HH:mm")
      const strTime = moment(startTime, "HH:mm")
      const ndTime = moment(endTime, "HH:mm")

      if (!no_rest && strTimeRest.isAfter(ndTimeRest)) {
        await setFieldError(
          "startTimeRest",
          "heures début et fin pause incohérentes"
        )
        await setFieldError("endTimeRest", "heures début et fin pause incohérentes")
        return
      }

      if (strTime.isAfter(ndTime)) {
        await setFieldError(
          "startTime",
          "heures début et fin d'activités incohérentes"
        )
        await setFieldError(
          "endTime",
          "heures début et fin d'activités incohérentes"
        )
        return
      }

      if (!no_rest && strTimeRest.isBefore(strTime)) {
        await setFieldError(
          "startTimeRest",
          "heures début activité et pause incohérentes"
        )
        await setFieldError(
          "startTime",
          "heures début activité et pause incohérentes"
        )
        return
      }

      if (!no_rest && ndTimeRest.isAfter(ndTime)) {
        await setFieldError(
          "endTimeRest",
          "heures fin activité et pause incohérentes"
        )
        await setFieldError("endTime", "heures fin activité et pause incohérentes")
        return
      }

      if (startTime) {
        company.startTime = startTime
      }
      if (endTime) {
        company.endTime = endTime
      }
      if (!no_rest && startTimeRest) {
        company.startTimeRest = startTimeRest
      }
      if (!no_rest && endTimeRest) {
        company.endTimeRest = endTimeRest
      }

      if (slotDuration) {
        company.slotDuration = slotDuration
      }
      if (slotEventCount) {
        company.slotEventCount = slotEventCount
      }
      if (hiddenDays) {
        company.hiddenDays = hiddenDays.map(dayNumberFunc)
      }
      if (daysOfWeek) {
        company.daysOfWeek = daysOfWeek.map(dayNumberFunc)
      }
      company.subscription = {
        price: subscription_price,
        services_number: subscription_services_number,
        workers_number: subscription_workers_number,
      }
      if (region) {
        company.region = region
      }

      category_ids.forEach((el) => {
        cats.categories.forEach((cat) => {
          if (el.toString() === cat.name.toString()) {
            categories.push(cat.id)
          }
        })
      })

      if (categories.length) {
        company.category_ids = categories
      }

      const res = await createItem({
        variables: {
          comp: company,
        },
      })

      const { ok, errors } = res.data.registerCompanyAdmin
      if (ok) {
        await setSubmitting(false)
        await setCreateModal(false)
      } else {
        errors.forEach(async (error) => {
          if (error.path && error.path.toString() === "global") {
            await setGlobalCreateError(error.message)
          } else {
            await setFieldError(error.path, error.message)
            await setSubmitting(false)
          }
        })
      }
    } catch (error) {
      throw error
    }
  }

  const onPageChange = async (page) => {
    try {
      await setLoading(true)
      await setPage(page)
      let variables = { page }

      if (name.trim().length > 0) {
        variables.name = name
      }
      fetchMore({
        variables,
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev

          const { findCompaniesAMCommercial } = fetchMoreResult
          return Object.assign({}, prev, {
            findCompaniesAMCommercial,
          })
        },
      })

      await setLoading(false)
    } catch (error) {
      throw error
    }
  }
  const search = async () => {
    try {
      await setLoading(true)

      let variables = await computeVariables(1)

      fetchMore({
        variables,
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev

          const { findCompaniesAMCommercial } = fetchMoreResult
          return Object.assign({}, prev, {
            findCompaniesAMCommercial,
          })
        },
      })
      await setLoading(false)
    } catch (error) {
      window.alert(error.message)
    }
  }

  const computeVariables = async (page) => {
    await setPage(page)
    let variables = { page }

    if (name) {
      variables.name = name
    }
    if (reference) {
      variables.reference = reference
    }
    if (regionS.length > 0) {
      variables.regions = regionS
    }
    if (is_active != undefined) {
      variables.is_active = is_active
    }

    if (categories.length > 0) {
      const category_ids = []
      categories.forEach((el) => {
        cats.categories.forEach((cat) => {
          if (el.toString() === cat.name.toString()) {
            category_ids.push(cat.id)
          }
        })
      })
      variables.category_ids = category_ids
    }

    if (manager_ids.length > 0) {
      const managers = []
      manager_ids.forEach((el) => {
        const splitted = el.split("->")
        const phone = splitted[1]
        senrvUsersData.senrvUsers.forEach((u) => {
          if (phone == u.phone) {
            managers.push(u.id)
          }
        })
      })

      variables.senrv_manager_ids = managers
    }

    return variables
  }

  const clearFilter = async () => {
    try {
      await setName("")
      await setReference("")
      await setManagerIds([])
      await setRegions([])
      await setCategories([])

      await setIsActive(true)
      await setPage(1)
      let variables = await computeVariables(1)
      await setLoading(true)
      await refetch({ variables })
      await setLoading(false)
    } catch (error) {
      await setLoading(false)
      window.alert(error.message)
    }
  }


  const setUpRoles = async (roles) => {
    try {
      const modify = roles
        .filter((r) => !r.company_id)
        .some((r) => r.is_super_admin  )

      

      await setCanModify(modify)
       

      return true
    } catch (error) {
      throw error
    }
  }
  useEffect(() => {
    if (roles) {
      setUpRoles(roles)
        .then(() => {})
        .catch((err) => console.log("error", err))
    }
  }, [roles])

  const currency =
    regions && regions.oneSettingAdmin && regions.oneSettingAdmin.currency
      ? regions.oneSettingAdmin.currency
      : ""

  if (generalLoading) {
    return <Loading />
  }
  return (
    <div className={classes.root}>
      <CompanyToolbar
        openModal={async () => await setCreateModal(true)}
        setName={setName}
        name={name}
        senrvusers={
          senrvUsersData && senrvUsersData.senrvUsers
            ? senrvUsersData.senrvUsers.map((user) => {
                const gender = user.gender == "male" ? "Monsieur" : "Madame"
                return `${gender} ${user.name} ${user.lastname}->${user.phone}`
              })
            : []
        }
        setReference={setReference}
        reference={reference}
        setManagerIds={setManagerIds}
        manager_ids={manager_ids}
        setRegions={setRegions}
        regions={regionS}
        regionsData={regions}
        setCategories={setCategories}
        categories={categories}
        setIsActive={setIsActive}
        is_active={is_active}
        clearFilter={clearFilter}
        search={search}
      />

      <Formik
        initialValues={{
          name: "",

          owner_id: "",

          reference: "",
          category_ids: [],
          region: "",

          hiddenDays: [],
          daysOfWeek: [],
          startTime: "",
          endTime: "",
          no_rest: false,
          startTimeRest: "",
          endTimeRest: "",
          slotDuration: "00:00",
          slotEventCount: 1,
          subscription_price: 0,
          subscription_services_number: 0,
          subscription_workers_number: 0,
        }}
        validationSchema={createSchema}
        onSubmit={create}
      >
        {(props) => (
          <ModalDialog
            open={createModal}
            cancel={async () => {
              await props.handleReset()
              await setCreateModal(false)
            }}
            title={"Création nouvelle entreprise"}
          >
            <CompanyCreate
              currency={currency}
              optionDays={optionDays}
              categories={
                cats && cats.categories ? cats.categories.map((cat) => cat.name) : []
              }
              regions={regions}
              globalError={globalCreateError}
              subscriptions={
                subsData && subsData.findSubscriptions
                  ? subsData.findSubscriptions.map((e) => e.name)
                  : []
              }
              {...props}
            />
          </ModalDialog>
        )}
      </Formik>

      <div className={classes.content}>
        <CompanyList data={data.findCompaniesAMCommercial} onPageChange={onPageChange}  canModify={canModify}/>
      </div>
    </div>
  )
}

export default CompanyCommercialView
