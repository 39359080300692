import gql from "graphql-tag"

export const updateServiceAdminMutation = gql`
  mutation updateServiceAdmin(
    $is_active: Boolean
    $id: String!
    $company_id: String!
  ) {
    updateServiceAdmin(is_active: $is_active, id: $id, company_id: $company_id) {
      ok
      id
      is_active
      errors {
        path
        message
      }
    }
  }
`
