import gql from "graphql-tag"

export const createCategoryMutation = gql`
  mutation createCat($name: String, $description: String, $file: Upload,$priority:Int) {
    createCat(name: $name, description: $description, file: $file,priority:$priority) {
      ok
      cat {
        id
        name
        picture
        is_active
        createdAt
        updatedAt
        priority
        author {
          id
          name
          lastname
        }
      }
      errors {
        path
        message
      }
    }
  }
`

export const deleteCategoryMutation = gql`
  mutation deleteCat($category_id: String!) {
    deleteCat(category_id: $category_id) {
      ok
      category_id
      errors {
        path
        message
      }
    }
  }
`

export const updateCategoryMutation = gql`
  mutation updateCat(
    $id: String!
    $name: String
    $description: String
    $file: Upload
    $is_active: Boolean
    $priority:Int
  ) {
    updateCat(
      id: $id
      name: $name
      description: $description
      file: $file
      is_active: $is_active
      priority:$priority
    ) {
      ok
      cat {
        id
        name
        picture
        is_active
        createdAt
        updatedAt
        priority
        author {
          id
          name
          lastname
        }
      }
      errors {
        path
        message
      }
    }
  }
`
