import React from "react"
import { Grid } from "@material-ui/core"
import Pagination from "@material-ui/lab/Pagination"
import { Company } from "./Company"

export const CompanyList = ({
  data: { docs, page, totalPages },
  selectForUpdate,
  selectForRemove,
  onPageChange,
}) => {
  return (
    <Grid>
      <div style={{ marginBottom: 30 }}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={async (_event, value) => {
            if (value.toString() !== page.toString()) {
              await onPageChange(value)
            }
          }}
        />
      </div>
      <Grid container spacing={3} style={{paddingLeft:'9px'}}>
        {docs.map((doc) => (
          <Grid item key={doc.id} lg={4} md={6} xs={12}>
            <Company
              key={doc.id}
              item={doc}
               
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}
