import React, { useEffect } from "react"
import clsx from "clsx"

import { makeStyles } from "@material-ui/styles"
import { Divider, Drawer } from "@material-ui/core"

import { Profile, SidebarNav } from "./components"

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 260,
    [theme.breakpoints.up("lg")]: {
      marginTop: 64,
      height: "calc(100% - 64px)",
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(2),
  },
  divider: {
    marginTop: 16,
    marginBottom: 5,
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
}))

const Sidebar = (props) => {

  const { open, variant, onClose, computedRole, className, key, ...rest } = props

  const classes = useStyles()

  if (!computedRole) {
    return null
  }
  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div {...rest} className={clsx(classes.root, className)}>
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav className={classes.nav} pages={props.pages} />
        {/* <UpgradePlan /> */}
      </div>
    </Drawer>
  )
}

export default Sidebar
