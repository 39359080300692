import gql from "graphql-tag"

export const findSubscriptionsQuery = gql`
  query findSubscriptions {
    findSubscriptions {
      id
      name
      description
      is_active
      price
      services_number
      workers_number
      author {
        id
        fullname
      }
      companies {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`
