import React, { useState } from "react"
import { useQuery, useMutation } from "react-apollo"
import * as Yup from "yup"
import { Formik } from "formik"
import { makeStyles } from "@material-ui/styles"
import Loading from "components/Loading"
import { ModalDialog } from "components/ModalDialog"
import { Profile } from "./components/Profile"
import { ProfileEdit } from "./components/ProfileEdit"
import { currentUserQuery } from "graphql/query/user"
import { changePasswordMutation, updateAccountMutation } from "graphql/mutation/user"
import { oneSettingAdminQuery } from "graphql/query/setting_admin"
import { toast } from "react-toastify"
import client from "graphql/apollo"
import { ChangePasswordEdit } from "./components/ChangePasswordEdit"

const updateAccountSchema = Yup.object().shape({
  gender: Yup.string().nullable(),
  name: Yup.string()
    .trim()
    .min(2, "minimun 2 caractères")
    .max(50, "maximum 50 caractères")
    .required("champ requis"),
  lastname: Yup.string()
    .trim()
    .min(2, "minimun 2 caractères")
    .max(50, "maximum 50 caractères")
    .nullable(),
  phone: Yup.string(),
  email: Yup.string().email("email invalide").nullable(),
})

const updatePasswordSchema = Yup.object().shape({
  password: Yup.string()
    .trim()
    .min(6, "minimun 6 caractères")
    .max(50, "maximum 50 caractères")
    .required("champ requis"),

  passwordConfirm: Yup.string()
    .trim()
    .min(6, "minimun 6 caractères")
    .max(50, "maximum 50 caractères")
    .required("champ requis"),

  phone: Yup.string()
    .trim()

    .required("champ requis"),
})
const useStyles = makeStyles((theme) => ({
  // root: {
  //   padding: theme.spacing(3),
  // },
  content: {
    // marginTop: theme.spacing(2),
    padding:'15px 35px'
  },
}))

const ProfileView = () => {
  const classes = useStyles()
  const [globalEditError, setGlobalEditError] = useState("")

  const [updateAccountModal, setUpdateAccountModal] = useState(false)
  const [updatePasswordModal, setUpdatePasswordModal] = useState(false)

  const { loading: loading1, data: currentUserData } = useQuery(currentUserQuery)
  const [changePassword] = useMutation(changePasswordMutation)
  const { data, loading: loading2 } = useQuery(oneSettingAdminQuery)
  const phone_code =
    data && data.oneSettingAdmin && data.oneSettingAdmin.phone_code
      ? data.oneSettingAdmin.phone_code
      : null

  const phone_min_size =
    data && data.oneSettingAdmin && data.oneSettingAdmin.phone_min_size
      ? data.oneSettingAdmin.phone_min_size
      : null

  const phone_max_size =
    data && data.oneSettingAdmin && data.oneSettingAdmin.phone_max_size
      ? data.oneSettingAdmin.phone_max_size
      : null
  const [updateAccount] = useMutation(updateAccountMutation, {
    async update(
      _cache,
      {
        data: {
          updateAccount: { ok, name, lastname, email, phone, gender },
        },
      }
    ) {
      try {
        if (ok) {
          const { onlineUser } = await client.readQuery({
            query: currentUserQuery,
            variables: {},
          })

          let data = {}
          if (email) {
            data.email = email
          }
          if (gender) {
            data.gender = gender == "Monsieur" ? "male" : "female"
          }

          if (name) {
            data.name = name
          }
          if (lastname) {
            data.lastname = lastname
          }

          if (phone) {
            data.phone = phone
          }

          await client.writeQuery({
            query: currentUserQuery,

            data: {
              onlineUser: { ...onlineUser, ...data },
            },
          })
        }
      } catch (error) {
        window.alert(error.message)
      }
    },
  })

  const updateAcc = async (
    { name, lastname, gender, email, phone },
    { setSubmitting }
  ) => {
    try {
      await setSubmitting(true)

      let data = {}
      if (email && currentUser.email != email) {
        data.email = email
      }
      if (gender && currentUser.gender != gender) {
        data.gender = gender
      }

      if (name && currentUser.name != name) {
        data.name = name
      }
      if (lastname && currentUser.lastname != lastname) {
        data.lastname = lastname
      }

      if (phone && currentUser.phone != phone) {
        data.phone = phone
      }

      if (Object.keys(data).length > 0) {
        const response = await updateAccount({
          variables: data,
        })

        const { ok, errors } = response.data.updateAccount
        if (ok) {
          await setUpdateAccountModal(false)
          toast.success("Vos modifications sont enregistrées")
        } else {
          window.alert(errors[0].message)
        }
      }
    } catch (error) {
      window.alert(error.message)
    } finally {
      await setSubmitting(true)
      await setUpdateAccountModal(false)
    }
  }

  const updatePass = async (
    { password, passwordConfirm, phone },
    { setSubmitting, setFieldError }
  ) => {
    try {
      if (passwordConfirm != password) {
        await setFieldError("passwordConfirm", "Les deux passwords sont différents")
        window.alert("Les deux passwords sont différents")
        return
      }

      const phoneLength = parseInt(phone.trim().length)

      if (
        !phone.startsWith(phone_code) ||
        phoneLength < phone_min_size ||
        phoneLength > phone_max_size
      ) {
        await setFieldError(
          "phone",
          `Le téléphone doit commencer par ${phone_code} ,taille minimale de ${phone_min_size} et maximale de ${phone_max_size}`
        )
        window.alert(
          `Le téléphone doit commencer par ${phone_code} ,taille minimale de ${phone_min_size} et maximale de ${phone_max_size}`
        )
        return
      }
      await setSubmitting(true)

      const response = await changePassword({
        variables: { password, phone },
      })

      const { ok, errors } = response.data.changePassword
      if (ok) {
        await setUpdatePasswordModal(false)
        toast.success("Vos modifications sont enregistrées")
      } else {
        window.alert(errors[0].message)
      }
    } catch (error) {
      window.alert(error.message)
    } finally {
      await setSubmitting(false)
    }
  }
  if (loading1 || loading2) {
    return <Loading />
  }
  const currentUser =
    currentUserData &&
    currentUserData.onlineUser &&
    currentUserData.onlineUser.authenticated
      ? currentUserData.onlineUser
      : null
  const updateInitialValuesAccount = {
    name: currentUser && currentUser.name,
    lastname: currentUser && currentUser.lastname,
    email: currentUser && currentUser.email,
    gender: currentUser && currentUser.gender ?currentUser.gender:'male',
    phone: currentUser && currentUser.phone,
  }
  return (
    <div className={classes.root}>
      <Formik
        initialValues={updateInitialValuesAccount}
        validationSchema={updateAccountSchema}
        onSubmit={updateAcc}
      >
        {(props) => (
          <ModalDialog
            open={updateAccountModal}
            cancel={async () => {
              await setUpdateAccountModal(false)

              await props.handleReset()
            }}
            title={"Mes informations"}
          >
            <ProfileEdit
              {...props}
              item={currentUser ? currentUser : null}
              globalError={globalEditError}
            />
          </ModalDialog>
        )}
      </Formik>

      <Formik
        initialValues={{
          password: "",
          phone: "",
          passwordConfirm: "",
        }}
        validationSchema={updatePasswordSchema}
        onSubmit={updatePass}
      >
        {(props) => (
          <ModalDialog
            open={updatePasswordModal}
            cancel={async () => {
              await setUpdatePasswordModal(false)

              await props.handleReset()
            }}
            title={"Changement Password"}
          >
            <ChangePasswordEdit {...props} phone_code={phone_code} />
          </ModalDialog>
        )}
      </Formik>

      <div className={classes.content}>
        <Profile
          item={currentUser ? currentUser : null}
          setUpdateAccountModal={setUpdateAccountModal}
          setUpdatePasswordModal={setUpdatePasswordModal}
        />
      </div>
    </div>
  )
}

export default ProfileView
