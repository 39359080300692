import React, { useState } from "react"
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { Accordion } from "components/Accordion"

export const CompanyToolbar = (props) => {
  const {
    openModal,

    setName,
    name,

    setReference,
    reference,
    setManagerIds,
    manager_ids,
    setRegions,
    regions,
    setCategories,
    categories,
    regionsData,
    setIsActive,
    is_active,
    senrvusers,
    search,
    clearFilter,
  } = props
   
  const [expanded1, setExpanded1] = useState(true)
  return (
    <Accordion
      id={"search-client-filter"}
      title={"Filtrer Les entreprise "}
      expanded={expanded1}
      setExpanded={setExpanded1}
    >
      <Grid
        container
        spacing={3}
        style={{ padding: '0px 20px' }}
      >

          <Grid item lg={6} md={6} sm={6} xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  id="is_active"
                  name="is_active"
                  checked={is_active}
                  onChange={async (_e, value) => await setIsActive(!is_active)}
                />
              }
              label={is_active ? "Compte actif" : "Compte inactif"}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
              <Button
              color="primary"
              variant="contained"
              onClick={openModal}
            >
              Ajouter une entreprise
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="name"
              name="name"
              label="Nom"
              onChange={async ({ target: { value } }) => await setName(value)}
              fullWidth
              value={name}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required={false}
              id="reference"
              name="reference"
              label="Référence"
              value={reference}
              onChange={async ({ target: { value } }) => await setReference(value)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Autocomplete
              multiple={true}
              noOptionsText={"Aucun résultat"}
              id="regions"
              name="regions"
              label={"Régions"}
              defaultValue={regions}
              options={regionsData}
              onChange={async (e, newVal) => {
                e.preventDefault()

                await setRegions(newVal)
              }}
              getOptionLabel={(option) => option}
              //   style={{ margin: 100 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Régions"
                  variant="outlined"
                  required
                  fullWidth
                />
              )}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Autocomplete
              multiple={true}
              noOptionsText={"Aucun résultat"}
              id="senrv_manager_ids"
              name="senrv_manager_ids"
              label={"Managers"}
              defaultValue={manager_ids}
              options={senrvusers}
              onChange={async (e, newVal) => {
                e.preventDefault()

                await setManagerIds(newVal)
              }}
              getOptionLabel={(option) => option}
              //   style={{ margin: 100 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Commercial(e)"
                  variant="outlined"
                  required
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div className="content-btn-setting-generale">
              <Button
                color="secondary"
                //disabled={!formState.isValid}
                type="submit"
                onClick={clearFilter}
                variant="contained"
              >
                Effacer les filtres
              </Button>

              <Button
                color="primary"
                //disabled={!formState.isValid}
                type="submit"
                onClick={search}
                variant="contained"
              >
                Chercher
              </Button>
            </div>
          </Grid>
        
      </Grid>
    </Accordion>
  )
}
