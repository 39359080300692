import gql from "graphql-tag"

export const GET_CURRENT_USER_QUERY = gql`
  query {
    currentUser @client {
      authenticated
    }
  }
`
export const senrvUsersQuery = gql`
  query senrvUsers {
    senrvUsers {
      id
      name
      lastname
      gender
      phone
    }
  }
`
export const currentUserQuery = gql`
  query onlineUser {
    onlineUser {
      authenticated
      id
      name
      lastname
      email
      phone
      is_active
      picture
      phone_confirmed
      fullname
      gender
      lat
      lng
      roles {
        _id
        name
        is_admin
        is_manager
        is_worker
        is_super_admin
        is_commercial
        is_from_senrv
      }
    }
  }
`

export const findSalersQuery = gql`
  query findSalers($page: Int, $name: String) {
    findSalers(page: $page, name: $name) {
      totalDocs
      hasPrevPage
      totalPages
      limit
      offset
      page
      prevPage
      nextPage
      docs {
        id
        name
        lastname
        fullname
        email
        phone
        is_active
        picture
        phone_confirmed
        createdAt
      }
    }
  }
`

export const findUsersAdminQuery = gql`
  query findUsersAdmin(
    $page: Int!
    $name: String
    $phone: String
    $email: String
    $rolename: [String]
    $is_active: Boolean
  ) {
    findUsersAdmin(
      page: $page
      name: $name
      phone: $phone
      email: $email
      rolename: $rolename
      is_active: $is_active
    ) {
      totalDocs
      hasPrevPage
      totalPages
      limit
      offset
      page
      prevPage
      nextPage
      docs {
        id
        name
        lastname
        fullname
        email
        picture
        phone
        is_active
        gender
        lat
        lng

        phone_confirmed
        createdAt
        roles {
          _id
          name
          is_admin
          is_manager
          is_worker
          is_super_admin
          is_commercial
          is_from_senrv
        }
      }
    }
  }
`
export const findCustomersQuery = gql`
  query findCustomers(
    $page: Int!
    $name: String
    $phone: String
    $email: String
    $is_active: Boolean
  ) {
    findCustomers(
      page: $page
      name: $name
      phone: $phone
      email: $email
      is_active: $is_active
    ) {
      totalDocs
      hasPrevPage
      totalPages
      limit
      offset
      page
      prevPage
      nextPage
      docs {
        id
        gender
        name
        lastname
        fullname
        email
        lat
        lng
        phone
        is_active
        picture
        phone_confirmed
        createdAt
      }
    }
  }
`

export const findTeamsQuery = gql`
  query findTeams($page: Int, $name: String) {
    findTeams(page: $page, name: $name) {
      totalDocs
      hasPrevPage
      totalPages
      limit
      offset
      page
      prevPage
      nextPage
      docs {
        id
        name
        lastname
        fullname
        email
        phone
        is_active
        picture
        phone_confirmed
        createdAt
        lat
        lng

        roles {
          _id
          name
          is_admin
          is_manager
          is_worker
          is_from_senrv
          is_admin
          is_super_admin
        }
      }
    }
  }
`

/**
 *
 */
