import gql from "graphql-tag"

export const generateBillsMutation = gql`
  mutation generateBills($from_date: String!, $to_date: String!) {
    generateBills(from_date: $from_date, to_date: $to_date) {
      ok
      errors {
        path
        message
      }
    }
  }
`

export const updateBillMutation = gql`
  mutation updateBill(
    $id: String!
    $bill_paid: Boolean
    $company_id: String!
    $company_is_active: Boolean
  ) {
    updateBill(
      id: $id
      bill_paid: $bill_paid
      company_id: $company_id
      company_is_active: $company_is_active
    ) {
      ok
      id
      bill_paid
      company_is_active
      errors {
        path
        message
      }
    }
  }
`

//

export const removeBillsByIdMutation = gql`
  mutation removeBillsById($id: String!) {
    removeBillsById(id: $id) {
      ok
      id
      errors {
        path
        message
      }
    }
  }
`

//

export const removeBillsMutation = gql`
  mutation removeBills($from_date: String!, $to_date: String!) {
    removeBills(from_date: $from_date, to_date: $to_date) {
      ok
      errors {
        path
        message
      }
    }
  }
`
