import React from "react"
import { Grid, Button, TextField } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Loading from "components/Loading"
import { MessageField } from "components/MessageField"

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: "100%",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}))
export const ChangePasswordEdit = ({
  values,
  touched,
  errors,
  handleChange,
  handleSubmit,
  isSubmitting,

  globalError,
  phone_code,
}) => {
  const classes = useStyles()

  if (isSubmitting) {
    return <Loading />
  }

  return (
    <form
      className={classes.root}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      {globalError && <MessageField message={globalError} severity={"error"} />}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <TextField
            id="phone"
            name="phone"
            label={`Votre téléphone (${phone_code})`}
            value={values.phone}
            onChange={handleChange}
            error={touched.phone && errors.phone ? true : false}
            fullWidth
            helperText={touched.phone && errors.phone ? errors.phone : null}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            error={touched.password && errors.password ? true : false}
            helperText={touched.password && errors.password ? errors.password : null}
            label="Password"
            name="password"
            id="password"
            required
            onChange={handleChange}
            type="password"
            value={values.password}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            error={touched.passwordConfirm && errors.passwordConfirm ? true : false}
            helperText={
              touched.passwordConfirm && errors.passwordConfirm
                ? errors.passwordConfirm
                : null
            }
            label="Confirmer"
            name="passwordConfirm"
            id="passwordConfirm"
            required
            onChange={handleChange}
            type="password"
            value={values.passwordConfirm}
          />
        </Grid>
        <div className="controle-btn-bottom btn-setting-facture">
            <Button
              color="primary"
              //disabled={!formState.isValid}
             
              type="submit"
              onClick={handleSubmit}
              variant="contained"
            >
              enregistrer
            </Button>
        </div>
        {/* <Grid item xs={12} sm={6}>
         
        </Grid> */}
      </Grid>
    </form>
  )
}
