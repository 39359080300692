import React, { useEffect, useState } from "react"
import moment from "moment-timezone"
import { makeStyles } from "@material-ui/styles"
import { Accordion } from "components/Accordion"
import { useMutation, useQuery } from "@apollo/react-hooks"
import { findBillsCommercialQuery } from "graphql/query/comp_bill"
import {
  removeBillsByIdMutation,
  removeBillsMutation,
  updateBillMutation,
} from "graphql/mutation/comp_bill"
import { Grid, Button } from "@material-ui/core"
import { oneSettingAdminQuery } from "graphql/query/setting_admin"
import { BillList } from "./components/BillList"
import { TextField } from "@material-ui/core"
import Loading from "components/Loading"
import { toast } from "react-toastify"
import { Edit } from "@material-ui/icons"
import { ModalDialog } from "components/ModalDialog"
import { FormControlLabel } from "@material-ui/core"
import { Checkbox } from "@material-ui/core"
import { ONE_COMPANY_FACTURE_PATH } from "utils/static_constants"
import { currentUserQuery } from "graphql/query/user"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
}))

const FactureCommercialView = ({ history }) => {
  const classes = useStyles()
  const [expanded1, setExpanded1] = useState(true)
  const [expanded2, setExpanded2] = useState(true)
  const [editModal, setEditModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const [removeFromLoading, setRemoveFromLoading] = useState(false)
  const [updateBillLoading, setUpdateBillLoading] = useState(false)
  const [bill_paid, setBillPaid] = useState(undefined)
  const [from_bill, setFromBill] = useState(undefined)
  const [to_bill, setToBill] = useState(undefined)
  const [editedItem, setEditedItem] = useState(undefined)
  const [from_bill_remove, setFromBillRemove] = useState(undefined)
  const [to_bill_remove, setToBillRemove] = useState(undefined)
  const [canModify, setCanModify] = useState(false)

  const [page, setPage] = useState(1)
  const from = moment().clone().startOf("month")
  const to = moment().clone().endOf("month")
  const from_date = from.format("YYYY-MM-DD")
  const to_date = to.format("YYYY-MM-DD")
  const [updateBill] = useMutation(updateBillMutation, {
    async update(
      cache,
      {
        data: {
          updateBill: { ok, bill_paid, company_is_active, id },
        },
      }
    ) {
      if (ok) {
        const variables = await computeVariables(page)

        const { findBillsCommercial } = await cache.readQuery({
          query: findBillsCommercialQuery,
          variables,
        })

        let docs = findBillsCommercial.docs
        const userIndex = findBillsCommercial.docs.findIndex(
          (d) => d.id.toString() === id.toString()
        )

        if (userIndex > -1) {
          let bill = docs[userIndex]

          if (bill_paid != undefined && bill_paid != null) {
            bill.bill_paid = bill_paid
          }

          if (company_is_active != undefined && company_is_active != null) {
            bill.company.is_active = company_is_active
          }

          docs[userIndex] = bill
        }
        await cache.writeQuery({
          query: findBillsCommercialQuery,
          variables,
          data: {
            findBillsCommercial: {
              ...findBillsCommercial,

              docs,
            },
          },
        })
      }
    },
  })
  const [removeBillsById] = useMutation(removeBillsByIdMutation, {
    async update(
      _cache,
      {
        data: {
          removeBillsById: { ok },
        },
      }
    ) {
      if (ok) {
        await clearFilter()
      }
    },
  })
  const [removeBills] = useMutation(removeBillsMutation, {
    async update(
      _cache,
      {
        data: {
          removeBills: { ok },
        },
      }
    ) {
      if (ok) {
        await clearFilter()
      }
    },
  })

  const selectForUpdate = async (item) => {
    try {
      await setEditedItem(item)
      await setEditModal(true)
    } catch (error) {
      window.alert(error.message)
    }
  }

  const goToFacture = async ({ company_id, bill_id }) => {
    try {
      await history.push(`${ONE_COMPANY_FACTURE_PATH}/${company_id}/${bill_id}`)
    } catch (error) {
      window.alert(error.message)
    }
  }
  const {
    loading: loading3,
    data: compBillData,
    fetchMore,
    refetch,
  } = useQuery(findBillsCommercialQuery, {
    fetchPolicy: "network-only",
    variables: { page },
  })
  const { data: userData } = useQuery(currentUserQuery, {
    //fetchPolicy: "network-only",
  })
  const roles =
    userData && userData.onlineUser && userData.onlineUser.roles
      ? userData.onlineUser.roles
      : []
  const { loading: loading2, data: generalSetting } = useQuery(oneSettingAdminQuery)

  const timezone =
    generalSetting &&
    generalSetting.oneSettingAdmin &&
    generalSetting.oneSettingAdmin.time_zone
      ? generalSetting.oneSettingAdmin.time_zone
      : "Africa/Dakar"

  const currency =
    generalSetting &&
    generalSetting.oneSettingAdmin &&
    generalSetting.oneSettingAdmin.currency
      ? generalSetting.oneSettingAdmin.currency
      : "Africa/Dakar"
  const computeVariables = async (page) => {
    await setPage(page)
    let variables = { page }
    try {
      if (bill_paid != undefined) {
        variables.bill_paid = bill_paid
      }
      if (from_bill) {
        variables.from_date = from_bill
      }

      if (to_bill) {
        variables.to_date = to_bill
      }

      return variables
    } catch (error) {
      window.alert(error.message)
    }
  }
  const onPageChange = async (page) => {
    try {
      let variables = await computeVariables(page)
      await fetchMore({
        variables,
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev

          const { findBillsCommercial } = fetchMoreResult
          return Object.assign({}, prev, {
            findBillsCommercial,
          })
        },
      })
    } catch (error) {
      throw error
    }
  }
  const clearFilter = async () => {
    try {
      await setBillPaid(undefined)
      await setPage(1)
      await setFromBill(undefined)
      await setToBill(undefined)

      await refetch({ variables: { page: 1 } })
    } catch (error) {
      window.alert(error.message)
    }
  }

  const search = async () => {
    try {
      const variables = await computeVariables(1)

      await setLoading(true)

      fetchMore({
        variables,
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev

          const { findBillsCommercial } = fetchMoreResult

          return Object.assign({}, prev, {
            findBillsCommercial,
          })
        },
      })
    } catch (error) {
      window.alert(error.message)
    }
  }

  const remove = async () => {
    try {
      if (!from_bill_remove || !to_bill_remove) {
        window.alert("Choisir les plages de suppression")
        return
      } else if (moment(from_bill_remove).isAfter(moment(to_bill_remove))) {
        window.alert("dates incorrectes")
        return
      } else {
        await setRemoveFromLoading(true)
        const res = await removeBills({
          variables: {
            from_date: from_bill_remove,
            to_date: to_bill_remove,
          },
        })

        await setRemoveFromLoading(false)
        const { ok, errors } = res.data.removeBills
        if (ok) {
          await toast.success("Factures supprimmées")

          await setFromBillRemove(undefined)
          await setToBillRemove(undefined)
        } else {
          window.alert(errors[0].message)
        }
      }
    } catch (error) {
      await setRemoveFromLoading(false)
      window.alert(error.message)
    }
  }
  /*  if (loading || loading1 || loading2 || loading3) {
    return <Loading />
  } */
  const updateBillComp = async (id, company_is_active, company_id) => {
    try {
      if (window.confirm("Etes vous sûr(e) de vouloir bloquer cette entreprise")) {
        await setUpdateBillLoading(true)
        const res = await updateBill({
          variables: { id, company_is_active, company_id },
        })

        await setUpdateBillLoading(false)

        const { ok, errors } = res.data.updateBill

        if (ok) {
          await setEditModal(false)
          await setEditedItem(undefined)

          await toast.success("Opération faite avec succès")
        } else {
          window.alert(errors[0].message)
        }
      }
    } catch (error) {
      await setUpdateBillLoading(false)
      window.alert(error.message)
    }
  }

  const updateBillStatus = async (id, bill_paid, company_id) => {
    try {
      if (
        window.confirm(
          "Etes vous sûr(e) de vouloir marquer cette facture comme payée"
        )
      ) {
        await setUpdateBillLoading(true)
        const res = await updateBill({
          variables: { id, bill_paid, company_id },
        })

        await setUpdateBillLoading(false)

        const { ok, errors } = res.data.updateBill

        if (ok) {
          await setEditModal(false)
          await setEditedItem(undefined)

          await toast.success("Opération faite avec succès")
        } else {
          window.alert(errors[0].message)
        }
      }
    } catch (error) {
      await setUpdateBillLoading(false)
      window.alert(error.message)
    }
  }
  const setUpRoles = async (roles) => {
    try {
      const modify = roles.filter((r) => !r.company_id).some((r) => r.is_super_admin)

      await setCanModify(modify)

      return true
    } catch (error) {
      throw error
    }
  }
  useEffect(() => {
    if (roles) {
      setUpRoles(roles)
        .then(() => {})
        .catch((err) => console.log("error", err))
    }
  }, [roles])
  return (
    <div className={classes.root}>
      {editModal && editedItem && (
        <ModalDialog
          open={editModal}
          cancel={async () => {
            await setEditModal(false)
          }}
          title={`Facture totale ${moment(
            new Date(parseInt(editedItem.createdAt))
          ).format("MMM YYYY")}`}
        >
          <Grid container spacing={3}>
            {editedItem &&
              editedItem.bill_paid != undefined &&
              !editedItem.bill_paid &&
              editedItem.company &&
              editedItem.company.is_active != undefined &&
              editedItem.company.is_active && (
                <Grid item xs={12} sm={12}>
                  <Button
                    disabled={updateBillLoading}
                    style={{ backgroundColor: "red", color: "white" }}
                    fullWidth
                    size="large"
                    //type="submit"
                    onClick={async () => {
                      await updateBillComp(
                        editedItem.id,
                        !editedItem.company.is_active,
                        editedItem.company.id
                      )
                    }}
                    variant="contained"
                  >
                    Bloquer cette entreprise
                  </Button>
                </Grid>
              )}

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                id="name"
                name="name"
                label="Entreprise"
                value={editedItem.company.name}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                required
                id="name"
                name="name"
                label={`Facture totale( ${currency})`}
                value={editedItem.final_bill}
              />
            </Grid>
            {canModify &&
              editedItem &&
              editedItem.bill_paid != undefined &&
              !editedItem.bill_paid && (
                <Grid item xs={12} sm={6}>
                  <Button
                    style={{ backgroundColor: "green", color: "white" }}
                    fullWidth
                    size="large"
                    disabled={updateBillLoading}
                    onClick={async () => {
                      await updateBillStatus(
                        editedItem.id,
                        !editedItem.bill_paid,
                        editedItem.company.id
                      )
                    }}
                    variant="contained"
                  >
                    Marquer la facture comme payée
                  </Button>
                </Grid>
              )}
            {updateBillLoading && <Loading />}
          </Grid>
        </ModalDialog>
      )}

      <Accordion
        id={"search-client-bill-total"}
        title={"Filtrer "}
        expanded={expanded1}
        setExpanded={setExpanded1}
      >
        <Grid container spacing={3} style={{ padding: 20 }}>
          <Grid item xs={12} sm={6}>
            <TextField
              id="start"
              label="Du "
              name="start"
              type="date"
              fullWidth
              value={from_bill}
              onChange={async ({ target: { value } }) => {
                await setFromBill(value)
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="end"
              label="Au"
              name="end"
              type="date"
              value={to_bill}
              onChange={async ({ target: { value } }) => {
                await setToBill(value)
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
              <div className="content-btn-setting-generale">
                  <Button
                    style={{ backgroundColor: "red", color: "white" }}
                  
                    //type="submit"
                    onClick={clearFilter}
                    variant="contained"
                  >
                    effacer les filtres
                  </Button>
                  <Button
                    //  style={{ backgroundColor: "red", color: "white" }}
                    color="primary"
                  
                    //type="submit"
                    onClick={search}
                    variant="contained"
                  >
                    chercher
                  </Button>
              </div>
          </Grid>

        </Grid>
        <BillList
          selectForUpdate={selectForUpdate}
          goToFacture={goToFacture}
          time_zone={timezone}
          currency={currency}
          data={
            compBillData && compBillData.findBillsCommercial
              ? compBillData.findBillsCommercial
              : null
          }
          docs={
            compBillData &&
            compBillData.findBillsCommercial &&
            compBillData.findBillsCommercial.docs
              ? compBillData.findBillsCommercial.docs
              : null
          }
          page={page}
          totalPages={
            compBillData &&
            compBillData.findBillsCommercial &&
            compBillData.findBillsCommercial.totalPages
              ? compBillData.findBillsCommercial.totalPages
              : null
          }
          onPageChange={onPageChange}
        />
      </Accordion>
    </div>
  )
}

export default FactureCommercialView
