import React from "react"
import { Grid, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: "100%",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}))
export const JobEdit = ({ item }) => {
  const classes = useStyles()

  return (
    <form
      className={classes.root}
      noValidate
      autoComplete="off"
      
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h3" component="h2">{item.title}</Typography>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Typography>{item.description}</Typography>
        </Grid>
      </Grid>
    </form>
  )
}
