import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardContent,
  Typography,
  CardMedia,
  CardActions,
  IconButton
} from '@material-ui/core'

import { DisplayDate } from 'components/DisplayDate'
import { ONE_COMPANY_PATH } from 'utils/static_constants'
import { Link } from 'react-router-dom'
import inconnu from '../../../assets/inconnu.jpg'
import { renderDate } from 'utils/func'
import DeleteIcon from '@material-ui/icons/Delete'
const useStyles = makeStyles((theme) => ({
  root: { backgroundColor: 'white' },
  imageContainer: {
    height: 180,
    width: '100%',
    margin: '0 auto',
    border: `1px solid ${theme.palette.divider}`,
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  image: {
    width: '100%'
  },
  statsItem: {
    display: 'flex',
    alignItems: 'center'
  },
  statsIcon: {
    color: theme.palette.icon,
    marginRight: theme.spacing(1)
  },
  link: {
    color: '#263238',
    '&:hover': {
      color: '#9980FA'
    }
  }
}))

export const Company = (props) => {
  const { className, item, time_zone, removeCompany, ...rest } = props

  const classes = useStyles()

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
      // to={`${ONE_COMPANY_PATH}/${item.id}`}
    >
      {/*   {item.picture ?  (
        <CardMedia image={item.picture} className={classes.imageContainer} />
      ) : (<CardMedia image={`https://static.cnews.fr/sites/default/files/styles/image_640_360/public/point1.jpg?itok=jGTH8XuS`} className={classes.imageContainer} />)} */}
      <CardContent
        style={{
          backgroundColor: 'white',
          borderColor: 'purple',
          borderRadius: 10,
          borderWidth: 10
        }}
      >
        <Typography align='center' gutterBottom variant='h4'>
          <Link className={classes.link} to={`${ONE_COMPANY_PATH}/${item.id}`}>
            {' '}
            {item.name}
          </Link>
        </Typography>

        {/* {!item.picture && <img size="tiny" src={inconnu} alt={item.name} />} */}

        <div className='content-libily-compagny'>
          <p className='libily-compagny'>Réference:</p>
          <Typography align='center' variant='body2' lines={3}>
            {item.reference}
          </Typography>
        </div>
        <div className='content-libily-compagny'>
          <p className='libily-compagny'>Date:</p>
          <Typography align='center' variant='body2' lines={3}>
            {renderDate(item.createdAt, time_zone)}
          </Typography>
        </div>

        <div className='content-libily-compagny'>
          <p className='libily-compagny'>Region:</p>
          <Typography align='center' variant='body2' lines={3}>
            {item.region}
          </Typography>
        </div>

        <div className='content-libily-compagny'>
          {/* <p className="libily-compagny">Region:</p> */}
          <Typography align='center' variant='body2' lines={3}>
            {item.categories.map((c, i) => (
              <span key={i}>{c.name + ' '}</span>
            ))}
          </Typography>
        </div>

        <div className='content-libily-compagny'>
          <p className='libily-compagny'>Compte:</p>
          <Typography
            align='center'
            variant='body2'
            lines={3}
            style={{ color: item.is_active ? 'green' : 'red' }}
          >
            {item.is_active ? 'Actif' : 'Inactif'}
          </Typography>
        </div>
      </CardContent>

      <CardActions style={{ display: 'flex', justifyContent: 'space-between' }}>
        <IconButton
          aria-label='delete'
          className={classes.margin}
          onClick={async (e) => {
            try {
              e.preventDefault()
              if (
                window.confirm(
                  'êtes vous sûr de vouloir supprimer cette entreprise ?'
                )
              ) {
                await removeCompany(item.id)
              }
            } catch (error) {
              window.alert(error.message)
            }
          }}
        >
          <DeleteIcon className={classes.statsIcon} />
        </IconButton>

        {/*   <IconButton
          aria-label='delete'
          className={classes.margin}
          onClick={async (e) => {
            e.preventDefault()
            await selectForUpdate(item)
          }}
        >
          <EditIcon className={classes.statsIcon} />
        </IconButton> */}
      </CardActions>
    </Card>
  )
}
