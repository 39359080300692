import React from "react"
import PropTypes from "prop-types"
import { makeStyles, useTheme } from "@material-ui/styles"
import { Typography, Grid, TextField } from "@material-ui/core"
import EditIcon from "@material-ui/icons/Edit"
import CurrencyTextField from "@unicef/material-ui-currency-textfield"
import { MessageField } from "components/MessageField"
import { SelectField } from "components/SelectField"
import SettingsIcon from '@material-ui/icons/Settings';

const useStyles = makeStyles((theme) => ({
  root: {},
  imageContainer: {
    height: 64,
    width: 64,
    margin: "0 auto",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "5px",
    overflow: "hidden",
    display: "flex",
    // alignItems: "center",
    // justifyContent: "center",
  },
  image: {
    width: "100%",
  },
  statsItem: {
    display: "flex",
    //alignItems: "center",
  },
  statsIcon: {
    color: theme.palette.icon,
    marginRight: theme.spacing(1),
  },
}))

export const Setting = (props) => {
  const { className, item, selectForUpdate, canModify } = props

  const classes = useStyles()
  const theme = useTheme()

  if (!item || !item.id) {
    return null
  }

  return (
    <div className="miniContent content-candre-info">
        <div className={"miniContentHeader"}>
            <Typography variant="h3">
                <SettingsIcon className={"iconminiContentHeader"} />
                 Paramétrage générale
            </Typography>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            {/* <TextField required label="Pays" value={item.country} readOnly={true} /> */}
            <div className={"miniContentMain"}>
              <Typography variant="body2" className="label-profil">
                  Pays:
              </Typography>
              <p className={"infoText"}>{item.country ? item.country : ""}</p>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={"miniContentMain"}>
              <Typography variant="body2" className="label-profil">
                 Fuseau Horaire:
              </Typography>
              <p className={"infoText"}>{item.time_zone ? item.time_zone : ""}</p>
            </div>
            {/* <TextField required label="Fuseau Horaire" value={item.time_zone} readOnly={true} /> */}
            </Grid>

          <Grid item xs={12} sm={6}>
            <div className={"miniContentMain"}>
              <Typography variant="body2" className="label-profil">
                  Délai minimal d'annulation rv:
              </Typography>
              <p className={"infoText"}>{item.minimal_cancel + "h" ? item.minimal_cancel + "h" : ""}</p>
            </div>
            {/* <TextField
              required
              label="Délai minimal d'annulation rv"
              value={item.minimal_cancel + " h"}
              readOnly={true}
            /> */}
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={"miniContentMain"}>
                <Typography variant="body2" className="label-profil">
                    Taille minimale Téléphone:
                </Typography>
                <p className={"infoText"}>{item.phone_min_size ? item.phone_min_size : ""}</p>
             </div>
            {/* <TextField
              required
              label="Taille minimale Téléphone"
              value={item.phone_min_size}
              readOnly={true}
            /> */}
          </Grid>

          <Grid item xs={12} sm={6}>
            <div className={"miniContentMain"}>
                <Typography variant="body2" className="label-profil">
                    Taille maximale Téléphone:
                </Typography>
                <p className={"infoText"}>{item.phone_max_size ? item.phone_max_size : ""}</p>
             </div>
              {/* <TextField
                required
                label="Taille maximale Téléphone"
                value={item.phone_max_size}
                readOnly={true}
              /> */}
          </Grid>

          <Grid item xs={12} sm={6}>
            <div className={"miniContentMain"}>
                <Typography variant="body2" className="label-profil">
                    Indicatif téléphone:
                </Typography>
                <p className={"infoText"}>{item.phone_code ? item.phone_code : ""}</p>
             </div>
            {/* <TextField
              required
              label="Indicatif téléphone"
              value={item.phone_code}
              readOnly={true}
            /> */}
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={"miniContentMain"}>
                <Typography variant="body2" className="label-profil">
                    Monnaie:
                </Typography>
                <p className={"infoText"}>{item.currency ? item.currency : ""}</p>
             </div>
            {/* <TextField required label="Monnaie" value={item.currency} readOnly={true} /> */}
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={"miniContentMain"}>
                <Typography variant="body2" className="label-profil">
                    Prix sms:
                </Typography>
                <p className={"infoText"}>{item.sms_price ? item.sms_price : ""}</p>
             </div>
            {/* <CurrencyTextField
              as={TextField}
              readOnly={true}
              value={item.sms_price}
              currencySymbol={item.currency}
              required
              label={"Prix sms"}
            /> */}
          </Grid>
          <Grid item xs={12} sm={12}>
          <div className={"miniContentMain"}>
                <Typography variant="body2" className="label-profil">
                    Régions:
                </Typography>
                <p className={"infoText"}>{item.regions ? item.regions.map(r=>`${r} ; `) : ""}</p>
             </div>

              {/* <SelectField
                multiple={true}
                label={"Régions"}
                value={item.regions}
                theme={theme}
                dataSource={item.regions}
                disabled={true}
                open={false}
              /> */}
          </Grid>

          {canModify && (
            <Grid item xs={12} sm={12}>
              <Typography display="inline" variant="body2">
                <EditIcon
                  className={classes.statsIcon}
                  color="primary"
                  onClick={async (e) => {
                    e.preventDefault()

                    await selectForUpdate(item)
                  }}
                />
              </Typography>
            </Grid>
          )}
        </Grid>
    </div>

  )
}

Setting.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object.isRequired,
}
